import { FC, useState } from 'react';

import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { CardContent, makeStyles } from '@material-ui/core';

import { Select, Typography } from '@kubecost-frontend/holster';

import { useClusters } from '../../contexts/ClusterConfig';
import { accents } from '../../services/colors';
import { monthDay, toCurrency } from '../../services/format';

const useStyles = makeStyles({
  tooltip: {
    borderRadius: 2,
    background: 'rgba(255, 255, 255, 0.95)',
    padding: 12,
  },
  tooltipLineItem: {
    fontSize: '1rem',
    margin: 0,
    padding: 0,
  },
});

const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: {
    dataKey?: string | number;
    fill?: string;
    name?: string | number;
    payload?: { end: Date | number; start: Date | number };
    value?: (string | number) | (string | number)[];
  }[];
}) => {
  const classes = useStyles();
  const { modelConfig } = useClusters();

  if (!payload || payload.length === 0) {
    return null;
  }

  const dateTimeFormatter = Intl.DateTimeFormat(navigator.language, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'UTC',
  });

  const total = payload.reduce((sum, item) => {
    if (typeof item.value !== 'number') {
      return sum;
    }
    return sum + item.value;
  }, 0.0);

  let start = null;
  if (payload[0] && payload[0].payload) {
    start = payload[0].payload.start;
  }

  let end = null;
  if (payload[0] && payload[0].payload) {
    end = payload[0].payload.end;
  }

  if (active) {
    return (
      <div className={classes.tooltip}>
        {start && end && (
          <p
            className={classes.tooltipLineItem}
            style={{ color: '#808080', fontSize: '0.75rem' }}
          >{`${dateTimeFormatter.format(start)} to ${dateTimeFormatter.format(end)}`}</p>
        )}
        <p
          className={classes.tooltipLineItem}
          style={{
            color: '#000000',
            marginBottom: '1em',
            marginTop: '.25em',
          }}
        >{`Total: ${
          typeof payload[0].dataKey === 'string' && payload[0].dataKey.endsWith('/cost')
            ? toCurrency(total, modelConfig.currencyCode)
            : total
        }`}</p>
        {payload.map((item) => (
          <div
            key={item.name}
            style={{
              display: 'grid',
              gridTemplateColumns: '20px 1fr',
              gap: '.5em',
              margin: '.25em',
            }}
          >
            <div>
              <div style={{ backgroundColor: item.fill, width: 18, height: 18 }} />
            </div>
            <div>
              <p className={classes.tooltipLineItem}>{`${
                typeof item.name === 'string' ? item.name.split('/')[0] : item.name?.toString()
              }: ${
                typeof item.dataKey === 'string' &&
                item.dataKey.endsWith('/cost') &&
                typeof item.value === 'number'
                  ? toCurrency(item.value, modelConfig.currencyCode)
                  : item.value
              }`}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

interface GraphCardProps {
  colorKeys: string[];
  data: {
    cost: Record<string, number | string>[];
    count: Record<string, number | string>[];
  };
  loading?: boolean;
  selectOpts: Array<{ display: string; value: string }>;
  title: string;
}

const GraphCard: FC<GraphCardProps> = ({ colorKeys, data, loading = false, selectOpts, title }) => {
  const { modelConfig } = useClusters();
  const [yDisplay, setYDisplay] = useState(selectOpts ? selectOpts[0].value : '');

  const graphData = yDisplay === 'cost' ? data.cost : data.count;

  if (!graphData || !graphData.length || !selectOpts || !colorKeys || loading) {
    return (
      <div className={'border border-kc-gray-100'} style={{ height: '100%', width: '100%' }}>
        <div
          className={'flex border-b border-kc-gray-100'}
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '17.5px',
            padding: selectOpts.length > 1 ? '11px 19px' : '15px 19px',
          }}
        >
          <Typography className={'font-bold'} style={{ flexGrow: 1 }} variant={'p'}>
            {title}
          </Typography>
          {selectOpts.length > 1 && (
            <Select
              options={selectOpts.map((opt) => ({
                label: opt.display,
                value: opt.value,
              }))}
              setValue={handleYDisplayChange}
              value={yDisplay}
            />
          )}
        </div>
        <hr
          style={{
            border: 'none',
            borderTop: '2px solid rgba(0,0,0,0.1)',
            margin: 0,
          }}
        />
        <CardContent style={{ padding: 0, opacity: 0.2 }}>
          <ResponsiveContainer height={300} width={'100%'}>
            <BarChart
              data={[
                {
                  val: 8,
                },
                {
                  val: 10,
                },
                {
                  val: 9,
                },
                {
                  val: 7,
                },
                {
                  val: 10,
                },
                {
                  val: 9,
                },
              ]}
              margin={{ top: 30, right: 30, left: 30, bottom: 12 }}
            >
              <CartesianGrid strokeDasharray={'3 3'} vertical />
              <XAxis />
              <Bar
                className={'MuiSkeleton-pulse'}
                dataKey={'val'}
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.35)' }}
              />
            </BarChart>
          </ResponsiveContainer>
        </CardContent>
      </div>
    );
  }
  const colorMap: Record<string, string> = {};
  colorKeys.forEach((key, i) => {
    colorMap[key] = accents[i % accents.length];
  });
  return (
    <div className={'border border-kc-gray-100'} style={{ height: '100%', width: '100%' }}>
      <div
        className={'flex border-b border-kc-gray-100'}
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '17.5px',
          padding: selectOpts.length > 1 ? '11px 19px' : '15px 19px',
        }}
      >
        <Typography className={'font-bold'} style={{ flexGrow: 1 }} variant={'p'}>
          {title}
        </Typography>
        {selectOpts.length > 1 && (
          <Select
            options={selectOpts.map((opt) => ({
              label: opt.display,
              value: opt.value,
            }))}
            setValue={handleYDisplayChange}
            value={yDisplay}
          />
        )}
      </div>
      <CardContent style={{ padding: 0 }}>
        <ResponsiveContainer height={300} width={'100%'}>
          <BarChart data={graphData} margin={{ top: 30, right: 30, left: 30, bottom: 12 }}>
            <CartesianGrid vertical={false} />
            <XAxis
              axisLine={false}
              dataKey={'date'}
              tickFormatter={(value) => {
                const label = monthDay(new Date(value));
                return label === 'NaN/NaN' ? 'Loading' : label;
              }}
              tickMargin={8}
              tickSize={0}
            />
            <YAxis
              axisLine={false}
              tickFormatter={(value) => {
                const formattedValue =
                  yDisplay === 'cost'
                    ? toCurrency(value, modelConfig.currencyCode, 2, true)
                    : value;
                return formattedValue;
              }}
              tickMargin={8}
              tickSize={0}
            />
            <Tooltip
              content={(props) => <CustomTooltip {...props} />}
              wrapperStyle={{ zIndex: 1000 }}
            />
            {colorKeys.map((key) => (
              <Bar
                dataKey={`${key}/${yDisplay}`}
                fill={colorMap[key]}
                key={`${key}/${yDisplay}`}
                stackId={'a'}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </div>
  );

  function handleYDisplayChange(s: string) {
    setYDisplay(s);
  }
};

GraphCard.defaultProps = {
  loading: false,
};

export { GraphCard, GraphCardProps };
