import React from 'react';

import { NavIconProps } from './types';

const AllocationNavIcon = React.forwardRef<SVGSVGElement, NavIconProps>(
  ({ active, ...args }, ref) => (
    <svg
      fill={'none'}
      height={'30'}
      ref={ref}
      viewBox={'0 0 30 30'}
      width={'30'}
      xmlns={'http://www.w3.org/2000/svg'}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...args}
    >
      <path
        d={
          'M14.875 5.93819C14.9524 5.89354 15.0476 5.89354 15.125 5.93819L18.4551 7.86084L18.8301 7.21132L18.4551 7.86084C18.5325 7.9055 18.5801 7.98803 18.5801 8.07735V11.9226C18.5801 12.012 18.5325 12.0945 18.4551 12.1392L15.125 14.0618C15.0476 14.1065 14.9524 14.1065 14.875 14.0618L11.5449 12.1392C11.4675 12.0945 11.4199 12.012 11.4199 11.9226V8.07735C11.4199 7.98803 11.4675 7.9055 11.5449 7.86084L14.875 5.93819Z'
        }
        stroke={active ? '#63E892' : '#607971'}
        strokeWidth={'1.5'}
      />
      <path
        d={
          'M8.875 15.9382C8.95235 15.8935 9.04765 15.8935 9.125 15.9382L12.4551 17.8608L12.8301 17.2113L12.4551 17.8608C12.5325 17.9055 12.5801 17.988 12.5801 18.0774V21.9226C12.5801 22.012 12.5325 22.0945 12.4551 22.1392L9.125 24.0618C9.04765 24.1065 8.95235 24.1065 8.875 24.0618L5.54487 22.1392C5.46752 22.0945 5.41987 22.012 5.41987 21.9226V18.0774C5.41987 17.988 5.46752 17.9055 5.54487 17.8608L8.875 15.9382Z'
        }
        stroke={active ? '#63E892' : '#607971'}
        strokeWidth={'1.5'}
      />
      <path
        d={
          'M20.875 15.9382C20.9524 15.8935 21.0476 15.8935 21.125 15.9382L24.4551 17.8608L24.8301 17.2113L24.4551 17.8608C24.5325 17.9055 24.5801 17.988 24.5801 18.0774V21.9226C24.5801 22.012 24.5325 22.0945 24.4551 22.1392L21.125 24.0618C21.0476 24.1065 20.9524 24.1065 20.875 24.0618L17.5449 22.1392C17.4675 22.0945 17.4199 22.012 17.4199 21.9226V18.0774C17.4199 17.988 17.4675 17.9055 17.5449 17.8608L20.875 15.9382Z'
        }
        stroke={active ? '#63E892' : '#607971'}
        strokeWidth={'1.5'}
      />
    </svg>
  ),
);

AllocationNavIcon.displayName = 'AllocationNavIcon';
export { AllocationNavIcon };
