import { useReducer, useState } from 'react';

import axios from 'axios';

import { Alert, Button, Input, Typography } from '@kubecost-frontend/holster';

type FailedClusterAction =
  | { address: string; type: 'checkURL' }
  | { type: 'failCheck' }
  | { type: 'checkSucceeded' }
  | { type: 'reset' };

type FailedClusterState = {
  checkFailed: boolean;
  checkSucceeded: boolean;
  checkedAddress: string;
  loading: boolean;
};

const initialState: FailedClusterState = {
  checkedAddress: '',
  loading: false,
  checkSucceeded: false,
  checkFailed: false,
};

type AddContextSwitcherFormProps = {
  contextAdded: (url: string) => void;
};

const AddContextSwitcherForm = ({ contextAdded }: AddContextSwitcherFormProps) => {
  const [currentURL, setCurrentURL] = useState('');
  const [{ checkFailed, loading }, dispatch] = useReducer(
    (state: FailedClusterState, action: FailedClusterAction): FailedClusterState => {
      switch (action.type) {
        case 'checkSucceeded':
          return {
            ...state,
            checkSucceeded: true,
            checkFailed: false,
            loading: false,
          };
        case 'failCheck':
          return {
            ...state,
            checkSucceeded: false,
            checkFailed: true,
            loading: false,
          };
        case 'checkURL':
          return {
            checkSucceeded: false,
            checkFailed: false,
            loading: true,
            checkedAddress: action.address,
          };
        case 'reset':
          return initialState;
        default:
          return initialState;
      }
    },
    initialState,
  );

  const checkUrl = (url: string) => {
    if (!url.startsWith('http')) {
      url = `http://${url}`;
    }

    dispatch({
      type: 'checkURL',
      address: url,
    });
    const URL = `${url}/model/clusterInfo`;
    axios
      .get(URL, {
        timeout: 5000,
      })
      .then(() => {
        contextAdded(url);
        setCurrentURL('');
        dispatch({
          type: 'reset',
        });
      })
      .catch((e) => {
        dispatch({
          type: 'failCheck',
        });
      });
  };

  return (
    <div className={'p-6'}>
      {checkFailed && (
        <div className={'mb-4'}>
          <Alert
            content={
              <>
                <Typography variant={'p'}>
                  It looks like that Kubecost instance is not responding. Would you like to add it
                  anyway?
                </Typography>
                <Button
                  className={'mt-4'}
                  onClick={() => {
                    contextAdded(currentURL);
                  }}
                  variant={'default'}
                >
                  Add Anyway
                </Button>
              </>
            }
            title={'Lookup Failed'}
            variant={'warning'}
          />
        </div>
      )}
      <Typography className={'font-semibold'} variant={'p'}>
        Add Context
      </Typography>
      <div className={'flex gap-4'}>
        <Input
          className={'w-full flex-grow'}
          disabled={loading}
          onChange={(e) => setCurrentURL(e.target.value)}
          onKeyDown={(e) => {
            if (e.code === 'Enter') checkUrl(currentURL);
          }}
          placeholder={'http://kubecosturl.xyz'}
          value={currentURL}
          autoFocus
        />
        <Button
          disabled={loading || currentURL === ''}
          onClick={() => {
            checkUrl(currentURL);
          }}
          variant={'primary'}
        >
          {loading ? 'Checking...' : 'Add'}
        </Button>
      </div>
      <Typography className={'text-kc-gray-200'} variant={'p'}>
        URL of another Kubecost install
      </Typography>
    </div>
  );
};

export { AddContextSwitcherForm };
