import { Dispatch, SetStateAction, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { Alert } from '@kubecost-frontend/holster';

import { Loading } from '../../components';
import { Header } from '../../components/Header2New';
import { useNetworkTraffic, useNetworkTrafficStatus } from '../../hooks/useNetworkTraffic';
import { Allocation } from '../../types/allocation';

import { NetworkAllocationModal } from './NetworkAllocationModal';
import { NetworkAllocationTable } from './NetworkAllocationTable';

const DEFAULTS = {
  selectedAllocationData: null,
};

const PageBody = ({
  setSelectedAllocationData,
}: {
  setSelectedAllocationData: Dispatch<SetStateAction<Allocation | null>>;
}) => {
  const { isError, isLoading } = useNetworkTrafficStatus();

  if (isLoading) {
    return <Loading className={'mt-5'} message={'Loading network data...'} />;
  }

  if (isError) {
    return (
      <>
        <br />
        <Alert
          content={'There was an error when determining if this page can load network data.'}
          title={'Error!'}
          variant={'danger'}
        />
      </>
    );
  }

  return <NetworkAllocationTable setSelectedAllocationData={setSelectedAllocationData} />;
};

const NetworkAllocationPage = () => {
  const [search] = useSearchParams();
  const [selectedAllocationData, setSelectedAllocationData] = useState<Allocation | null>(
    DEFAULTS.selectedAllocationData,
  );

  const networkTrafficQuery = useNetworkTraffic({
    maxDestinations: 8,
    namespace: search.get('namespace')?.split(',')[0],
  });

  return (
    <>
      <Header
        refreshCallback={() => document.location.reload()}
        title={'Allocation / Network costs'}
      />
      <Alert
        content={''}
        link={{
          href: 'https://docs.kubecost.com/architecture/network-allocation',
          text: 'Learn more',
          target: '_blank',
        }}
        title={'This page is currently in beta'}
        variant={'warning'}
      />
      <Alert
        content={
          'Network costs on this page may not reflect the costs on the Overview or Allocations pages. Additionally, the network traffic volumes available when a row is clicked are from the last time the pod was restarted.'
        }
        style={{ marginTop: 16 }}
        title={'Note'}
        variant={'info'}
      />
      <PageBody setSelectedAllocationData={setSelectedAllocationData} />
      <NetworkAllocationModal
        handleOnClose={() => setSelectedAllocationData(DEFAULTS.selectedAllocationData)}
        networkTrafficQuery={networkTrafficQuery}
        selectedAllocationData={selectedAllocationData}
      />
    </>
  );
};

export { NetworkAllocationPage };
