import { Alert, TableBody, TableCell, TableRow } from '@kubecost-frontend/holster';

import { useClusters } from '../../../contexts/ClusterConfig';
import { toCurrency } from '../../../services/format';
import { Allocation } from '../../../types/allocation';

import { NetworkAllocationRow, NetworkAllocationRowData } from './NetworkAllocationRow';

const formatCost = (cost: number, currencyCode: string): string => {
  if (cost > 0 && cost < 0.01) {
    return `<${toCurrency(0.01, currencyCode, 2, false)}`;
  }
  return toCurrency(cost, currencyCode, 2, false);
};

interface NetworkAllocationTableBodyProps {
  setSelectedAllocationData: (allocation: Allocation) => void;
  sortedRowData: Array<NetworkAllocationRowData>;
  totals: {
    networkCost: number;
    networkCrossRegionCost: number;
    networkCrossZoneCost: number;
    networkInternetCost: number;
  };
}
const NetworkAllocationTableBody = ({
  setSelectedAllocationData,
  sortedRowData,
  totals,
}: NetworkAllocationTableBodyProps) => {
  const { modelConfig } = useClusters();

  if (sortedRowData.length === 0) {
    return (
      <TableBody>
        <TableRow>
          <TableCell className={'py-4'} colSpan={6}>
            <Alert
              content={'Your data loaded properly but there do not appear to be any results'}
              title={'No network data'}
              variant={'warning'}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {/* Totals */}
      <TableRow className={'font-bold'}>
        <TableCell>Totals</TableCell>
        <TableCell />
        <TableCell style={{ textAlign: 'right' }}>
          {formatCost(totals.networkInternetCost, modelConfig.currencyCode)}
        </TableCell>
        <TableCell style={{ textAlign: 'right' }}>
          {formatCost(totals.networkCrossZoneCost, modelConfig.currencyCode)}
        </TableCell>
        <TableCell style={{ textAlign: 'right' }}>
          {formatCost(totals.networkCrossRegionCost, modelConfig.currencyCode)}
        </TableCell>
        <TableCell style={{ textAlign: 'right' }}>
          {formatCost(totals.networkCost, modelConfig.currencyCode)}
        </TableCell>
      </TableRow>
      {/* Normal Rows */}
      {sortedRowData.map((rowData) => (
        <NetworkAllocationRow
          handleOnClick={() => setSelectedAllocationData(rowData.allocation)}
          key={rowData.allocation.name}
          rowData={rowData}
        />
      ))}
    </TableBody>
  );
};

export { NetworkAllocationTableBody };
