/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react';

import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

import { Typography } from '@kubecost-frontend/holster';

import { useClusters } from '../../../contexts/ClusterConfig';
import { model } from '../../../services/model';

import { ContextSwitcherModal } from './ContextSwitcherModal';

const ContextSwitcher: FC = () => {
  const [clusterName, setClusterName] = useState('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { activeContext } = useClusters();

  const handleOpenDialog = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    model.clusterInfo().then((resp) => {
      setClusterName(resp.name);
    });
  }, [activeContext]);

  return (
    <>
      <div className={'flex items-center p-4'} onClick={handleOpenDialog}>
        <SwapHorizIcon className={'mr-4'} />
        <Typography className={'cursor-pointer font-bold hover:text-kc-primary'} variant={'p'}>
          Switch Context
          <span
            className={'cursor-pointer font-light hover:text-kc-primary'}
            style={{ display: 'block', fontSize: 14 }}
          >
            {clusterName}
          </span>
        </Typography>
      </div>
      <ContextSwitcherModal onClose={() => setModalOpen(false)} open={modalOpen} />
    </>
  );
};

export default ContextSwitcher;
