import {
  fetchAbandonedWorkloads,
  fetchClusterSizingRecommendations,
  fetchRequestSizingRecommendationsMaxHeadroom,
  getPvSavings,
  getReservedRecSavings,
  getSavingsSummary,
  getUnassignedAddressSavings,
  getUnassignedDiskSavings,
  getUnutilizedLocalDiskSavings,
} from '../../services/savings';
import {
  defaultSpotAllowSharedCore,
  defaultSpotMinNodeCount,
  defaultSpotMinOnDemandNodeCount,
  defaultSpotTargetUtilization,
  defaultSpotWindow,
  fetchSpotChecklistClusterSizing,
} from '../../services/savings/spotclustersizing';

import { ColoredCloudIcon, ColoredKubernetesIcon } from './Icons';
import { SavingsItem } from './SavingsCard';

interface SavingsCardCtaTextType {
  default: string;
  upgradeRequired: string;
  withValue: string;
}

const savingsCardCtaText: SavingsCardCtaTextType = {
  default: 'Explore savings',
  upgradeRequired: 'Upgrade to start saving',
  withValue: 'Save up to',
};

const cloudSavings: SavingsItem[] = [
  // ======================================
  // == Cluster Sizing ====================
  // ======================================
  {
    title: 'Right-size your cluster nodes',
    description:
      "Adjust the number and size of your cluster's nodes to stop over-spending on unused capacity.",
    url: '../cluster-sizing',
    icon: <ColoredCloudIcon />,
    isBeta: false,
    isBetaMessage: '',
    savingsType: 'cloud',
    dataLoader: async () =>
      fetchClusterSizingRecommendations('2d', 0.8, 1, true, 'x86').then((recs: any) =>
        Object.keys(recs.data).reduce((total, key) => {
          if (
            recs.data[key].recommendations &&
            recs.data[key].recommendations.single &&
            recs.data[key].recommendations.single.monthlySavings
          ) {
            return recs.data[key].recommendations.single.monthlySavings + total;
          }
          return total;
        }, 0),
      ),
  },
  // ======================================
  // == Request Sizing ====================
  // ======================================
  {
    title: 'Right-size your container requests',
    description:
      'Over-provisioned containers provide an opportunity to lower requests and save money. Under-provisioned containers may cause CPU throttling or memory-based evictions.',
    url: '../request-sizing',
    icon: <ColoredKubernetesIcon />,
    isBeta: true,
    isBetaMessage:
      'This calculation is being handled by a beta API and should be used with caution!',
    savingsType: 'kube',
    dataLoader: async () =>
      fetchRequestSizingRecommendationsMaxHeadroom('2d', 0.8, 0.8, []).then(({ monthlySavings }) =>
        Math.max(0, monthlySavings),
      ),
  },
  // ======================================
  // == Abandoned Workloads ===============
  // ======================================
  {
    title: 'Remedy abandoned workloads',
    description:
      "Scale down, delete or resize pods that don't send or receive a meaningful rate of network traffic.",
    url: '../abandoned-workloads',
    icon: <ColoredKubernetesIcon />,
    isBeta: false,
    isBetaMessage: '',
    savingsType: 'kube',
    dataLoader: async () =>
      fetchAbandonedWorkloads(2, 500).then((workloads: any) =>
        workloads.reduce((total: number, workload: any) => workload.monthlySavings + total, 0),
      ),
  },
];

const savings: SavingsItem[] = [
  // Add in the cloud Savings.
  // To enable a savings card on KC Cloud
  ...cloudSavings,
  // ======================================
  // == Reserved Instances ================
  // ======================================
  {
    title: 'Reserve instances',
    description:
      'Consider purchasing reserved instances based on historical resource usage patterns.',
    url: '../reserved-instances',
    icon: <ColoredCloudIcon />,
    isBeta: false,
    isBetaMessage: '',
    savingsType: 'cloud',
    dataLoader: getReservedRecSavings,
  },
  // ======================================
  // == Persistent Volumes ================
  // ======================================
  {
    title: 'Manage unclaimed volumes',
    description:
      'Delete volumes that are unused by any pods or move them to a cheaper storage tier.',
    url: '../unclaimed-volumes',
    icon: <ColoredKubernetesIcon />,
    isBeta: false,
    isBetaMessage: '',
    savingsType: 'kube',
    dataLoader: getPvSavings,
  },
  // ======================================
  // == Local Disks =======================
  // ======================================
  {
    title: 'Resize local disks',
    description:
      'Resize local disks with low utilization, and see whether you may launch new nodes with smaller disks on the next node turndown.',
    url: '../low-disk',
    icon: <ColoredKubernetesIcon />,
    isBeta: false,
    isBetaMessage: '',
    savingsType: 'kube',
    dataLoader: getUnutilizedLocalDiskSavings,
  },

  // ======================================
  // == Underutilized Nodes ===============
  // ======================================
  {
    title: 'Manage underutilized nodes',
    description: 'Turn down or resize nodes with low memory and CPU utilization.',
    url: '../nodes',
    icon: <ColoredKubernetesIcon />,
    isBeta: false,
    isBetaMessage: '',
    savingsType: 'kube',
    dataLoader: async () =>
      getSavingsSummary().then((savingsSummary) => savingsSummary.nodeTurndown),
  },

  // ======================================
  // == Unassigned Resources ==============
  // ======================================
  {
    title: 'Delete unassigned resources',
    description:
      'Disks and IP addresses that are not being used by any clusters may continue to incur charges.',
    url: '../orphaned-resources',
    icon: <ColoredCloudIcon />,
    isBeta: false,
    isBetaMessage: '',
    savingsType: 'cloud',
    dataLoader: async () =>
      Promise.all([getUnassignedAddressSavings(), getUnassignedDiskSavings()]).then(
        ([addrSavings, diskSavings]) => addrSavings + diskSavings,
      ),
  },
  // ======================================
  // == Spot Sizing =======================
  // ======================================
  {
    title: 'Spot Instances',
    description:
      'Identify workloads ready for spot (preemptible) nodes and resize your cluster to realize the savings of migrating workloads to spot.',
    url: '../spot',
    icon: <ColoredCloudIcon />,
    isBeta: false,
    isBetaMessage: '',
    savingsType: 'cloud',
    dataLoader: async () =>
      fetchSpotChecklistClusterSizing(
        defaultSpotMinOnDemandNodeCount,
        defaultSpotMinNodeCount,
        defaultSpotTargetUtilization,
        defaultSpotAllowSharedCore,
        defaultSpotWindow,
      ).then((sizing: any) => sizing.monthlySavings),
  },
  // ======================================
  // == Persistent Volume Sizing ==========
  // ======================================
  {
    title: 'Right-size your persistent volumes',
    description:
      'Adjust the size of your persistent volumes to stop over-spending on unused capacity.',
    url: '../persistent-volume-sizing',
    icon: <ColoredKubernetesIcon />,
    isBeta: true,
    isBetaMessage:
      'This calculation is being handled by a beta API and should be used with caution!',
    savingsType: 'kube',
    dataLoader: getPvSavings,
  },
];

export { cloudSavings, savings, savingsCardCtaText };
