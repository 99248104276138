import { useQuery } from 'react-query';

import { useAPIClient } from '../services/APIClient';
import { KubecostResponse } from '../services/kubecost';

type NetworkTrafficDestinationId = {
  name: string;
  namespace: string;
  type: string;
};
type NetworkTrafficDestination = {
  destinationAddresses: Array<string>;
  destinationIp: string;
  destinationsIds: Array<NetworkTrafficDestinationId>;
  internet: boolean;
  region: boolean;
  totalBytes: number;
  zone: boolean;
};
type NetworkTrafficEntry = {
  destinations: Array<NetworkTrafficDestination>;
  sourceAddresses: Array<string>;
  sourceIds: Array<{
    name: string;
    namespace: string;
    type: string;
  }>;
  sourceIp: string;
};
type NetworkTraffic = Array<NetworkTrafficEntry>;
const useNetworkTraffic = (params?: { maxDestinations?: number; namespace?: string | null }) => {
  const APIClient = useAPIClient();

  return useQuery(
    ['networkTraffic', { ...params }],
    async () =>
      (
        await APIClient.get<KubecostResponse<NetworkTraffic>>('/networkTraffic', {
          params,
        })
      ).data,
  );
};

type NetworkTrafficStatus = Array<{
  available: boolean;
  pod: string;
  reason: string;
}>;
const useNetworkTrafficStatus = () => {
  const APIClient = useAPIClient();

  return useQuery(
    ['networkTrafficStatus'],
    async () =>
      (await APIClient.get<KubecostResponse<NetworkTrafficStatus>>('/networkTrafficStatus')).data,
  );
};

export {
  NetworkTraffic,
  NetworkTrafficDestination,
  NetworkTrafficDestinationId,
  NetworkTrafficEntry,
  NetworkTrafficStatus,
  useNetworkTraffic,
  useNetworkTrafficStatus,
};
