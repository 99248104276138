import { useEffect } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import BetaUINotificaton from './components/BetaUINotification';
import { EnablementsModal, EnablementsModalNew } from './components/EnablementsModal';
import { Page } from './components/Page';
import { useBetaUI } from './hooks/useBetaUI';
import AbandonedWorkloads from './pages/AbandonedWorkloads';
import { AllActions } from './pages/Actions/AllActions';
import { UnderUtilizedResources as LowCPUMemory } from './pages/Actions/LowCPUMemory';
import { UnderUtilizedResources as LowCPUMemoryNew } from './pages/Actions/LowCPUMemoryNew';
import LowDiskUsage from './pages/Actions/LowDiskUsage';
import { LowDiskUsageNew } from './pages/Actions/LowDiskUsageNew';
import ReservedInstances from './pages/Actions/ReservedInstances';
import { ReservedInstancesNew } from './pages/Actions/ReservedInstancesNew';
import UnclaimedVolumes from './pages/Actions/UnclaimedVolumes';
import { UnclaimedVolumesNew } from './pages/Actions/UnclaimedVolumesNew';
import AdvancedReportingDetail from './pages/AdvancedReportingDetail';
import AdvancedReportingManager from './pages/AdvancedReportingManager';
import { Notifications as Alerts } from './pages/Alerts';
import { AlertsNew } from './pages/AlertsNew';
import Allocation from './pages/Allocation';
import { AllocationNew } from './pages/AllocationNew';
import Assets from './pages/Assets';
import { AssetPageMemoized } from './pages/AssetsNew';
import { CloudCostsPage } from './pages/Cloud';
import ClusterList from './pages/ClusterList';
import ClusterSizing from './pages/ClusterSizing';
import ClusterSizingNew from './pages/ClusterSizingNew';
import Diagnostics from './pages/Diagnostics';
import ETLStatus from './pages/ETLStatus';
import Health from './pages/Health';
import { Health as HealthNew } from './pages/HealthNew';
import Inspect from './pages/Inspect';
import { Inspect2 } from './pages/Inspect/index2';
import KeyInstructions from './pages/KeyInstructions';
import { KeyInstructions as KeyInstructionsNew } from './pages/KeyInstructionsNew';
import { NetworkAllocationPage } from './pages/NetworkAllocation';
import { NewAdvancedReporting } from './pages/NewAdvancedReporting';
import OrphanedResources from './pages/OrphanedResources';
import OverviewOld from './pages/Overview';
import { Overview } from './pages/OverviewNew';
import PersistentVolumeSizing from './pages/PersistentVolumeSizing';
import Reports from './pages/Reports';
import { ReportsNew } from './pages/ReportsNew';
import RequestSizing from './pages/RequestSizing';
import { RequestSizing as RequestSizingNew } from './pages/RequestSizingNew';
import { Reservations } from './pages/Reservations';
import Savings from './pages/Savings';
import { Savings as SavingsNew } from './pages/SavingsNew';
import { Settings } from './pages/Settings';
import { SpotChecklistEstimatedPage as Spot } from './pages/Spot';
import UnauthorizedNew from './pages/UnauthorizedNew';

const rewriteLegacyRoutes = (url: string): string => {
  // action.html routes were renamed
  // these substitutions are specific to those views
  const actionMap = {
    '/nodes': /\/action\.html\?a=unusednodes$/,
    '/low-disk': /\/action\.html\?a=lowlocalstorage$/,
    '/reserved-instances': /\/action\.html\?a=reserved$/,
    '/unclaimed-volumes': /\/action\.html\?a=unclaimedvolumes$/,
  };
  let loc = url;
  Object.entries(actionMap).forEach(([newRoute, oldRoute]) => {
    loc = loc.replace(oldRoute, newRoute);
  });

  // general /page.html -> /page substitution
  if (url.indexOf('.html') > -1) {
    loc = loc.replace('.html', '');
  }
  return loc;
};

const KubecostRoutes = () => {
  const { isBetaUI } = useBetaUI();

  /*
   * This effect is meant to automatically redirect the user
   * away from .html pages while preserving their legacy links/bookmarks.
   */
  useEffect(() => {
    // actually set the window location
    const newRoute = rewriteLegacyRoutes(window.location.href);

    if (newRoute !== window.location.href) window.location.href = newRoute;
  }, []);

  return (
    <Routes>
      <Route element={<Page />}>
        <Route element={<Navigate to={'/'} />} path={'/index'} />

        {/* Routes that have not been updated to support the new UI */}
        <Route element={<BetaUINotificaton />}>
          <Route element={<AbandonedWorkloads />} path={'abandoned-workloads'} />
          <Route element={<ClusterList />} path={'context-list'} />
          <Route element={<Diagnostics />} path={'diagnostics'} />
          <Route element={<ETLStatus />} path={'etl-status'} />
          <Route element={<OrphanedResources />} path={'orphaned-resources'} />
          <Route element={<Reservations />} path={'reservations'} />
          <Route element={<Spot />} path={'spot'} />
        </Route>
        <Route element={<Settings />} path={'settings'} />

        {/* Routes that are only supported in the new UI */}
        <Route element={<PersistentVolumeSizing />} path={'persistent-volume-sizing'} />
        <Route element={<UnauthorizedNew />} path={'unauthorized'} />
        <Route element={<CloudCostsPage />} path={'cloud'} />
        <Route element={<NetworkAllocationPage />} path={'network'} />
        <Route element={<AllActions />} path={'actions'} />

        {isBetaUI ? (
          // New UI routes below share the same path as the "old" one but render the new UI component
          <>
            <Route element={<EnablementsModalNew />}>
              <Route element={<Overview />} path={'/'} />
              <Route element={<Overview />} path={'/overview'} />
              <Route element={<AllocationNew />} path={'/allocations'} />
              <Route element={<AssetPageMemoized />} path={'assets'} />
              <Route element={<NewAdvancedReporting />} path={'advanced-reporting'} />
              <Route element={<AlertsNew />} path={'alerts'} />
              <Route element={<ClusterSizingNew />} path={'cluster-sizing'} />
              <Route element={<HealthNew />} path={'health'} />
              <Route element={<ReportsNew />} path={'reports'} />
              <Route element={<RequestSizingNew />} path={'request-sizing'} />
              <Route element={<LowDiskUsageNew />} path={'low-disk'} />
              <Route element={<LowCPUMemoryNew />} path={'nodes'} />
              <Route element={<ReservedInstancesNew />} path={'reserved-instances'} />
              <Route element={<UnclaimedVolumesNew />} path={'unclaimed-volumes'} />
              <Route element={<Inspect2 />} path={'details'} />
              <Route element={<KeyInstructionsNew />} path={'keyinstructions'} />
            </Route>
            <Route element={<SavingsNew />} path={'savings'} />
          </>
        ) : (
          // Old UI routes below share the same path as the "new" ones above but render the old UI component
          <Route element={<EnablementsModal />}>
            <Route element={<OverviewOld />} path={'/'} />
            <Route element={<OverviewOld />} path={'/overview'} />
            <Route element={<Allocation />} path={'/allocations'} />
            <Route element={<Assets />} path={'assets'} />
            <Route element={<Savings />} path={'savings'} />
            <Route element={<AdvancedReportingDetail />} path={'advanced-reporting-detail'} />
            <Route element={<AdvancedReportingManager />} path={'advanced-reporting'} />
            <Route element={<Alerts />} path={'alerts'} />
            <Route element={<ClusterSizing />} path={'cluster-sizing'} />
            <Route element={<Health />} path={'health'} />
            <Route element={<Reports />} path={'reports'} />
            <Route element={<RequestSizing />} path={'request-sizing'} />
            <Route element={<LowDiskUsage />} path={'low-disk'} />
            <Route element={<LowCPUMemory />} path={'nodes'} />
            <Route element={<ReservedInstances />} path={'reserved-instances'} />
            <Route element={<UnclaimedVolumes />} path={'unclaimed-volumes'} />
            <Route element={<Inspect />} path={'details'} />
            <Route element={<KeyInstructions />} path={'keyinstructions'} />
          </Route>
        )}
      </Route>
    </Routes>
  );
};

export { KubecostRoutes, rewriteLegacyRoutes };
