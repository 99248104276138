// Trying this out per some React 18/19 docs on there best practices
// is showing 0 bites on import which is nice.
import * as React from 'react';

import { Tooltip } from '@kubecost-frontend/holster';

import { SquareInfoIcon } from '../../assets/images';

interface HighlightCardTooltipProps {
  description?: string;
  title?: string;
}

const HighlightCardTooltip: React.FC<HighlightCardTooltipProps> = ({
  description = '',
  title = '',
}) => (
  <Tooltip className={'text-sm absolute top-0 left-0 w-[325px]'} content={description}>
    <div className={'flex'}>
      {title}
      <div className={'pt-1 pl-1'}>
        <SquareInfoIcon color={'#607971'} />
      </div>
    </div>
  </Tooltip>
);

export { HighlightCardTooltip };
