import { Alert } from '@kubecost-frontend/holster';

type TestResult = {
  error: string | null;
  status: 'Success' | 'Failure' | 'Not Configured';
};

type TestResults = { [key: string]: TestResult };

type TestResultItemProps = {
  testStatus: TestResult;
  title: string;
};

const TestResultItem = ({ testStatus, title }: TestResultItemProps): any => {
  const { status } = testStatus;

  if (status === 'Not Configured') return <></>;

  return (
    <Alert
      content={''}
      title={`${title} - ${status}`}
      variant={status === 'Success' ? 'success' : 'danger'}
    />
  );
};

export { TestResult, TestResultItem, TestResults };
