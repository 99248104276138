import { get } from 'lodash';

const regex = /^(https?:\/\/)(.+):(.+)@(.+)/;

type Cluster = {
  address: string;
  details: {};
  federatedCluster: boolean;
  localCluster: boolean;
  name: string;
  online: boolean;
  url: string; // Local is anything not retrieved from the server.
};

const handleSetCluster = (cluster: any) => {
  const basicAuthAddress = regex.exec(cluster.address);
  const { auth } = cluster.details;

  if (!(basicAuthAddress || auth)) {
    localStorage.setItem('container', cluster.address);
    window.location.reload();
  } else {
    window.open(cluster.address);
  }
};

async function fetchClusterList(allEndpoints: string[]): Promise<any> {
  const clusters: Array<Cluster> = [];
  await Promise.all(
    allEndpoints.map(async (endpoint) => {
      const cluster = endpoint.replace(/\/api$/, '');
      const url = `${cluster}/model/clusterInfo?req=${Date.now()}`;

      try {
        const infoRes = await fetch(url);
        const info = await infoRes.json();

        const name = get(info, 'data.name', cluster);
        const federatedCluster = get(info, 'data.thanosEnabled', false);

        clusters.push({
          name,
          url,
          federatedCluster: federatedCluster === 'true',
          address: cluster,
          details: get(info, 'details', {}),
          online: true,
        });
      } catch (e) {
        clusters.push({
          address: cluster,
          url,
          federatedCluster: false,
          name: cluster,
          details: {},
          online: false,
        });
      }
    }),
  );
  return clusters;
}

const queryContext = async (endpoint: string, local: boolean): Promise<Cluster> => {
  const cluster = endpoint.replace(/\/api$/, '');
  const url = `${cluster}/model/clusterInfo?req=${Date.now()}`;

  try {
    const infoRes = await fetch(url);
    const info = await infoRes.json();

    const name = get(info, 'data.name', cluster);
    const federatedCluster = get(info, 'data.thanosEnabled', false);

    return {
      name,
      url,
      federatedCluster: federatedCluster === 'true',
      address: cluster,
      details: get(info, 'details', {}),
      online: true,
      localCluster: local,
    };
  } catch (e) {
    return {
      address: cluster,
      url,
      federatedCluster: false,
      name: cluster,
      details: {},
      online: false,
      localCluster: local,
    };
  }
};

// function addNewCluster(newClusterAddress: string) {
//  const address = `http://${sanitizeHTML(newClusterAddress.trim())
//    .replace(/^https?:\/\//, '')
//    .replace(/\/$/, '')}`;
//
//  if (localClusterEndpoints.includes(address)) {
//    return alert(`This address (${address}) has already been added`);
//  }
//
//  return addContext(address);
// }

export { Cluster, fetchClusterList, handleSetCluster, queryContext, regex };
