import { useQuery } from 'react-query';

import { useAPIClient } from '../../../services/APIClient';
import { KubecostResponse } from '../../../services/kubecost';
import { AllocationSet, AllocationSummaryResponse } from '../../../types/allocation';
import { AllocationSummaryParams } from '../types';

// TODO: clean this up into a dynamic query key
const allocationSummaryQueryKey = ['allocation', 'summary'];
const allocationQueryKey = (params: any) => ['allocation', { ...params }];

const queryAllocationSummary = async (params: AllocationSummaryParams) => {
  const APIClient = useAPIClient();
  return (
    await APIClient.get<KubecostResponse<AllocationSummaryResponse>>('/allocation/summary', {
      params: {
        ...params,
      },
    })
  ).data;
};

const useGetAllocationSummary = (params: AllocationSummaryParams) => {
  const { data, ...args } = useQuery([allocationSummaryQueryKey, { useErrorBoundary: true }], () =>
    queryAllocationSummary(params),
  );
  return {
    data,
    summary: data?.data,
    ...args,
  };
};

const queryAllocation = async (params: AllocationSummaryParams) => {
  const APIClient = useAPIClient();
  return (
    await APIClient.get<{ data: AllocationSet[] }>('/allocation', {
      params: {
        ...params,
      },
    })
  ).data;
};

const useGetAllocation = (params: AllocationSummaryParams) => {
  const { data, ...args } = useQuery([allocationQueryKey(params), { useErrorBoundary: true }], () =>
    queryAllocation(params),
  );
  return {
    data,
    allocations: data?.data,
    ...args,
  };
};

export { useGetAllocation, useGetAllocationSummary };
