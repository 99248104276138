import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { analytics as Analytics } from '../services/analytics';

export function usePageViewRecorder() {
  const location = useLocation();

  useEffect(() => {
    Analytics.record('page_visit', {
      url: location.pathname,
      search: location.search,
    });
  }, [location]);
}
