import { FC, HTMLAttributes } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

interface LoadingProps extends HTMLAttributes<HTMLDivElement> {
  message?: string;
}

const Loading: FC<LoadingProps> = ({ className = '', message = '', ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <div className={`w-full text-center ${className}`} {...rest}>
    <CircularProgress />
    {message && (
      <Typography color={'textSecondary'} variant={'h6'}>
        {message}
      </Typography>
    )}
  </div>
);

Loading.displayName = 'Loading';

export { Loading };
