import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';

import { getCurrentContainerAddressModel } from '../util';

const baseURL = getCurrentContainerAddressModel();
const axiosConfig: AxiosRequestConfig = {
  baseURL,
  paramsSerializer: {
    serialize: (params) => qs.stringify(params, { arrayFormat: 'comma' }),
  },

  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const APIClient = axios.create(axiosConfig);

const useAPIClient = () => APIClient;

export { APIClient, useAPIClient };
