import { useState } from 'react';

import { uniqBy } from 'lodash';
import { useQueries } from 'react-query';

import { Modal } from '@kubecost-frontend/holster';

import { useClusters } from '../../../contexts/ClusterConfig';

import { AddContextSwitcherForm } from './AddContextSwitcherForm';
import { ContextRow } from './ContextRow';
import { queryContext } from './util';

type ContextSwitcherModalProps = {
  onClose: () => void;
  open: boolean;
};

const ContextSwitcherModal = ({ onClose, open }: ContextSwitcherModalProps) => {
  const [showAddForm, setShowAddForm] = useState(false);
  const { addContext, localClusterEndpoints, remoteClusterEndpoints } = useClusters();

  const contexts = useQueries(
    uniqBy(
      [
        ...remoteClusterEndpoints.map((endpoint) => ({
          queryKey: ['context', endpoint],
          queryFn: async () => queryContext(endpoint, false),
        })),

        ...localClusterEndpoints.map((endpoint) => ({
          queryKey: ['context', endpoint],
          queryFn: async () => queryContext(endpoint, true),
        })),
      ],
      'queryKey',
    ),
  );

  return (
    <Modal onClose={() => onClose()} open={open} title={'Contexts'} noPadding>
      <div className={'border-t border-kc-white-200'}>
        <div style={{ padding: 0 }}>
          {contexts.map(({ data }) => (
            <ContextRow cluster={data!} key={data && data.address} />
          ))}
        </div>
      </div>
      {showAddForm && (
        <AddContextSwitcherForm
          contextAdded={(context) => {
            addContext(context);
            setShowAddForm(false);
          }}
        />
      )}
      {!showAddForm && (
        <div className={'p-6'}>
          <button className={'text-kc-link'} onClick={() => setShowAddForm(true)} type={'button'}>
            Add Cluster
          </button>
        </div>
      )}
    </Modal>
  );
};

export { ContextSwitcherModal };
