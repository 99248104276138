import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useAPIClient } from '../services/APIClient';
import { ModelConfig } from '../services/model';

export const modelConfigQueryKey = ['model', 'modelConfig'];

const queryModelConfigApi = async () => {
  const APIClient = useAPIClient();
  return (
    await APIClient.get<{ data: ModelConfig }>(
      `/getConfigs?q=${Math.floor(Math.random() * Math.floor(1000000))}`,
    )
  ).data;
};

export const useGetModelConfig = () => {
  const APIClient = useAPIClient();
  const queryClient = useQueryClient();
  const { data, ...args } = useQuery(modelConfigQueryKey, queryModelConfigApi);

  const { mutate } = useMutation((payload) => APIClient.post('/updateConfigByKey', payload), {
    onSuccess() {
      queryClient.invalidateQueries(modelConfigQueryKey);
    },
  });

  return {
    data,
    updateModelConfig: mutate,
    modelConfig: data?.data,
    ...args,
  };
};
