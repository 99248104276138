import { Link } from 'react-router-dom';

import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import SettingsIcon from '@material-ui/icons/Settings';

import { Typography } from '@kubecost-frontend/holster';

import { AlertsNavIcon } from '../../assets/images/alerts-nav-icon';
import { AllocationNavIcon } from '../../assets/images/allocation-nav-icon';
import { AssetsNavIcon } from '../../assets/images/assets-nav-icon';
import { CloudNavIcon } from '../../assets/images/cloud-nav-icon';
import { HealthNavIcon } from '../../assets/images/health-nav-icon';
import { OverviewNavIcon } from '../../assets/images/overview-nav-icon';
import { ReportsNavIcon } from '../../assets/images/reports-nav-icon';
import { SavingsNavIcon } from '../../assets/images/savings-nav-icon';
import useEnablements, { EnablementKeys } from '../../hooks/useEnablements';
import logo from '../../images/kubecost-full-logo.png';

import ContextSwitcher from './ContextSwitcher';
import { NavItem } from './NavItem';

const Nav = ({ active }: { active: string }) => {
  const { data: enablements } = useEnablements();

  const top = [
    { name: 'Overview', href: 'overview', icon: OverviewNavIcon },
    {
      name: 'Allocations',
      href: 'allocations',
      icon: AllocationNavIcon,
    },
    { name: 'Assets', href: 'assets', icon: AssetsNavIcon },
    { name: 'Savings', href: 'savings', icon: SavingsNavIcon },
    { name: 'Health', href: 'health', icon: HealthNavIcon },
    { name: 'Reports', href: 'reports', icon: ReportsNavIcon },
    { name: 'Alerts', href: 'alerts', icon: AlertsNavIcon },
  ];

  if (enablements && enablements[EnablementKeys.cloudCosts]?.isEnabled) {
    top.splice(3, 0, {
      name: 'Cloud Costs',
      href: 'cloud',
      icon: CloudNavIcon,
    });
  }

  return (
    <div className={'w-[240px] shrink-0 grow-0 bg-kc-background'}>
      <nav className={'fixed flex h-full w-[240px] flex-col p-4'}>
        <img alt={'Kubecost'} className={'mx-5 mb-4 mt-6 w-[135px]'} src={logo} />

        <div className={'grow'}>
          {top.map((l) => (
            <NavItem
              active={active === `/${l.href}` || (l.name === 'Overview' && active === '/')}
              href={l.href}
              icon={l.icon}
              key={l.name}
              name={l.name}
            />
          ))}
        </div>
        <div>
          <ContextSwitcher />
          <Link to={'settings'}>
            <div className={'flex items-center p-4'}>
              <SettingsIcon className={'mr-4'} />
              <Typography
                className={'cursor-pointer font-bold hover:text-kc-primary'}
                variant={'p'}
              >
                Settings
              </Typography>
            </div>
          </Link>
          {document.cookie.indexOf('token=') !== -1 && (
            <Link className={'flex items-center p-4'} to={'/logout'} reloadDocument>
              <MeetingRoomIcon className={'mr-4'} />
              <Typography
                className={'cursor-pointer font-bold hover:text-kc-primary'}
                variant={'p'}
              >
                Logout
              </Typography>
            </Link>
          )}
        </div>
      </nav>
    </div>
  );
};

export { Nav };
