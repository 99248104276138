import { UseQueryResult } from 'react-query';

import { Button, Modal, Typography } from '@kubecost-frontend/holster';

import { NetworkTraffic } from '../../../hooks/useNetworkTraffic';
import { KubecostResponse } from '../../../services/kubecost';
import { Allocation } from '../../../types/allocation';

import { NetworkAllocationModalTable } from './NetworkAllocationModalTable';

interface NetworkAllocationModalProps {
  handleOnClose: () => void;
  networkTrafficQuery: UseQueryResult<KubecostResponse<NetworkTraffic>>;
  selectedAllocationData: Allocation | null;
}

const NetworkAllocationModal = ({
  handleOnClose,
  networkTrafficQuery,
  selectedAllocationData,
}: NetworkAllocationModalProps) => (
  <Modal
    onClose={handleOnClose}
    open={selectedAllocationData !== null}
    title={'IP Network History'}
  >
    <Typography onClick={handleOnClose} variant={'p'}>
      Recent network destinations for this pod.
    </Typography>
    <NetworkAllocationModalTable
      networkTrafficQuery={networkTrafficQuery}
      selectedAllocationData={selectedAllocationData}
    />
    <div className={'mt-2 flex justify-end'}>
      <Button onClick={handleOnClose} variant={'primary'}>
        Close
      </Button>
    </div>
  </Modal>
);

export { NetworkAllocationModal };
