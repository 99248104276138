import {
  CircularProgress,
  CircularProgressProps,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';

// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    bottom: {
      color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
      color: '#1a90ff',
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },
    circle: {
      strokeLinecap: 'round',
    },
  }),
);

const FacebookCircularProgress = (props: CircularProgressProps) => {
  const { bottom, circle, root, top } = useStylesFacebook();

  return (
    <div className={root}>
      <CircularProgress
        className={bottom}
        size={40}
        thickness={4}
        variant={'determinate'}
        {...props}
        value={100}
      />
      <CircularProgress
        className={top}
        classes={{ circle }}
        size={40}
        thickness={4}
        variant={'indeterminate'}
        {...props}
      />
    </div>
  );
};

export { FacebookCircularProgress, useStylesFacebook };
