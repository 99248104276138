import { WindowOptions } from '../AssetsNew/AssetControls/types';

type AggregationType = {
  name: string;
  value: string;
};

type AlertType =
  | 'budget'
  | 'efficiency'
  | 'recurringUpdate'
  | 'spendChange'
  | 'assetBudget'
  | 'cloudReport';

type AlertTypeWithDescription = SelectOptions & Description;

type Description = {
  description?: string;
};

type SelectOptions = {
  label: string;
  value: AlertType;
};

const alertTypeOpts: AlertTypeWithDescription[] = [
  {
    label: 'Allocation Budget',
    value: 'budget',
    description:
      'Allocation Budget alerts are triggered when the total cost of allocations goes over a set budget limit.',
  },
  {
    label: 'Allocation Efficiency',
    value: 'efficiency',
    description:
      'Allocation Efficiency alerts are triggered when the average efficiency of allocations falls below a set threshold.',
  },
  {
    label: 'Allocation Recuring Update',
    value: 'recurringUpdate',
    description:
      'Allocation Recurring alerts have no trigger. They simply issue a report on the state of the allocations every <date range> days.',
  },
  {
    label: 'Allocation Spend Change',
    value: 'spendChange',
    description:
      'Allocation Spend change alerts are triggered when a significant jump or drop in spend relative to the average occurs.',
  },
  {
    label: 'Asset Budget',
    value: 'assetBudget',
    description:
      'Asset Budget alerts are triggered when the total cost of assets goes over a set budget limit.',
  },
  {
    label: 'Cloud Report',
    value: 'cloudReport',
    description:
      'Asset Recurring alerts have no trigger. They simply issue a report on the state of the assets every <date range> days.',
  },
];

const allocationAggregationOptions = [
  {
    name: 'Cluster',
    value: 'cluster',
  },
  {
    name: 'Controller',
    value: 'controller',
  },
  {
    name: 'Container',
    value: 'container',
  },
  {
    name: 'Deployment',
    value: 'deployment',
  },
  {
    name: 'Label',
    value: 'label',
  },
  {
    name: 'Namespace',
    value: 'namespace',
  },
  {
    name: 'Pod',
    value: 'pod',
  },
  {
    name: 'Service',
    value: 'service',
  },
];

const assetAggregationOptions = [
  {
    name: 'Service',
    value: 'service',
  },
  {
    name: 'Type',
    value: 'type',
  },
  {
    name: 'Category',
    value: 'category',
  },
  {
    name: 'Cluster',
    value: 'cluster',
  },
  {
    name: 'Provider',
    value: 'provider',
  },
  {
    name: 'Account',
    value: 'account',
  },
  {
    name: 'Provider ID',
    value: 'ProviderID',
  },
];

const windowOptions: WindowOptions = [
  { label: '1 day', value: '1d' },
  { label: '2 days', value: '2d' },
  { label: '3 days', value: '3d' },
  { label: '4 days', value: '4d' },
  { label: '5 days', value: '5d' },
  { label: '6 days', value: '6d' },
  { label: '7 days', value: '7d' },
];

const WEBHOOKS = {
  SLACK: {
    PLACEHOLDER:
      'https://hooks.slack.com/services/XXXXXXXXXXX/XXXXXXXXXXX/XXXXXXXXXXXXXXXXXXXXXXXX',
    DEFAULT: '',
  },
  MS_TEAMS: {
    PLACEHOLDER: 'https://m365x682156.webhook.office.com',
    DEFAULT: '',
  },
};

export {
  AggregationType,
  AlertType,
  AlertTypeWithDescription,
  Description,
  SelectOptions,
  WEBHOOKS,
  alertTypeOpts,
  allocationAggregationOptions,
  assetAggregationOptions,
  windowOptions,
};
