import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BarChartIcon from '@material-ui/icons/BarChart';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import HomeIcon from '@material-ui/icons/Home';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ReportIcon from '@material-ui/icons/Report';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/styles';
import { useState, useEffect, useContext } from 'react';
import { SearchBarContext } from '../SearchBarContext';

import ContextSwitcher from './ContextSwitcher';
import { NavItem } from './NavItem';

const drawerWidth = 200;

const useStyles = makeStyles({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: 'inherit',
    border: 0,
    width: drawerWidth,
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

const Nav = ({ active }: { active: string }) => {
  const classes = useStyles();

  // Search box
  const { setIsVisible } = useContext(SearchBarContext);

  const [init, setInit] = useState(false);

  useEffect(() => {
    if (!init) {
      setInit(true);
    }
  }, [init]);

  const top = [
    { name: 'Overview', href: 'overview', icon: <HomeIcon /> },
    {
      name: 'Cost Allocation',
      href: 'allocations',
      icon: <BarChartIcon />,
    },
    { name: 'Assets', href: 'assets', icon: <DeveloperBoardIcon /> },
    { name: 'Savings', href: 'savings', icon: <AttachMoneyIcon /> },
    { name: 'Health', href: 'health', icon: <ReportIcon /> },
    { name: 'Reports', href: 'reports', icon: <AssignmentIcon /> },
    { name: 'Alerts', href: 'alerts', icon: <NotificationsIcon /> },
  ];

  return (
    <Drawer
      anchor={'left'}
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
      variant={'permanent'}
    >
      <List style={{ flexGrow: 1 }}>
        {top.map((l) => (
          <NavItem active={active === `/${l.href}`} key={l.name} {...l} />
        ))}
      </List>
      <List>
        <ContextSwitcher />
        <NavItem icon={<SearchIcon />} name={'Search Docs'} onClick={() => setIsVisible(true)} />
        <NavItem
          active={active === '/settings'}
          href={'settings'}
          icon={<SettingsIcon />}
          name={'Settings'}
        />
        {document.cookie.indexOf('token=') != -1 && (
          <NavItem
            active={active === '/logout'}
            icon={<MeetingRoomIcon />}
            name={'Logout'}
            onClick={() => {
              window.location.href = '/logout';
            }}
          />
        )}
      </List>
    </Drawer>
  );
};

export { Nav };
