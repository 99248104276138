import filter from 'lodash/filter';
import flatMap from 'lodash/flatMap';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import has from 'lodash/has';
import isArray from 'lodash/isArray';
import omitBy from 'lodash/omitBy';
import moment from 'moment';

import billing from '../billing';
import { HelperDumpService as HelperDumpServiceClass } from '../helper_dump';
import logger from '../logger';
import { ModelConfigs, model } from '../model';
import ProviderService from '../provider';

interface RequestSizingRec {
  controllers: Controller[];
  monthlySavings: number;
  monthlySavingsCPU: number;
  monthlySavingsRAM: number;
}

interface Controller {
  clusterId: string;
  clusterName: string;
  containers: Record<string, Container>;
  efficiency: number;
  id: string;
  monthlySavings: number;
  monthlySavingsCPU: number;
  monthlySavingsRAM: number;
  name: string;
  namespace: string;
  pods: Record<string, Pod>;
  requests: { cpuCores: number; ramBytes: number };
  target: { cpuCores: number; ramBytes: number };
  type: string;
  usage: { cpuCores: number; ramBytes: number };
}

interface Container {
  clusterId: string;
  clusterName: string;
  efficiency: number;
  monthlySavings: number;
  monthlySavingsCPU: number;
  monthlySavingsRAM: number;
  name: string;
  namespace: string;
  requests: { cpuCores: number; ramBytes: number };
  target: { cpuCores: number; ramBytes: number };
  usage: { cpuCores: number; ramBytes: number };
}

interface Pod {
  clusterId: string;
  clusterName: string;
  containers: Record<string, Container>;
  controllers: Record<string, Controller>;
  efficiency: number;
  generateName: string;
  monthlySavings: number;
  monthlySavingsCPU: number;
  monthlySavingsRAM: number;
  name: string;
  namespace: string;
  requests: { cpuCores: number; ramBytes: number };
  target: { cpuCores: number; ramBytes: number };
  usage: { cpuCores: number; ramBytes: number };
}

interface SavingsSummary {
  abandonedWorkloads: number;
  clusterSizing: number;
  nodeTurndown: number;
  requestSizing: number;
}

interface NodeTurndownSavings {
  isMaster: boolean;
  nodeCanScaleDown: boolean;
  nodeName: string;
  nodeReason: Array<string>;
  podReasons: Array<string>;
  podsCanScaleDown: boolean;
  savings: number;
}

async function fetchNodeTurndownSavings(utilization = 0.6): Promise<NodeTurndownSavings[]> {
  return model.get<NodeTurndownSavings[]>('/savings/nodeTurndown', {
    utilization,
  });
}

async function fetchRequestSizingRecommendationsMaxHeadroom(
  window: string,
  targetCPUUtilization: number,
  targetRAMUtilization: number,
  filters: { type: string; value: string }[],
  requestOpts?: RequestInit,
): Promise<RequestSizingRec> {
  const params: Record<string, number | string> = {
    algorithm: 'max-headroom',
    window,
    targetCPUUtilization,
    targetRAMUtilization,
  };
  if (filters !== undefined && isArray(filters)) {
    forEach(filters, (f) => {
      if (f.type.toLowerCase() === 'cluster') {
        params.filterClusters = f.value;
      } else if (f.type.toLowerCase() === 'node') {
        params.filterNodes = f.value;
      } else if (f.type.toLowerCase() === 'namespace') {
        params.filterNamespaces = f.value;
      } else if (f.type.toLowerCase() === 'label') {
        params.filterLabels = f.value;
      } else if (f.type.toLowerCase() === 'service') {
        params.filterServices = f.value;
      } else if (f.type.toLowerCase() === 'controller kind') {
        params.filterControllerKinds = f.value;
      } else if (f.type.toLowerCase() === 'controller') {
        const tokens = f.value.split('/');
        if (tokens.length === 2) {
          [params.filterControllerKind, params.filterControllers] = tokens;
        } else {
          params.filterControllers = f.value;
        }
      } else if (f.type.toLowerCase() === 'pod') {
        params.filterPods = f.value;
      } else if (f.type.toLowerCase() === 'container') {
        params.filterContainers = f.value;
      } else {
        logger.warn(`Warning: failed to parse filter ${f.type}=${f.value}`);
      }
    });
  }
  return model.get('/savings/requestSizing', params, requestOpts);
}

// fetchClusterSizingRecommendations returns a set of node pool recommendations for the current
// cluster, as well as the parameters detected for computing the recommendations.
async function fetchClusterSizingRecommendations(
  window: string,
  targetUtilization: number,
  minNodeCount: number,
  allowSharedCore: boolean,
  architecture: string,
) {
  return model.get('/savings/clusterSizingETL', {
    window,
    targetUtilization,
    minNodeCount,
    allowSharedCore,
    architecture,
  });
}

// fetchSpotSizingRecommendations uses two sets of recommendation parameters to fetch a "spot ready" sized
// recommendation as well as an onDemand recommendation
async function fetchSpotSizingRecommendations(p, window, onDemandRecParameters, spotRecParameters) {
  const { allowSharedCore, minNodeCount, targetUtilization } = onDemandRecParameters;
  const {
    allowSharedCore: spotAllowSharedCore,
    minNodeCount: spotMinNodeCount,
    targetUtilization: spotTargetUtilization,
  } = spotRecParameters;

  // console.log("p: " + p + ", window: " + window)
  // console.log("targetUtil: " + targetUtilization + ", minNode: " + minNodeCount + ", allowSharedCore: " + allowSharedCore)
  // console.log("spotTargetUtil: " + spotTargetUtilization + ", spotMinNode: " + spotMinNodeCount + ", spotAllowSharedCore: " + spotAllowSharedCore)

  return model.get('/savings/spotSizing', {
    p,
    window,
    targetUtilization,
    minNodeCount,
    allowSharedCore,
    spotTargetUtilization,
    spotMinNodeCount,
    spotAllowSharedCore,
  });
}

// fetchSpotConversion returns the costs of converting the current node pools to spot
async function fetchSpotConversion() {
  return model.get('/savings/spotConversion');
}

interface AbandonedWorkload {
  allocation: { cpuCores: number; ramBytes: number };
  clusterId: string;
  clusterName: string;
  egressBytesPerSecond: number;
  ingressBytesPerSecond: number;
  monthlySavings: number;
  namespace: string;
  node: string;
  owners: { kind: string; name: string }[];
  pod: string;
  requests: { cpuCores: number; ramBytes: number };
  usage: { cpuCores: number; ramBytes: number };
}
// fetchAbandonedWorkloads queries Prometheus for pods that have neither sent nor
// received meaningful traffic in the given time interval
async function fetchAbandonedWorkloads(
  durDays = 2,
  bytesPerSecThreshold = 500,
): Promise<AbandonedWorkload[]> {
  const workloads = await model.get<AbandonedWorkload[]>('/savings/abandonedWorkloads', {
    days: durDays,
    threshold: bytesPerSecThreshold,
  });

  return workloads;
}

function fetchRequestSizing() {
  return model.get('/requestSizing').then((raw) => console.log(raw));
}

// fetchOrphanedDisks retrieves the full projectDisks response, then filters out
// only those that have no users
function fetchOrphanedDisks() {
  return model.get('/projectDisks').then((raw) => parseProjectDisks(raw));
}

// fetchOrphanedIPAddresses retrieves the full projectAddresses response, then filters out
// only those that have no users
function fetchOrphanedIPAddresses() {
  return model.get('/projectAddresses').then((raw) => parseProjectAddresses(raw));
}

function parseProjectAddresses(raw) {
  // parse GCP response
  if (has(raw, 'items')) {
    return parseProjectAddressesGCP(raw);
  }

  // parse AWS response
  if (has(raw, 'Addresses')) {
    return parseProjectAddressesAWS(raw);
  }

  return [];
}

function parseProjectAddressesGCP(raw) {
  // filter out empty regions
  const regions = omitBy(get(raw, 'items', {}), (r) => get(r, 'addresses', []).length === 0);

  // flatten addresses into a single array and select only relevant fields
  const addresses = flatMap(regions, (data) => get(data, 'addresses'));

  // filter only addresses without parents and select fields
  const orphaned = filter(addresses, (address) => get(address, 'users', []).length === 0).map(
    (address) => {
      let projectID = '';
      const projectRE = /projects\/([A-Za-z0-9\-]+)/;
      const projectMatch = address.selfLink.match(projectRE);
      if (projectMatch && projectMatch.length > 1) {
        projectID = projectMatch[1];
      }
      const url = `https://console.cloud.google.com/networking/addresses/list?project=${projectID}`;

      let region = get(address, 'region', 'global');
      if (region !== 'global') {
        const re = /\/([A-Za-z0-9\-]+)$/;
        const match = region.match(re);
        if (match && match.length > 0) {
          region = match[1];
        }
      }

      return {
        address: address.address,
        description: {
          type: address.addressType,
        },
        monthlyCost: 730.0 * billing.getHourlyIPAddressCost('gcp', region),
        name: address.name,
        region,
        url,
      };
    },
  );

  // select and re-format fields
  return orphaned;
}

function parseProjectAddressesAWS(raw) {
  const addresses = get(raw, 'Addresses', []);

  // filter only addresses without parents and select fields
  const orphaned = filter(addresses, (address) => get(address, 'AssociationId', null) === null).map(
    (address) => {
      const url = 'https://console.aws.amazon.com/ec2/home?#Addresses:';
      return {
        address: address.PublicIp,
        description: {
          tags: address.Tags,
        },
        monthlyCost: 730.0 * billing.getHourlyIPAddressCost('aws', null),
        name: address.AllocationId,
        region: null,
        url,
      };
    },
  );

  return orphaned;
}

function parseProjectDisks(raw) {
  // parse GCP response
  if (has(raw, 'items')) {
    return parseProjectDisksGCP(raw);
  }

  // parse AWS response
  if (has(raw, 'Volumes')) {
    return parseProjectDisksAWS(raw);
  }

  return [];
}

function parseProjectDisksAWS(raw) {
  const disks = get(raw, 'Volumes', []);

  const orphaned = filter(disks, (disk) => {
    // filter only disks either not in use or with no currently-attached attachments
    const attachments = get(disk, 'Attachments', []);
    const isAttached = filter(attachments, (a) => get(a, 'State', '') === 'attached').length > 0;
    const isInUse = get(disk, 'State', '') === 'in-use';
    return !isInUse || !isAttached;
  }).map((disk) => {
    const zone = get(disk, 'AvailabilityZone', '');

    const re = /([a-z]+-[a-z]+-[0-9])[a-zA-Z0-9]*/;
    const match = zone.match(re);
    let region = '';
    if (match && match.length > 0) {
      region = match[1];
    }

    const sizeGB = disk.Size ? parseFloat(disk.Size) : null;

    const url = `https://console.aws.amazon.com/ec2/home?region=${region}#Volumes:sort=desc:createTime`;

    const attachments = get(disk, 'Attachments', []) || [];
    let lastAttached = null;
    for (const attachment of attachments) {
      const attachmentTime = get(attachment, 'AttachTime', null);
      if (lastAttached === null || attachmentTime > lastAttached) {
        lastAttached = attachmentTime;
      }
    }

    return {
      monthlyCost: sizeGB * billing.getMonthlyStorageCost(disk.VolumeType),
      lastAttached,
      lastDetached: null,
      name: disk.VolumeId,
      description: '',
      region,
      sizeGB,
      url,
    };
  });

  return orphaned;
}

function parseProjectDisksGCP(raw) {
  // filter out empty regions
  const regions = omitBy(get(raw, 'items', {}), (r) => get(r, 'disks', []).length === 0);

  // flatten disks into a single array and select only relevant fields
  const disks = flatMap(regions, (data, region) =>
    get(data, 'disks').map((disk) => ({
      region,
      ...disk,
    })),
  );

  // filter only disks without parents and having not been used for at least an hour, then select fields
  const orphaned = filter(disks, (disk) => {
    if (get(disk, 'users', []).length > 0) {
      return false;
    }

    // do not consider disk orphaned if it was used within the last hour
    const threshold = moment().subtract(1, 'hour');
    const lastUsed = moment(get(disk, 'lastDetachTimestamp', 0));
    if (threshold.isBefore(lastUsed)) {
      return false;
    }

    return true;
  }).map((disk) => {
    let { region } = disk;
    const re = /zones\/([A-Za-z0-9\-]+)$/;
    const match = region.match(re);
    if (match && match.length > 0) {
      region = match[1];
    }

    const sizeGB = disk.sizeGb ? parseFloat(disk.sizeGb) : null;

    let projectID = '';
    const projectRE = /projects\/([A-Za-z0-9\-]+)/;
    const projectMatch = disk.selfLink.match(projectRE);
    if (projectMatch && projectMatch.length > 1) {
      projectID = projectMatch[1];
    }
    const zone = disk.region.substr(6);
    const url = `https://console.cloud.google.com/compute/disksDetail/zones/${zone}/disks/${disk.name}?project=${projectID}`;

    let { description } = disk;
    try {
      description = JSON.parse(disk.description);
    } catch (err) {}

    return {
      monthlyCost: sizeGB * billing.getMonthlyStorageCost(disk.type),
      lastAttached: disk.lastAttachTimestamp,
      lastDetached: disk.lastDetachTimestamp,
      name: disk.name,
      description,
      region,
      sizeGB,
      url,
    };
  });

  // select and re-format fields
  return orphaned;
}

const HelperDumpService = new HelperDumpServiceClass();

async function getSavingsSummary(): Promise<SavingsSummary> {
  const savingsSummary = await HelperDumpService.getSavingsSummaryPromise();
  return savingsSummary as SavingsSummary;
}

function getDiscount(modelConfigs: ModelConfigs): number {
  let discount;
  try {
    discount = parseFloat(modelConfigs.discount) / 100 || 0;
  } catch (err) {
    discount = 0;
  }

  let negotiatedDiscount;
  try {
    negotiatedDiscount = parseFloat(modelConfigs.negotiatedDiscount) / 100 || 0;
  } catch (err) {
    negotiatedDiscount = 0;
  }

  const totalDiscount = 1 - (1 - discount) * (1 - negotiatedDiscount);
  return totalDiscount;
}

async function getPvSavings(): Promise<number> {
  let pvSavings;
  try {
    const pvs = await ProviderService.fetchPersistentVolumes();
    pvSavings = accumulateSavings(pvs);
  } catch (err) {
    pvSavings = 0;
  }
  return pvSavings;
}

async function getReservedRecSavings(): Promise<number> {
  let reservedRecSavings;
  try {
    const reservedRecs = await HelperDumpService.getReservedRec(1);
    reservedRecSavings = accumulateSavings(reservedRecs);
  } catch (err) {
    reservedRecSavings = 0;
  }
  return reservedRecSavings;
}

async function getUnassignedAddressSavings(): Promise<number> {
  let unassignedAddressSavings;
  try {
    const addresses = await fetchOrphanedIPAddresses();
    unassignedAddressSavings = addresses.reduce(
      (total: number, address: { monthlyCost: number }) => total + address.monthlyCost,
      0.0,
    );
  } catch (err) {
    unassignedAddressSavings = 0;
  }
  return unassignedAddressSavings;
}

async function getUnassignedDiskSavings(): Promise<number> {
  let unassignedDiskSavings;
  try {
    const disks = await fetchOrphanedDisks();
    unassignedDiskSavings = disks.reduce((total, disk) => total + disk.monthlyCost, 0.0);
  } catch (err) {
    unassignedDiskSavings = 0;
  }
  return unassignedDiskSavings;
}

async function getUnutilizedLocalDiskSavings(): Promise<number> {
  const clusterInfo = await model.clusterInfo();
  let unutilizedDiskSavings;
  try {
    const unutilizedLocalDisks = await HelperDumpService.getUnutilizedLocalDisks(0.2, clusterInfo);
    unutilizedDiskSavings = accumulateSavings(unutilizedLocalDisks);
  } catch (err) {
    unutilizedDiskSavings = 0;
  }
  return unutilizedDiskSavings;
}

async function getEstimatedSavings(): Promise<{
  abandonedWorkloadSavings: number;
  clusterSizingSavings: number;
  pvSavings: number;
  requestSizingSavings: number;
  reservedRecSavings: number;
  totalSavings: number;
  turndownSavings: number;
  unassignedAddressSavings: any;
  unassignedDiskSavings: number;
  unutilizedDiskSavings: number;
}> {
  const clusterInfo = await model.clusterInfo();

  let pvSavings;
  try {
    const pvs = await ProviderService.fetchPersistentVolumes();
    pvSavings = accumulateSavings(pvs);
  } catch (err) {
    pvSavings = 0;
  }

  let clusterSizingSavings;
  let requestSizingSavings;
  let abandonedWorkloadSavings;
  let turndownSavings;
  try {
    const summary = await HelperDumpService.getSavingsSummaryPromise();
    // discounts are already applied, ETL sourced data.
    clusterSizingSavings = summary.clusterSizing;
    requestSizingSavings = summary.requestSizing;
    abandonedWorkloadSavings = summary.abandonedWorkloads;
    turndownSavings = summary.nodeTurndown;
  } catch (err) {
    clusterSizingSavings = 0;
    requestSizingSavings = 0;
    abandonedWorkloadSavings = 0;
    turndownSavings = 0;
  }

  let unutilizedDiskSavings;
  try {
    const unutilizedLocalDisks = await HelperDumpService.getUnutilizedLocalDisks(0.2, clusterInfo);
    unutilizedDiskSavings = accumulateSavings(unutilizedLocalDisks);
  } catch (err) {
    unutilizedDiskSavings = 0;
  }

  let reservedRecSavings;
  try {
    const reservedRecs = await HelperDumpService.getReservedRec(1);
    reservedRecSavings = accumulateSavings(reservedRecs);
  } catch (err) {
    reservedRecSavings = 0;
  }

  let unassignedDiskSavings;
  try {
    const disks = await fetchOrphanedDisks();
    unassignedDiskSavings = disks.reduce((total, disk) => total + disk.monthlyCost, 0.0);
  } catch (err) {
    unassignedDiskSavings = 0;
  }

  let unassignedAddressSavings;
  try {
    const addresses = await fetchOrphanedIPAddresses();
    unassignedAddressSavings = addresses.reduce(
      (total: any, address: { monthlyCost: any }) => total + address.monthlyCost,
      0.0,
    );
  } catch (err) {
    unassignedAddressSavings = 0;
  }

  const nodeSavings = Math.max(clusterSizingSavings, turndownSavings);

  const totalSavings =
    (pvSavings +
      unassignedDiskSavings +
      unassignedAddressSavings +
      abandonedWorkloadSavings +
      requestSizingSavings +
      nodeSavings +
      unutilizedDiskSavings +
      reservedRecSavings) *
    0.65;

  const response = {
    totalSavings,
    pvSavings,
    unassignedDiskSavings,
    unassignedAddressSavings,
    abandonedWorkloadSavings,
    requestSizingSavings,
    clusterSizingSavings,
    unutilizedDiskSavings,
    reservedRecSavings,
    turndownSavings,
  };
  return response;
}

/**
 * Accepts a savings object or array of savings objects and returns the accumulated savings result.
 */
function accumulateSavings(obj: { savings: number } | { savings: number }[]) {
  if (Array.isArray(obj)) {
    return obj.reduce((acc, o) => {
      if (typeof o.savings === 'number') {
        return acc + o.savings;
      }
      return acc;
    }, 0);
  }
  if (typeof obj.savings === 'number') {
    return obj.savings;
  }
  return 0;
}

export {
  Container,
  Controller,
  RequestSizingRec,
  fetchAbandonedWorkloads,
  fetchClusterSizingRecommendations,
  fetchNodeTurndownSavings,
  fetchOrphanedDisks,
  fetchOrphanedIPAddresses,
  fetchRequestSizing,
  fetchRequestSizingRecommendationsMaxHeadroom,
  fetchSpotConversion,
  fetchSpotSizingRecommendations,
  getDiscount,
  getEstimatedSavings,
  getPvSavings,
  getReservedRecSavings,
  getSavingsSummary,
  getUnassignedAddressSavings,
  getUnassignedDiskSavings,
  getUnutilizedLocalDiskSavings,
};
