import { ReactNode } from 'react';

import SearchIcon from '@material-ui/icons/Search';

import { Typography } from '@kubecost-frontend/holster';

interface EmptyAllocationDataProps {
  reason: ReactNode
}

const EmptyAllocationData = ({reason}: EmptyAllocationDataProps) => (
  <div className={'mb-8 h-48 w-full p-16 text-center'}>
    <SearchIcon style={{ height: '4rem', width: '4rem', marginBottom: '2rem' }} />
    <Typography variant={'h5'}>We couldn't find the data you're looking for!</Typography>
    <Typography variant={'p'}>{reason ?? 'Try adjusting your report parameters and try again!'}</Typography>
  </div>
);

export default EmptyAllocationData;
