import capitalize from 'lodash/capitalize';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import CodeIcon from '@material-ui/icons/Code';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '@kubecost-frontend/holster';

interface Alert {
  aggregation: string;
  filter: string;
  id: string;
  type: string;
  window: string;
}

interface ComponentProps {
  alerts: Alert[];
  deleteAlert?: (alert: Alert) => void;
  editAlert?: (alert: Alert) => void;
  onRowClick?: (row: Alert) => void;
  testAlert?: (alert: Alert) => Promise<void>;
  exportAlert?: (alert: Alert) => void;
}

/**
 *
 * @param type The Alert type to format for display.
 * @returns The words of the type, separated and capitalized.
 *
 * @remarks
 * Example: spendChange -> Spend Change
 */
const formatType = (type: string): string =>
  type
    .split(/(?=[A-Z])/)
    .map(capitalize)
    .join(' ');

const AlertsList = ({
  alerts,
  deleteAlert,
  editAlert,
  onRowClick,
  testAlert,
  exportAlert
}: ComponentProps): JSX.Element => (
  <Table data-test={'alerts-list'} style={{ width: '100%' }}>
    <TableHead>
      <TableRow>
        <TableHeadCell>Type</TableHeadCell>
        <TableHeadCell>Window</TableHeadCell>
        <TableHeadCell>Aggregation</TableHeadCell>
        <TableHeadCell>Filter</TableHeadCell>
        {editAlert && <TableHeadCell>Edit</TableHeadCell>}
        {testAlert && <TableHeadCell>Test</TableHeadCell>}
        {deleteAlert && <TableHeadCell>Delete</TableHeadCell>}
        {exportAlert && <TableCell>Export</TableCell>}
      </TableRow>
    </TableHead>
    <TableBody>
      {alerts.map((alert) => (
        <TableRow key={alert.id} onClick={() => onRowClick && onRowClick(alert)}>
          <TableCell>{formatType(alert.type)}</TableCell>
          <TableCell>{alert.window}</TableCell>
          <TableCell>{alert.aggregation}</TableCell>
          <TableCell>{alert.filter}</TableCell>
          {editAlert && (
            <TableCell onClick={() => editAlert(alert)}>
              <EditIcon style={{ cursor: 'pointer' }} />
            </TableCell>
          )}
          {testAlert && (
            <TableCell onClick={() => testAlert(alert)}>
              <SendIcon style={{ cursor: 'pointer' }} />
            </TableCell>
          )}
          {deleteAlert && (
            <TableCell data-test={'delete-alert'} onClick={() => deleteAlert(alert)}>
              <DeleteIcon style={{ cursor: 'pointer' }} />
            </TableCell>
          )}
          {exportAlert && (
            <TableCell data-test="export-alert" onClick={() => exportAlert(alert)}>
              <CodeIcon style={{cursor: 'pointer'}} />
            </TableCell>
          )}
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

AlertsList.defaultProps = {
  deleteAlert: undefined,
  editAlert: undefined,
  testAlert: undefined,
  onRowClick: undefined,
};

export { AlertsList };
