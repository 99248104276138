import { FC, memo, useState } from 'react';

import { Button, FormControlLabel, Select } from '@kubecost-frontend/holster';

import { AddFilter } from './AddFilter';
import { AccumulateOptions, FilterType, SelectOptions } from './types';

const emptyFilter: FilterType = { property: '', value: '' };

const allFilterPropertyOptions = [
  'Account',
  'Category',
  'Cluster',
  'Label/Tag',
  'Name',
  'Project',
  'Provider',
  'ProviderID',
  'Service',
  'Type',
];

interface EditControlProps {
  accumulate: boolean;
  accumulateOptions: AccumulateOptions[];
  filters: FilterType[];
  onClose: () => void;
  rate: any;
  setAccumulate: (item: string) => void;
  setFilters: (item: any) => void;
  setRate: (iotem: any) => void;
}

const EditControl: FC<EditControlProps> = ({
  accumulate,
  accumulateOptions,
  filters,
  onClose,
  rate,
  setAccumulate,
  setFilters,
  setRate,
}) => {
  // local state of report config, persisted on save
  const [localAccumulate, setLocalAccumulate] = useState(accumulate.toString());
  const [localFilters, setLocalFilters] = useState(
    filters.length > 0 ? [...filters] : [...filters, emptyFilter],
  );
  const [localRate, setLocalRate] = useState(rate);

  const usedFilterProps = localFilters.map((f) => f.property);
  const filterPropertyOptions = allFilterPropertyOptions.filter(
    (opt) => !usedFilterProps.includes(opt),
  );

  const handleAddFilterToList = () => {
    setLocalFilters((prev) => [...prev, { ...emptyFilter }]);
  };

  const handleRemoveFilterFromList = (index: number) => {
    setLocalFilters((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
  };

  const handleUpdateFilter = (index: number) => (f: FilterType) => {
    setLocalFilters((prev) => [...prev.slice(0, index), f, ...prev.slice(index + 1)]);
  };

  const filterChecker = () => {
    const newFilters = localFilters.filter((f) => f.property !== '' && f.value !== '');
    setFilters(newFilters);
  };

  const handleSave = () => {
    setAccumulate(localAccumulate);
    setRate(localRate);
    filterChecker();
    onClose();
  };

  const resolutionOptions: SelectOptions[] = accumulateOptions.map((opt) => ({
    label: opt.name,
    value: opt.value.toString(),
  }));

  const costMetricOpts: SelectOptions[] = [
    {
      label: 'Cumulative Cost',
      value: 'cumulative',
    },
    {
      label: 'Monthly Rate',
      value: 'monthly',
    },
    {
      label: 'Daily Rate',
      value: 'daily',
    },
    {
      label: 'Hourly Rate',
      value: 'hourly',
    },
  ];

  return (
    <>
      <div style={{ padding: '1em' }}>
        <FormControlLabel>Resolution</FormControlLabel>
        <Select options={resolutionOptions} setValue={setLocalAccumulate} value={localAccumulate} />
      </div>
      <div style={{ padding: '1em' }}>
        <FormControlLabel>Cost Metric</FormControlLabel>
        <Select options={costMetricOpts} setValue={setLocalRate} value={localRate} />
      </div>
      <div>
        {localFilters.map((item, idx) => (
          <AddFilter
            count={localFilters.length}
            handleAddFilter={handleAddFilterToList}
            handleDeleteFilter={() => handleRemoveFilterFromList(idx)}
            handleUpdateFilter={handleUpdateFilter(idx)}
            index={idx}
            property={item.property}
            propertyOptions={filterPropertyOptions}
            value={item.value}
          />
        ))}
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '.2fr .2fr',
          gap: '10px',
          padding: '1em 1em 0 1em',
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={handleSave} variant={'primary'}>
          Apply
        </Button>
        <Button onClick={onClose} variant={'default'}>
          Cancel
        </Button>
      </div>
    </>
  );
};
EditControl.displayName = 'EditControl';
export const EditoControlMemoized = memo(EditControl);
