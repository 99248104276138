import { createContext, useContext, useEffect, useState } from 'react';

import { uniq } from 'lodash';
import forEach from 'lodash/forEach';
import get from 'lodash/get';

import { useGetModelConfig } from '../hooks';
import FailedClusterDialog from '../pages/FailedClusterDialog/FailedClusterDialog';
import ConfigService, { ProductKey } from '../services/config';
import {
  ApiConfig,
  ClusterEnvResponse,
  ClusterInfoResponse,
  InstallInfoResponse,
  LocalConfig,
  ModelConfig,
} from '../services/model';
import { getCurrentContainerAddressModel } from '../services/util';

const thanos_query_path = '/thanosQuery?query=';
const prometheus_query_path = '/prometheusQuery?query=';
const prometheus_range_query_path = '/prometheusQueryRange?query=';
const config_ids = [
  'cpu',
  'pcpu',
  'ram',
  'pram',
  'grafana',
  'contact_label',
  'team_label',
  'product_label',
  'environment_label',
  'department_label',
  'container',
  'all_containers',
  'discount',
  'spot_label',
  'spot_label_tag',
];

interface ClusterContextType {
  activateContext: (contextURL: string) => void;
  activeContext: string;
  addContext: (contextURL: string) => void;
  apiConfig: ApiConfig;
  apiVersion: string;
  baseApiUrl: string;
  clusterConfig: ClusterInfoResponse;
  config: LocalConfig;
  envConfig: ClusterEnvResponse;
  grafanaUrl: string;
  licenseConfig: ProductKey;
  localClusterEndpoints: string[];
  modelConfig: ModelConfig;
  promQueryUrl: string;
  promRangeQueryUrl: string;
  remoteClusterEndpoints: string[];
  removeContext: (contextURL: string) => void;
  thanosQueryUrl: string;
}

const calculatedGrafanaAddress = (customUrl: string) =>
  customUrl || `${getCurrentContainerAddressModel().replace(/\/model$/, '')}/grafana`;

const hydrateConfigFromLocalStorage = (): LocalConfig => {
  // Grab local storage items and load them into config.
  const configItem: any = {};
  config_ids.forEach((configKey) => {
    configItem[configKey] = localStorage.getItem(configKey);
  });
  return configItem;
};

const ClusterContext = createContext<ClusterContextType>({});

const ClusterContextProvider = ({ children }: any) => {
  const [clusterConfig, setClusterConfig] = useState<ClusterInfoResponse>();
  const [installInfo, setInstallInfo] = useState<InstallInfoResponse>();
  const [envConfig, setEnvConfig] = useState<ClusterEnvResponse>();
  const [apiConfig, setApiConfig] = useState<ApiConfig>();
  const [licenseConfig, setLicenseConfig] = useState<ProductKey>();

  const [config, setConfig] = useState<LocalConfig>(hydrateConfigFromLocalStorage());
  const [remoteClusterEndpoints, setRemoteClusterEndpoints] = useState<string[]>([]);
  const [localClusterEndpoints, setLocalClusterEndpoints] = useState<string[]>([]);
  const [activeContext, setActiveContext] = useState(
    localStorage.getItem('container') || 'http://localhost:9090',
  ); /// TODO: Check that this is accurate

  // configLoadFailure, when true, will render the FailedClusterDialog to
  // attempt to capture the proper URL for the page
  const [configLoadFailure, setConfigLoadError] = useState<boolean>(false);

  const baseApiUrl = getCurrentContainerAddressModel();

  const { isFetching, isLoading, modelConfig } = useGetModelConfig();

  useEffect(() => {
    const URL = `${baseApiUrl}/clusterInfo`;
    fetch(URL)
      .then((resp) => resp.json())
      .then((response) => setClusterConfig(response.data))
      .catch((e) => {
        console.error('Failed to load context.', e);
        setConfigLoadError(true);
      });
  }, []);

  useEffect(() => {
    const URL = `${baseApiUrl}/config/env`;
    fetch(URL)
      .then((resp) => resp.json())
      .then(setEnvConfig);
  }, []);

  useEffect(() => {
    const URL = `${baseApiUrl}/installInfo`;
    fetch(URL)
      .then((resp) => resp.json())
      .then(setInstallInfo);
  }, []);

  useEffect(() => {
    const URL = `${baseApiUrl}/getApiConfig`;
    fetch(URL)
      .then((resp) => resp.json())
      .then(setApiConfig);
  }, []);

  useEffect(() => {
    ConfigService.fetchProductKey().then(setLicenseConfig);
  }, []);

  useEffect(() => {
    // Get All Remote Cluster URLs
    const fetchRemoteUrls = async () => {
      const connectedURL = `${baseApiUrl}/clusters?req=${Date.now()}`;
      const connectedClusters = await fetch(connectedURL)
        .then((data) => data.json())
        .catch(() => {
          console.warn(`Warning: unable to load backend clusters from ${connectedURL}`);
          return [];
        });

      const addresses: string[] = [];
      forEach(get(connectedClusters, 'data', []), (cluster) => {
        addresses.push(cluster.address);
      });
      setRemoteClusterEndpoints(addresses);
    };
    fetchRemoteUrls();
  }, []);

  useEffect(() => {
    if (!config) return;
    setLocalClusterEndpoints(uniq(config.all_containers.split(',')));
  }, [config]);

  if (configLoadFailure) {
    return <FailedClusterDialog />;
  }

  if (!clusterConfig || !envConfig || !config || !apiConfig || !modelConfig || !licenseConfig) {
    return <></>;
  }

  const addContext = (contextURL: string) => {
    const newLocalClusterEndpoints = [...localClusterEndpoints, contextURL];

    setLocalClusterEndpoints(newLocalClusterEndpoints);
    setConfig({
      ...config,
      all_containers: newLocalClusterEndpoints.join(','),
    });
    localStorage.setItem('all_containers', newLocalClusterEndpoints.join(','));
  };

  const removeContext = (contextURL: string) => {
    const allUrls = config.all_containers.split(',');
    if (!allUrls.includes(contextURL)) {
      console.warn('Context does not exist in list.');
    }
    const modifiedUrls = allUrls.filter((s: string) => s !== contextURL);
    setLocalClusterEndpoints(modifiedUrls);
    setConfig({
      ...config,
      all_containers: modifiedUrls.join(','),
    });
    localStorage.setItem('all_containers', modifiedUrls.join(','));
  };

  const activateContext = (contextURL: string) => {
    setActiveContext(contextURL);
    setConfig({
      ...config,
      container: contextURL,
    });
    localStorage.setItem('container', contextURL);
  };

  const value: ClusterContextType = {
    removeContext,
    addContext,
    localClusterEndpoints,
    remoteClusterEndpoints,
    clusterConfig,
    apiVersion: (installInfo && installInfo.version) || '<V1.94.0',
    envConfig,
    modelConfig,
    apiConfig,
    baseApiUrl,
    config,
    thanosQueryUrl: `${baseApiUrl}${thanos_query_path}`,
    promQueryUrl: `${baseApiUrl}${prometheus_query_path}`,
    promRangeQueryUrl: `${baseApiUrl}${prometheus_range_query_path}`,
    grafanaUrl: calculatedGrafanaAddress(apiConfig.grafanaURL),
    licenseConfig,
    activateContext,
    activeContext,
  };

  return <ClusterContext.Provider value={value}>{children}</ClusterContext.Provider>;
};

const useClusters = () => useContext(ClusterContext);

export { ClusterContext, ClusterContextProvider, useClusters };
