import { Card, Tooltip, Typography } from '@kubecost-frontend/holster';

import { SquareInfoIcon } from '../../assets/images';
import { useClusters } from '../../contexts/ClusterConfig';
import { useClustersEnablements_DEPRECATED } from '../../hooks/useClustersEnablement';
import { toCurrency } from '../../services/format';

interface SavingsBannerComponentProps {
  totalSavings: number;
}

const SavingsBanner = ({ totalSavings }: SavingsBannerComponentProps) => {
  const {
    modelConfig: { currencyCode: currency },
  } = useClusters();
  const enablements = useClustersEnablements_DEPRECATED();
  const isMulticlusterEnablementViolation = enablements[0].isViolation;

  return (
    <div className={'grid auto-rows-auto grid-cols-8 grid-rows-1 gap-4 pb-4'}>
      <Card
        className={isMulticlusterEnablementViolation ? 'col-[span_5_/_span_5]' : 'col-span-full'}
        style={{ backgroundColor: 'rgb(234, 249, 240, 0.8)', padding: '1em' }}
      >
        <div>
          <Typography className={'flex items-center font-bold'} variant={'h5'}>
            Estimated monthly savings available
            <Tooltip
              className={'w-52 font-normal'}
              content={
                'Estimated savings are probability adjusted and include both Kubernetes and external cloud insights.'
              }
            >
              <SquareInfoIcon className={'ml-3 text-kc-gray-200'} />
            </Tooltip>
          </Typography>
        </div>
        <Typography style={{ color: '#31C46C' }} variant={'h2'}>
          {toCurrency(totalSavings, currency)}
        </Typography>
      </Card>
      {isMulticlusterEnablementViolation && (
        <Card className={'col-span-3 !bg-kc-off-white p-8 !shadow-none'}>
          <h3 className={'pb-2 text-lg font-bold'}>You may be able to save 40%</h3>
          <Typography className={'pb-5 text-kc-gray-200'} variant={'p-small'}>
            Upgrade and discover more ways to save.
          </Typography>
          <a
            className={'holster-button kc-button-primary'}
            href={'https://www.kubecost.com/pricing/?upgrade=true'}
            target={'_blank'}
          >
            Upgrade plan
          </a>
        </Card>
      )}
    </div>
  );
};

export { SavingsBanner };
