import { AlertTypes } from '../../services/alerts';

import { windowOptions } from './types';

// This is very specific to the format thats given from slack.
// Gonna come back to this once I have time to learn about custom
// masking for inputs
const maskWebhook = (input: string, mask: string = 'X') =>
  `${input}`.slice(0, 33) + `${input}`.slice(33, input.length - 1).replace(/./g, mask);

const validate = (
  aggregationOptions: any[],
  aggregation: string,
  type: any,
  threshold: any,
  alertWindow?: string,
  efficiencyThreshold?: any,
  spendThreshold?: any,
  baselineWindow?: any,
  relativeChangeThreshold?: any,
): void => {
  // validate window
  const validWindows = windowOptions?.map((w) => w.value);
  if (!validWindows?.includes(alertWindow as string)) {
    throw new Error(
      `Expected window to be one of: ${validWindows?.join(', ')}. Instead got ${alertWindow}`,
    );
  }

  // validate aggregation
  const validAggs = aggregationOptions.map((agg) => agg.value);
  if (!validAggs.includes(aggregation)) {
    throw new Error(
      `Expected aggregation to be one of: ${validAggs.join(', ')}. Instead got ${aggregation}`,
    );
  }

  // type-specific validation
  if (type === 'budget' || type === 'assetBudget') {
    if (Number.isNaN(parseFloat(threshold))) {
      throw new Error('No cost threshold set');
    }
  } else if (type === 'efficiency') {
    if (Number.isNaN(parseFloat(spendThreshold))) {
      throw new Error('No spend threshold set');
    }
    const et = parseFloat(efficiencyThreshold);
    if (Number.isNaN(et)) {
      throw new Error('No efficiency threshold set');
    }
    if (et < 0.0 || et > 100.0) {
      throw new Error('Efficiency threshold is outside the permitted range (0 to 100)');
    }
  } else if (type === 'spendChange') {
    if (!validWindows.includes(baselineWindow)) {
      throw new Error(
        `Expected baseline window to be one of: ${validWindows.join(
          ', ',
        )}. Instead got ${baselineWindow}`,
      );
    }
    const rt = parseFloat(relativeChangeThreshold);
    if (Number.isNaN(rt)) {
      throw new Error('No relative change threshold set');
    }
    if (rt < -100.0) {
      throw new Error('Relative change threshold cannot be less than -100%');
    }
  } else if (type !== 'recurringUpdate' && type !== 'cloudReport') {
    throw new Error(
      `Expected alert type to be one of: budget, assetBudget, efficiency, recurringUpdate, cloudReport, spendChange. Instead got ${type}`,
    );
  }
};

const getAlertTypeText = (type: AlertTypes) => {
  if (type === 'budget') {
    return `
      Allocation Budget alerts are triggered when the total cost of allocations goes over a set budget limit.
    `;
  }
  if (type === 'efficiency') {
    return `
      Allocation Efficiency alerts are triggered when the average efficiency of allocations falls below a set threshold.
    `;
  }
  if (type === 'recurringUpdate') {
    return `
      Allocation Recurring alerts have no trigger. They simply issue a report on the state of the allocations every <date range> days.
    `;
  }
  if (type === 'spendChange') {
    return `
      Allocation Spend change alerts are triggered when a significant jump or drop in spend relative to the average occurs. 
    `;
  }
  if (type === 'assetBudget') {
    return `
      Asset Budget alerts are triggered when the total cost of assets goes over a set budget limit.
    `;
  }
  if (type === 'cloudReport') {
    return `
      Asset Recurring alerts have no trigger. They simply issue a report on the state of the assets every <date range> days.
    `;
  }
  return '';
};

export { getAlertTypeText, maskWebhook, validate };
