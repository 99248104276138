import { useQuery } from 'react-query';

import { useClusters } from '../../../contexts/ClusterConfig';
import { model } from '../../../services/model';

interface OrphanedResourcesResponse {
  description: Map<string, string>;
  diskName: string;
  diskSizeInGB: number;
  ipAddress: string;
  monthlyCost: number;
  region: string;
  resourceKind: string;
  url: string;
}

async function fetchOrphanedResources(): Promise<OrphanedResourcesResponse[]> {
  const response = await model.get<{ data: OrphanedResourcesResponse[] }>(
    '/savings/orphanedResources',
  );

  return response.data;
}

const useOrphanedResources = () => {
  const { clusterConfig } = useClusters();
  const queryKey = [clusterConfig.id, 'orphanedResources'];
  return useQuery(queryKey, fetchOrphanedResources);
};

export { OrphanedResourcesResponse, useOrphanedResources };
