import { FC } from 'react';

import { Switch } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import { useBetaUI } from '../../hooks/useBetaUI';

const BetaUISetting: FC = () => {
  const { isBetaUI, setIsBetaUI } = useBetaUI();

  return (
    <section>
      <header className={'flex items-center justify-between pb-4'}>
        <Typography className={'flex items-center'} variant={'body1'}>
          {!isBetaUI ? 'Switch to New UI' : 'Switch to Classic UI'}
          <Switch checked={isBetaUI} color={'primary'} onChange={() => setIsBetaUI(!isBetaUI)} />
        </Typography>
      </header>

      {isBetaUI ? (
        <Typography variant={'body1'}>
          The new UI for Kubecost is now the default option. If you prefer our classic look, you can
          switch back using the toggle switch above. If you have any concerns or feedback about the
          new UI, don't hesitate to reach out to us on {''}
          <a
            className={'text-kc-link'}
            href={'https://github.com/kubecost/cost-analyzer-helm-chart/issues/new/choose'}
            target={'_blank'}
          >
            Slack
          </a>{' '}
          or{' '}
          <a
            className={'text-kc-link'}
            href={
              'https://join.slack.com/t/kubecost/shared_invite/zt-1dz4a0bb4-InvSsHr9SQsT_D5PBle2rw'
            }
            target={'_blank'}
          >
            GitHub.
          </a>
        </Typography>
      ) : (
        <Typography variant={'body1'}>
          Switch the toggle above to see the brand new Kubecost UI in action!
        </Typography>
      )}

      <Divider style={{ marginTop: '4em', marginBottom: '4em' }} variant={'middle'} />
    </section>
  );
};

BetaUISetting.displayName = 'BetaUISetting';
export { BetaUISetting };
