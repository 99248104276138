import { SyntheticEvent, useState } from 'react';

import { IconButton } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import FilterNoneIcon from '@material-ui/icons/FilterNone';

interface CopyToClipBoardProps {
  copyText: string;
}

export const CopyToClipBoard = ({ copyText }: CopyToClipBoardProps) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleCopy = (e: SyntheticEvent) => {
    e.stopPropagation();
    navigator.clipboard.writeText(copyText);
    setIsCopied(true);
    setInterval(() => setIsCopied(false), 2000);
  };

  if (isCopied) {
    return <CheckIcon style={{ height: 15 }} color={'primary'} />;
  }

  return (
    <IconButton onClick={handleCopy} size={'small'}>
      <FilterNoneIcon style={{ height: 15 }} />
    </IconButton>
  );
};

export const CopyToClipBoardHoverElement = ({ copyText }: CopyToClipBoardProps) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className='flex items-center gap-0.5'
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
    >
      <div>{copyText}</div>
      {isHover && <CopyToClipBoard copyText={copyText} />}
    </div>
  );
};
