import { useEffect, useMemo, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import { Outlet, useLocation } from 'react-router-dom';

import Alert from '@material-ui/lab/Alert';

import { useBetaUI } from '../hooks/useBetaUI';
import { usePageViewRecorder } from '../hooks/usePageViewRecorder';
import { model } from '../services/model';

import { Footer } from './Footer';
import { Nav } from './Nav';
import { Nav as NavNew } from './NavNew';
import { SearchBarContext } from './SearchBarContext';
import { NavigationBar } from './SpotlightSearch';

const Page = () => {
  const { isBetaUI } = useBetaUI();
  const { pathname } = useLocation();
  usePageViewRecorder();

  const [thanosEnabled, setThanosEnabled] = useState(false);
  const [thanosReady, setThanosReady] = useState(false);
  const [singleClusterReady, setSingleClusterReady] = useState(false);
  const [bannerDataReady, setBannerDataReady] = useState(false);
  const [startupBannerDismissed, setStartupBannerDismissed] = useState(false);

  useEffect(() => {
    setStartupBannerDismissed(localStorage.getItem('startup_banner_dismissed') === 'true');
    Promise.all([
      model.clusterInfo().then((info) => {
        setThanosEnabled(info.thanosEnabled === 'true');
      }),
      model.getAllocationSummary('4h', 'namespace', { accumulate: false }).then(({ data }) => {
        const { sets } = data;
        const validDataPoints = sets.filter((s) => !isEmpty(s.allocations));
        let scr = false;
        if (validDataPoints.length > 1) {
          scr = true;
        } else {
          const longestRun = Math.max.apply(
            null,
            Object.values(sets[3].allocations)
              .filter((alloc) => !['__idle__', '__unmounted__'].includes(alloc.name))
              .map(model.getSummaryMinutes),
          );
          scr = longestRun > 15;
        }
        setThanosReady(validDataPoints.length >= 1);
        setSingleClusterReady(scr);
      }),
    ]).then(() => setBannerDataReady(true));
  }, []);

  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);

  return (
    <SearchBarContext.Provider
      value={useMemo(
        () => ({
          isVisible: isSearchBarVisible,
          setIsVisible: setIsSearchBarVisible,
        }),
        [isSearchBarVisible],
      )}
    >
      <div className={isBetaUI ? 'flex' : 'kubecost-app-body'}>
        <NavigationBar />
        {isBetaUI ? <NavNew active={pathname} /> : <Nav active={pathname} />}
        <main className={'relative h-screen grow overflow-x-auto px-16 pt-10'}>
          {bannerDataReady && thanosEnabled && !thanosReady && !startupBannerDismissed && (
            <Alert
              onClose={() => {
                localStorage.setItem('startup_banner_dismissed', 'true');
                setStartupBannerDismissed(true);
              }}
              severity={'info'}
            >
              Kubecost is collecting data. It takes Thanos about 4 hours to write its first block.
            </Alert>
          )}
          {bannerDataReady && !thanosEnabled && !singleClusterReady && !startupBannerDismissed && (
            <Alert
              onClose={() => {
                localStorage.setItem('startup_banner_dismissed', 'true');
                setStartupBannerDismissed(true);
              }}
              severity={'info'}
            >
              Kubecost is collecting data. Data should be ready for viewing within 15 minutes.
            </Alert>
          )}
          <Outlet />
          <Footer />
        </main>
      </div>
    </SearchBarContext.Provider>
  );
};

export { Page, SearchBarContext };
