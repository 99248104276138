import { SyntheticEvent, memo, useEffect, useState } from 'react';

import get from 'lodash/get';
import round from 'lodash/round';
import { useLocation } from 'react-router-dom';

import grey from '@material-ui/core/colors/grey';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import {
  Button,
  Tooltip as HolsterTooltip,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from '@kubecost-frontend/holster';

import { ArrowIcon } from '../../../assets/images/arrow-icon';
import { AllocationAggExplanations } from '../../../constants';
import { useClusters } from '../../../contexts/ClusterConfig';
import { analytics as Analytics } from '../../../services/analytics';
import { toCurrency } from '../../../services/format';
import { TableResults, TimeSeriesGraph, TopResultsGraph, TotalsRow } from '../../../services/model';
import { MemoizedAllocationChart } from '../AllocationChart';

import { AllocationReportRow } from './Row';

// descendingComparator provides a comparator for stableSort, which compares
// the given orderBy column of two rows, a and b. Due to the design of getCost,
// whereby the complete value of a "cost" column is actually the cost plus the
// associated adjustment (e.g. cpuCost = cpuCost + cpuCostAdjustment) the
// getCost function must be called here for "cost" columns. Kind of a hacky
// solution, but it's the simplest way to fix sorting. See the complete
// discussion: https://github.com/kubecost/cost-analyzer-frontend/issues/301
function descendingComparator(a: TableResults, b: TableResults, orderBy: string) {
  if (get(b, orderBy, 0) < get(a, orderBy, 0)) {
    return -1;
  }
  if (get(b, orderBy, 0) > get(a, orderBy, 0)) {
    return 1;
  }
  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === 'desc'
    ? (a: TableResults, b: TableResults) => descendingComparator(a, b, orderBy)
    : (a: TableResults, b: TableResults) => -descendingComparator(a, b, orderBy);
}

function stableSort(
  array: Array<TableResults>,
  comparator: (a: TableResults, b: TableResults) => number,
) {
  const stabilizedThis: [TableResults, number][] = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a: [TableResults, number], b: [TableResults, number]) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const efficiencyTooltip =
  'Efficiency is defined as (usage / request) for CPU and RAM. If resources are used, but no resources are requested, then efficiency is considered infinite.';

const labelAliases = [
  'team',
  'department',
  'environment',
  'product',
  'daemonset',
  'job',
  'deployment',
];

const DetailsPageLink = ({
  aggregateBy,
  resourceName,
}: {
  aggregateBy: string[];
  resourceName: string;
}) => {
  const location = useLocation();

  // in cases where resourceName has the format clusterName/clusterID, we want only clusterID passed to details.html
  let baseResourceName = resourceName;
  if (
    aggregateBy.length === 1 &&
    aggregateBy[0] === 'cluster' &&
    baseResourceName.split('/').length > 1
  ) {
    // eslint-disable-next-line prefer-destructuring
    baseResourceName = baseResourceName.split('/')[1];
  }
  let baseAggList = aggregateBy.toString();
  const urlParams = new URLSearchParams(location.search);
  const currentFilters = JSON.parse(atob(urlParams.get('filters') || '') || '{}');

  const handleDrilldown = (e: SyntheticEvent) => {
    e.stopPropagation();
    Analytics.record('inspected_item', {
      resourceName,
      resourceType: aggregateBy,
    });
    if (currentFilters.length > 0) {
      currentFilters.forEach((item: { property: string; value: string }) => {
        // to handle cases where we want to venture to type=namespace&name=kubecost via row click,
        // but we also have a filter of namespace=kubecost
        if (!baseAggList.includes(item.property)) {
          baseAggList += `,${item.property}`;
          baseResourceName += `/${item.value}`;
        }
      });
    }
    window.open(`./details?name=${baseResourceName}&type=${baseAggList}`, '_blank');
  };

  return (
    <Tooltip title={'Click to inspect details'}>
      <a className={'cursor-pointer text-kc-link'} onClick={handleDrilldown}>
        {resourceName} <OpenInNewIcon style={{ fontSize: 12, color: 'gray' }} />
      </a>
    </Tooltip>
  );
};

interface ComponentProps {
  aggregateBy: string[];
  canDrillDown: (row: { externalCost: number; name: string; totalCost: number }) => boolean;
  chartDisplay: 'category' | 'efficiency' | 'percentage' | 'series' | 'treemap';
  count: number;
  drillDownCompatible: string[];
  drillDownExemptRows: string[];
  drillDownForRow: (row: TableResults) => () => void;
  graphData: TimeSeriesGraph | TopResultsGraph;
  rate: string;
  sharingIdle: boolean;
  tableData: TableResults[];
  totalsRow: TotalsRow;
}

const getTooltipForAggregation = (aggregateBy: string[]) => {
  if (aggregateBy.length !== 1) {
    return '';
  }
  const agg = aggregateBy[0];
  return AllocationAggExplanations[agg] || '';
};

const AllocationReport = memo(
  ({
    aggregateBy,
    canDrillDown,
    chartDisplay,
    count,
    drillDownCompatible,
    drillDownExemptRows,
    drillDownForRow,
    graphData,
    rate,
    sharingIdle,
    tableData,
    totalsRow,
  }: ComponentProps) => {
    const [order, setOrder] = useState<'asc' | 'desc'>('desc');
    const [orderBy, setOrderBy] = useState('totalCost');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [numRowsMenuOpen, setNumRowsMenuOpen] = useState(false);
    const { modelConfig } = useClusters();

    useEffect(() => {
      setPage(0);
    }, [count]);

    if (tableData.length === 0) {
      return (
        <Typography style={{ padding: 24 }} variant={'p'}>
          No results
        </Typography>
      );
    }

    const inspectableAggs = ['namespace', 'controller', 'service', ...labelAliases];

    const headCells = [
      {
        id: 'name',
        numeric: false,
        label: 'Name',
        width: 'auto',
        tooltip: getTooltipForAggregation(aggregateBy),
      },
      {
        id: 'cpuCost',
        numeric: true,
        label: 'CPU',
        width: 90,
        tooltip: 'The total cost of node CPU attributed to each workload over the chosen window.',
      },
      {
        id: 'gpuCost',
        numeric: true,
        label: 'GPU',
        width: 90,
        tooltip: 'The total cost of node GPU attributed to each workload over the chosen window.',
      },
      {
        id: 'ramCost',
        numeric: true,
        label: 'RAM',
        width: 90,
        tooltip: 'The total cost of node RAM attributed to each workload over the chosen window.',
      },
      {
        id: 'pvCost',
        numeric: true,
        label: 'PV',
        width: 90,
        tooltip:
          'The total cost of Persistent Volume storage attributed to each workload over the chosen window.',
      },
      {
        id: 'networkCost',
        numeric: true,
        label: 'Network',
        width: 90,
        tooltip:
          'The total cost of network egress/ingress attributed attributed to each workload over the chosen window.',
      },
      {
        id: 'loadBalancerCost',
        numeric: true,
        label: 'LB',
        width: 90,
        tooltip:
          'The total cost of load balancers attributed to each workload over the chosen window.',
      },
      {
        id: 'sharedCost',
        numeric: true,
        label: 'Shared',
        width: 90,
        tooltip:
          'The total shared overhead cost attributed to each workload over the chosen window.',
      },
      {
        id: 'efficiency',
        numeric: true,
        label: 'Efficiency',
        width: 90,
        tooltip: (
          <p>
            The average efficiency of each workload over the chosen window. Workload efficiency is
            the cost-weighted ratio of RAM and CPU usage, to configured RAM and CPU request.{' '}
            <a
              className={'text-kc-link'}
              href={
                'https://guide.kubecost.com/hc/en-us/articles/4407601807383-Kubernetes-Cost-Allocation#cost-efficiency-table-example'
              }
              target={'_blank'}
            >
              Learn More
            </a>
          </p>
        ),
      },
      {
        id: 'totalCost',
        numeric: true,
        label: 'Total cost',
        width: 90,
        tooltip: 'The total cost attributed to each workload over the chosen window.',
        flipTooltip: true,
      },
    ];

    function isInspectable(row: { externalCost: number; name: string; totalCost: number }) {
      if (aggregateBy.length !== 1) {
        return false;
      }
      const agg = aggregateBy[0];
      if (agg.startsWith('label:')) {
        let labelName = aggregateBy[0].split(':').pop() || '';
        labelName = labelName.replace(/\.|\//g, '_');
        return row.name.startsWith(`${labelName}=`);
      }
      return canDrillDown(row) && inspectableAggs.includes(agg);
    }

    const lastPage = Math.floor(count / rowsPerPage);

    const orderedRows = stableSort(tableData, getComparator(order, orderBy));
    const pageRows = orderedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const p = Math.min(page, lastPage);
    const startIndex = p * rowsPerPage + 1;
    const stopIndex = (p + 1) * rowsPerPage;

    const dataToAllocationRow = (row: TableResults) => {
      let { name } = row;
      if (name === '__idle__' && sharingIdle) {
        name = 'Undistributable idle';
      }
      if (name === '__unmounted__') {
        name = 'Unmounted PVs';
      }

      const canDrillDownResult = canDrillDown(row);

      return (
        <AllocationReportRow
          canDrillDown={canDrillDownResult}
          costSuffix={{ hourly: '/hr', monthly: '/mo', daily: '/day' }[rate] || ''}
          cpuCost={row.cpuCost ?? 0}
          cpuRequest={row.averageCpuUtilization ?? 0}
          efficiency={row.efficiency ?? 0}
          gpuCost={row.gpuCost ?? 0}
          handleOnClick={() => {
            if (canDrillDownResult) {
              drillDownForRow(row)();
            }
          }}
          isIdle={row.name?.includes('__idle__')}
          isUnmounted={row.name?.includes('__unmounted__')}
          key={row.name}
          loadBalancerCost={row.loadBalancerCost ?? 0}
          name={
            isInspectable(row) ? (
              <DetailsPageLink aggregateBy={aggregateBy} resourceName={name} />
            ) : (
              name
            )
          }
          networkCost={row.networkCost ?? 0}
          pvCost={row.pvCost ?? 0}
          ramCost={row.ramCost ?? 0}
          ramRequest={row.averageRamUtilization ?? 0}
          sharedCost={row.sharedCost ?? 0}
          totalCost={row.totalCost ?? 0}
          trendData={row.trendData}
        />
      );
    };

    return (
      <div>
        <MemoizedAllocationChart
          aggregateBy={aggregateBy}
          allocationRows={pageRows}
          chartDisplay={chartDisplay}
          drillDownCompatible={drillDownCompatible}
          drillDownExemptRows={drillDownExemptRows}
          drillDownFactory={drillDownForRow}
          graphData={graphData}
          height={300}
        />
        <hr
          style={{
            margin: 0,
            border: 'none',
            borderTop: '1px solid rgba(0,0,0,0.1)',
          }}
        />
        <div className={'overflow-x-auto'}>
          <Table className={'w-full'}>
            <TableHead>
              <TableRow>
                {headCells.map((cell) => (
                  <TableHeadCell
                    align={cell.numeric ? 'right' : 'left'}
                    key={cell.id}
                    style={{
                      width: cell.width,
                      paddingRight: cell.id === 'totalCost' ? '2em' : '',
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === cell.id}
                      direction={orderBy === cell.id ? order : 'asc'}
                      onClick={() => {
                        const isDesc = orderBy === cell.id && order === 'desc';
                        setOrder(isDesc ? 'asc' : 'desc');
                        setOrderBy(cell.id);
                      }}
                    >
                      {cell.label}
                      {cell.tooltip && (
                        <HolsterTooltip
                          content={cell.tooltip}
                          flipDirection={cell.flipTooltip && cell.flipTooltip}
                          style={{ width: 300, color: '#607971' }}
                        >
                          <InfoIcon
                            style={{
                              fontSize: 12,
                              color: grey[500],
                              margin: '0 4px',
                            }}
                          />
                        </HolsterTooltip>
                      )}
                    </TableSortLabel>
                  </TableHeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align={'left'} style={{ fontWeight: 500 }}>
                  {totalsRow.name}
                </TableCell>
                <TableCell align={'right'} style={{ fontWeight: 500 }}>
                  {toCurrency(totalsRow.cpuCost || 0, modelConfig.currencyCode)}
                </TableCell>
                <TableCell align={'right'} style={{ fontWeight: 500 }}>
                  {toCurrency(totalsRow.gpuCost || 0, modelConfig.currencyCode)}
                </TableCell>
                <TableCell align={'right'} style={{ fontWeight: 500 }}>
                  {toCurrency(totalsRow.ramCost || 0, modelConfig.currencyCode)}
                </TableCell>
                <TableCell align={'right'} style={{ fontWeight: 500 }}>
                  {toCurrency(totalsRow.pvCost || 0, modelConfig.currencyCode)}
                </TableCell>
                <TableCell align={'right'} style={{ fontWeight: 500 }}>
                  {toCurrency(totalsRow.networkCost || 0, modelConfig.currencyCode)}
                </TableCell>
                <TableCell align={'right'} style={{ fontWeight: 500 }}>
                  {toCurrency(totalsRow.loadBalancerCost || 0, modelConfig.currencyCode)}
                </TableCell>
                <TableCell align={'right'} style={{ fontWeight: 500 }}>
                  {toCurrency(totalsRow.sharedCost || 0, modelConfig.currencyCode)}
                </TableCell>
                {totalsRow.efficiency === 1.0 &&
                !totalsRow.averageCpuUtilization &&
                !totalsRow.averageRamUtilization ? (
                  <Tooltip title={efficiencyTooltip}>
                    <TableCell align={'right'} style={{ fontWeight: 500 }}>
                      Inf%
                    </TableCell>
                  </Tooltip>
                ) : (
                  <TableCell align={'right'} style={{ fontWeight: 500 }}>
                    {round((totalsRow.efficiency || 0) * 100, 1)}%
                  </TableCell>
                )}
                <TableCell align={'right'} style={{ fontWeight: 500 }}>
                  {toCurrency(totalsRow.totalCost || 0, modelConfig.currencyCode)}
                </TableCell>
              </TableRow>
              {pageRows.map(dataToAllocationRow)}
            </TableBody>
          </Table>
        </div>
        <div className={'mt-3 flex h-6 w-full justify-between'}>
          <div className={'flex items-center'}>
            <Typography className={'mr-1'} variant={'p'}>
              Rows per page
            </Typography>
            <Button className={'p-1'} onClick={() => setNumRowsMenuOpen(true)} variant={'default'}>
              <ArrowIcon className={'inline'} direction={'LEFT'} />
              {rowsPerPage}
            </Button>
            {numRowsMenuOpen && (
              <Menu
                items={['10', '25', '50']}
                onClose={() => setNumRowsMenuOpen(false)}
                selectItem={(v) => setRowsPerPage(parseInt(v.text, 10))}
              />
            )}
          </div>
          <div className={'flex items-center justify-end'}>
            <Typography style={{ marginRight: 19 }} variant={'p'}>
              {startIndex}-{stopIndex} of {count}
            </Typography>
            <Button
              disabled={!page}
              onClick={() => setPage(page - 1)}
              style={{ marginRight: 7 }}
              variant={'default'}
            >
              Previous
            </Button>
            <Button
              disabled={(page + 1) * rowsPerPage >= count}
              onClick={() => setPage(page + 1)}
              style={{ marginRight: 15 }}
              variant={'default'}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    );
  },
);

AllocationReport.displayName = 'AllocationReport';
export { AllocationReport };
