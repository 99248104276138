import { Dispatch, FC, SetStateAction } from 'react';

import { Button } from '@kubecost-frontend/holster';

import { SaveIcon } from '../../assets/images/save-icon';

interface SaveControlProps {
  saved: boolean;
  setSaveDialogOpen: Dispatch<SetStateAction<boolean>>;
  setUnsaveDialogOpen: Dispatch<SetStateAction<boolean>>;
}

export const SaveControl: FC<SaveControlProps> = ({
  saved,
  setSaveDialogOpen,
  setUnsaveDialogOpen,
}) => (
  <Button
    className={'h-33px mr-3 flex items-center'}
    onClick={() => (saved ? setUnsaveDialogOpen(true) : setSaveDialogOpen(true))}
    variant={'default'}
  >
    <SaveIcon className={'mr-2'} />
    {saved ? 'Unsave' : 'Save'}
  </Button>
);
