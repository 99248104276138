import { FC, memo } from 'react';

import Typography from '@material-ui/core/Typography';

import { TableResults, TimeSeriesGraph, TopResultsGraph } from '../../../services/model';

import CategoryChart from './CategoryChart';
import LineChart from './LineChart';
import RangeChart from './RangeChart';
import SummaryChart from './SummaryChart';
import TreemapChart from './TreeMapChart';

interface AllocationChartProps {
  aggregateBy: string[];
  allocationRows: TableResults[];
  chartDisplay: 'category' | 'efficiency' | 'percentage' | 'series' | 'treemap';
  drillDownCompatible: string[];
  drillDownExemptRows: string[];
  drillDownFactory: (row: TableResults) => () => void;
  graphData: TimeSeriesGraph | TopResultsGraph;
  height: number;
}

const AllocationChart: FC<AllocationChartProps> = ({
  aggregateBy,
  allocationRows,
  chartDisplay,
  drillDownCompatible,
  drillDownExemptRows,
  drillDownFactory,
  graphData,
  height,
}) => {
  if (!graphData) {
    return <Typography variant={'body2'}>No data</Typography>;
  }

  if (chartDisplay === 'category') {
    if (!graphData.items) {
      return <Typography variant={'body2'}>No data</Typography>;
    }
    return <CategoryChart data={graphData as TopResultsGraph} height={height} />;
  }

  if (chartDisplay === 'treemap') {
    if (!graphData.items) {
      return <Typography variant={'body2'}>No data</Typography>;
    }
    return (
      <TreemapChart
        allocationRows={allocationRows}
        canDrillDown={aggregateBy.length === 1 && drillDownCompatible.includes(aggregateBy[0])}
        data={graphData as TopResultsGraph}
        drillDownExemptRows={drillDownExemptRows}
        drillDownFactory={drillDownFactory}
        height={2 * height} // For clarity, double heigh from barcharts
      />
    );
  }

  if (chartDisplay === 'percentage') {
    if (!graphData.items) {
      return <Typography variant={'body2'}>No data</Typography>;
    }
    return <SummaryChart data={graphData as TopResultsGraph} height={height} />;
  }

  if (chartDisplay === 'efficiency') {
    if (!graphData.graphItems) {
      return <Typography variant={'body2'}>No data</Typography>;
    }
    return <LineChart data={graphData as TimeSeriesGraph} height={height} />;
  }

  if (chartDisplay === 'series') {
    if (!graphData.graphItems) {
      return <Typography variant={'body2'}>No data</Typography>;
    }
    return <RangeChart data={graphData as TimeSeriesGraph} height={height} />;
  }

  return <RangeChart data={graphData as TimeSeriesGraph} height={height} />;
};
const MemoizedAllocationChart = memo(AllocationChart);
MemoizedAllocationChart.displayName = 'AllocationChart';
export { MemoizedAllocationChart };
