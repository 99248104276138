import React from 'react';

import { IconProps } from './types';

const CheckCircleOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(({ ...args }, ref) => (
  <svg
    height={'1em'}
    preserveAspectRatio={'xMidYMid meet'}
    ref={ref}
    viewBox={'0 0 24 24'}
    width={'1em'}
    xmlns={'http://www.w3.org/2000/svg'}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...args}
  >
    <g fill={'none'} stroke={'currentColor'} strokeWidth={'2'}>
      <path d={'m8 12.5l3 3l5-6'} strokeLinecap={'round'} strokeLinejoin={'round'} />
      <circle cx={'12'} cy={'12'} r={'10'} />
    </g>
  </svg>
));

CheckCircleOutlineIcon.displayName = 'CheckCircleOutlineIcon';
export { CheckCircleOutlineIcon };
