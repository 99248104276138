/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-handler-names */

import { HTMLInputTypeAttribute, ReactNode } from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';

import { Input } from '@kubecost-frontend/holster';

import { WEBHOOKS } from '../types';

type SimpleTextInputProps = {
  formControlClassName?: string;
  formGroupClassName?: string;
  handleOnChange: (e: React.FormEvent<HTMLInputElement>) => void;
  helperText?: ReactNode;
  inputClassName?: string;
  inputName?: string;
  inputTestId?: string;
  inputType?: HTMLInputTypeAttribute;
  label?: ReactNode;
  placeholder?: string;
  value: string | number;
};

const SimpleTextInput = ({
  formControlClassName,
  formGroupClassName,
  handleOnChange,
  helperText,
  inputClassName,
  inputName,
  inputTestId,
  inputType,
  label,
  placeholder = '',
  value,
}: SimpleTextInputProps) => (
  <FormGroup className={formGroupClassName}>
    <FormControl className={formControlClassName}>
      <Input
        className={inputClassName}
        data-testid={inputTestId}
        helperText={helperText}
        label={label}
        name={inputName}
        onChange={handleOnChange}
        placeholder={placeholder}
        type={inputType}
        value={value}
      />
    </FormControl>
  </FormGroup>
);

const EmailSubjectInput = ({
  helperText = 'Custom email subject line.',
  label = 'Subject',
  placeholder = 'Kubecost Alert',
  ...rest
}: SimpleTextInputProps) => (
  <SimpleTextInput helperText={helperText} label={label} placeholder={placeholder} {...rest} />
);

const LinkbackURLInput = ({
  helperText = 'Alert messages will refer recipients back to this URL',
  label = 'Linkback URL',
  placeholder = `${window.location.origin}`,
  ...rest
}: SimpleTextInputProps) => (
  <SimpleTextInput helperText={helperText} label={label} placeholder={placeholder} {...rest} />
);

const SlackInput = ({
  helperText = 'The global Slack webhook',
  label = 'Slack webhook',
  placeholder = `e.g. ${WEBHOOKS.SLACK.PLACEHOLDER}`,
  ...rest
}: SimpleTextInputProps) => (
  <SimpleTextInput helperText={helperText} label={label} placeholder={placeholder} {...rest} />
);

const MSTeamsInput = ({
  helperText = 'The global Microsoft Teams webhook',
  label = 'Microsoft Teams webhook',
  placeholder = `e.g. ${WEBHOOKS.MS_TEAMS.PLACEHOLDER}`,
  ...rest
}: SimpleTextInputProps) => (
  <SimpleTextInput helperText={helperText} label={label} placeholder={placeholder} {...rest} />
);

const MaximumCostThresholdInput = ({
  helperText = 'Total costs rising beyond this threshold will trigger the alert',
  label = 'Cost Threshold',
  ...rest
}: SimpleTextInputProps) => <SimpleTextInput helperText={helperText} label={label} {...rest} />;

const MinimumCostThresholdInput = ({
  helperText = 'The minimum spend threshold for alerting. Items whose total costs are below this number will not trigger alerts, even if they fall below the efficiency threshold.',
  label = 'Cost Threshold',
  ...rest
}: SimpleTextInputProps) => <SimpleTextInput helperText={helperText} label={label} {...rest} />;

const EfficiencyThresholdInput = ({
  helperText = 'Total efficiency of queried items falling below this threshold triggers the alert. Ranges from 0.0 to 100.0',
  label = 'Efficiency Threshold',
  ...rest
}: SimpleTextInputProps) => <SimpleTextInput helperText={helperText} label={label} {...rest} />;

const RelativeChangeThresholdInput = ({
  helperText = 'Percentage of change from the baseline (positive or negative) which will trigger the alert. Ranges from -100% (costs dropped to 0) upward.',
  label = 'Relative Change Threshold',
  ...rest
}: SimpleTextInputProps) => <SimpleTextInput helperText={helperText} label={label} {...rest} />;

const HealthChangeThresholdInput = ({
  helperText = 'Health scores range from 0 to 100',
  label = 'Health change threshold',
  ...rest
}: SimpleTextInputProps) => <SimpleTextInput helperText={helperText} label={label} {...rest} />;

const TimeWindowInput = ({
  helperText = 'Must be a relative window, such as 7d or 10m',
  label = 'Window',
  ...rest
}: SimpleTextInputProps) => <SimpleTextInput helperText={helperText} label={label} {...rest} />;

export {
  EfficiencyThresholdInput,
  EmailSubjectInput,
  HealthChangeThresholdInput,
  LinkbackURLInput,
  MSTeamsInput,
  MaximumCostThresholdInput,
  MinimumCostThresholdInput,
  RelativeChangeThresholdInput,
  SimpleTextInput,
  SlackInput,
  TimeWindowInput,
};
