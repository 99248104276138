// react
import { FC, useState } from 'react';

import { Header } from '../../components/Header2New';

import { SavingsBanner } from './SavingsBanner';
import { SavingsCard } from './SavingsCard';
import { savings } from './SavingsData';

export const Savings: FC = () => {
  const [totalSavings, setTotalSavings] = useState<{ [key: string]: number }>({});

  const totalSavingCalc = Object.keys(totalSavings).reduce(
    (total, key) => totalSavings[key] * 0.65 + total,
    0,
  );

  const savingsItems = savings;

  return (
    <>
      <Header refreshCallback={() => {}} title={'Savings'} />

      <SavingsBanner totalSavings={totalSavingCalc} />

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '1em',
        }}
      >
        {Object.values(savingsItems).map((savingsItem) => (
          <SavingsCard
            key={savingsItem.title}
            loadingComplete={(value) => {
              setTotalSavings((prev) => {
                const newVal = { ...prev };
                newVal[savingsItem.title] = value;
                return newVal;
              });
            }}
            savingsItem={savingsItem}
          />
        ))}
      </div>
    </>
  );
};
