import round from 'lodash/round';

import { IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { TableCell, TableRow } from '@kubecost-frontend/holster';

import { toCurrency } from '../../services/format';

import { TableRowData } from './types';

interface ARDataRowProps {
  currency: string;
  item: TableRowData;
  setActiveRow: (row: any) => void;
}

export const ARDataRow = ({
  currency,
  item,
  item: {
    cpuCost,
    efficiency,
    externalBreakdown,
    externalCost,
    gpuCost,
    loadBalancerCost,
    name,
    networkCost,
    pvCost,
    ramCost,
    sharedCost,
    totalCost,
  },
  setActiveRow,
}: ARDataRowProps) => (
  <TableRow>
    <TableCell align={'left'} style={{ fontWeight: 500 }}>
      <div className={'flex'} style={{ gap: '1em', alignItems: 'center' }}>
        <div>{name}</div>
        {externalBreakdown && externalBreakdown.length > 0 && (
          <IconButton
            className={'ml-4'}
            onClick={() => {
              setActiveRow(item);
            }}
            size={'small'}
          >
            <SearchIcon />
          </IconButton>
        )}
      </div>
    </TableCell>
    <TableCell align={'right'} style={{ fontWeight: 500 }}>
      {toCurrency(cpuCost || 0, currency)}
    </TableCell>
    <TableCell align={'right'} style={{ fontWeight: 500 }}>
      {toCurrency(gpuCost || 0, currency)}
    </TableCell>
    <TableCell align={'right'} style={{ fontWeight: 500 }}>
      {toCurrency(ramCost || 0, currency)}
    </TableCell>
    <TableCell align={'right'} style={{ fontWeight: 500 }}>
      {toCurrency(pvCost || 0, currency)}
    </TableCell>
    <TableCell align={'right'} style={{ fontWeight: 500 }}>
      {toCurrency(networkCost || 0, currency)}
    </TableCell>
    <TableCell align={'right'} style={{ fontWeight: 500 }}>
      {toCurrency(loadBalancerCost || 0, currency)}
    </TableCell>
    <TableCell align={'right'} style={{ fontWeight: 500 }}>
      {toCurrency(sharedCost || 0, currency)}
    </TableCell>
    <TableCell align={'right'} style={{ fontWeight: 500 }}>
      {toCurrency(externalCost || 0, currency)}
    </TableCell>
    <TableCell align={'right'} style={{ fontWeight: 500 }}>
      {round((efficiency || 0) * 100, 1)}%
    </TableCell>
    <TableCell align={'right'} style={{ fontWeight: 500, paddingRight: '2em' }}>
      {toCurrency(
        cpuCost + gpuCost + ramCost + pvCost + externalCost + loadBalancerCost + networkCost || 0,
        currency,
      )}
    </TableCell>
  </TableRow>
);
