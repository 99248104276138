import { FC, useState } from 'react';

import { Button } from '@kubecost-frontend/holster';

import { EllipsisIcon } from '../../assets/images/ellipsis-icon';
import { SaveIcon } from '../../assets/images/save-icon';
import { SettingsIcon } from '../../assets/images/settings-icon';
import { AggregateByControl } from '../../components/AggregateByControlNew';
import { QueryWindowSelector } from '../../components/QueryWindowSelector/QueryWindowSelector';
import {
  APIQueryWindow,
  apiQueryWindowParams,
} from '../../components/QueryWindowSelector/useQueryWindowParamState';
import { Asset, AssetFilter, AssetReport } from '../../types/asset';

import { aggregationOptions } from './asset';
import { CustomizeReportMenu } from './CustomizeReportMenu';

interface CustomizeReportHeaderProps {
  accumulate: boolean;
  aggregateBy: string[];
  cumulativeDate: Asset[];
  filters: AssetFilter[];
  report?: AssetReport;
  setAggregateBy: any;
  setEditReportDialog: (open: boolean) => void;
  setOpenReportDialog: (open: boolean) => void;
  setOpenSaveReportDialog: (open: boolean) => void;
  sharedMonthlyCost: number;
  title: string;
}

export const CustomizeReportHeader: FC<CustomizeReportHeaderProps> = ({
  accumulate,
  aggregateBy,
  cumulativeDate,
  filters,
  report,
  setAggregateBy,
  setEditReportDialog,
  setOpenReportDialog,
  setOpenSaveReportDialog,
  sharedMonthlyCost,
  title,
}) => {
  const [openMenu, setOpenMenu] = useState(false);

  const handleOnClickMoreMenu = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <div className="flex-[1_1_auto] flex flex-wrap justify-end items-stretch gap-y-3">
      <div className="mr-3">
        <QueryWindowSelector<APIQueryWindow> options={apiQueryWindowParams} />
      </div>
      <AggregateByControl
        useAggregateLabel
        aggregateBy={aggregateBy}
        aggregateByOptions={aggregationOptions}
        id={'aggregate-by-control'}
        setAggregateBy={setAggregateBy}
        unaggregatedEnabled
      />
      <Button
        className={'mr-3 flex items-center'}
        onClick={() => setEditReportDialog(true)}
        variant={'default'}
      >
        <SettingsIcon aria-hidden={'true'} className={'mr-2'} focusable={'false'} />
        Edit
      </Button>
      <Button
        className={'mr-3 flex items-center'}
        onClick={() => setOpenSaveReportDialog(true)}
        variant={'default'}
      >
        <SaveIcon aria-hidden={'true'} className={'mr-2'} focusable={'false'} />
        {report ? 'Unsave' : 'Save'}
      </Button>
      <div className={'relative'}>
        <Button
          aria-label={'More Options'}
          className={'h-full'}
          onClick={handleOnClickMoreMenu}
          variant={'default'}
        >
          <EllipsisIcon aria-hidden={'true'} focusable={'false'} />
        </Button>
        {openMenu ? (
          <CustomizeReportMenu
            accumulate={accumulate}
            aggregateBy={aggregateBy}
            cumulativeData={cumulativeDate}
            filters={filters}
            onClose={() => setOpenMenu(false)}
            setOpenReportDialog={setOpenReportDialog}
            sharedMonthlyCost={sharedMonthlyCost}
            title={title}
          />
        ) : null}
      </div>
    </div>
  );
};
