import { useEffect, useMemo, useRef, useState } from 'react';

import trim from 'lodash/trim';
import { useSearchParams } from 'react-router-dom';

import { Drawer, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import {
  Alert as HolsterAlert,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from '@kubecost-frontend/holster';

import AggregateByControl from '../../components/AggregateByControlAllocation';
import { Header } from '../../components/Header2New';
import { QueryWindowSelector } from '../../components/QueryWindowSelector/QueryWindowSelector';
import {
  APIQueryWindow,
  apiQueryWindowParams,
} from '../../components/QueryWindowSelector/useQueryWindowParamState';
import { useClusters } from '../../contexts/ClusterConfig';
import { monitorWidthResize } from '../../hooks/monitorWidthResize';
import { toCurrency } from '../../services/format';
import {
  ExternalAllocationResponse,
  ExternalAllocationResponseItem,
  model,
} from '../../services/model';
import { deleteAdvancedReport, saveAdvancedReport } from '../../services/reports';
import { AdvancedReport as Report } from '../../types/advancedReport';
import { EditControl } from '../AllocationNew/EditControl';
import useAllocationConfig from '../AllocationNew/hooks/useAllocationConfig';
import { SaveControl } from '../AllocationNew/SaveControl';
import { SaveReportDialog } from '../AllocationNew/SaveReportDialog';
import { UnsaveReportDialog } from '../AllocationNew/UnsaveReportDialog';
import { labelAssetsMap } from '../Inspect/inspectHelpers';

import { ARChart } from './ARChart';
import CloudBreakdownSelector from './CloudBreakdownSelector';
import { EditReportDialog } from './EditReportDialog';
import { NewARDataTable } from './NewARDataTable';

const NewAdvancedReporting = () => {
  const { apiConfig, modelConfig } = useClusters();
  const defaultConfig = useAllocationConfig();

  const [searchParams, setSearchParams] = useSearchParams();
  const [tableData, setTableData] = useState<ExternalAllocationResponse | null>(null);
  const [activeRow, setActiveRow] = useState<ExternalAllocationResponseItem | null>(null); // responsible for drawer
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [unsaveDialogOpen, setUnsaveDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    message?: string;
    severity?: 'success' | 'info' | 'warning' | 'error';
  }>({});

  const [editDialogAnchorEl, setEditDialogAnchorEl] = useState<EventTarget & HTMLButtonElement>();

  // ref and state for hook setting chart width
  const arChartRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState(0);

  const reportDefaults = useMemo(
    // Order matters! We use `JSON.stringify` on this object in `isActiveReportSaved`.
    (): Report => ({
      aggregateBy: 'namespace',
      cloudBreakdown: 'service',
      cloudJoin: `label:${apiConfig.namespace_external_label || 'kubernetes_namespace'}`,
      filters: [],
      sharedLabels: [],
      sharedNamespaces: [],
      sharedOverhead: 0,
      title: `Breakdown by service join on label:${
        apiConfig.namespace_external_label || 'kubernetes_namespace'
      }`,
      window: '7d',
    }),
    [apiConfig],
  );

  const activeReport = useMemo((): Report => {
    // id
    const id = searchParams.get('id') || undefined;

    // cloudBreakdown
    const cloudBreakdown = searchParams.get('breakdown') || reportDefaults.cloudBreakdown;

    // cloudJoinLabel
    const cloudJoin = searchParams.get('cloudJoinLabel') || reportDefaults.cloudJoin;

    // filters
    const urlFilter = searchParams.get('filters') || '';
    let filters: Array<{ property: string; value: string }> = [...reportDefaults.filters];
    try {
      filters = JSON.parse(global.window.atob(urlFilter)) || [...reportDefaults.filters];
    } catch (err) {
      filters = [...reportDefaults.filters];
    }

    // shared namespaces
    const sns = searchParams.get('sharedNamespaces');
    let sharedNamespaces: string[] = [...reportDefaults.sharedNamespaces];
    if (typeof sns === 'string') {
      sharedNamespaces = sns
        .split(',')
        .map((s) => trim(s))
        .filter((s) => !!s);
    }

    // shared overhead
    const soh = searchParams.get('sharedOverhead');
    let { sharedOverhead } = reportDefaults;
    if (soh) {
      sharedOverhead = parseFloat(soh);
    }

    // shared labels
    const sls = searchParams.get('sharedLabels');
    let sharedLabels: string[] = [...reportDefaults.sharedLabels];
    if (typeof sls === 'string') {
      sharedLabels = sls
        .split(',')
        .map((s) => trim(s))
        .filter((s) => !!s);
    }

    const window = searchParams.get('window') || reportDefaults.window;

    const aggregateBy = searchParams.get('agg') || reportDefaults.aggregateBy;

    const title =
      searchParams.get('title') || `Breakdown by ${cloudBreakdown} join on ${cloudJoin}`;

    // Order matters! We use `JSON.stringify` on this object in `isActiveReportSaved`.
    return {
      aggregateBy,
      cloudBreakdown,
      cloudJoin,
      filters,
      id,
      sharedLabels,
      sharedNamespaces,
      sharedOverhead,
      title,
      window,
    };
  }, [reportDefaults, searchParams]);

  const savedReport = useRef<Report>(activeReport);

  const isActiveReportSaved = JSON.stringify(activeReport) === JSON.stringify(savedReport.current);

  // automatically set chartWidth on window resize
  useEffect(() => {
    monitorWidthResize(arChartRef, setChartWidth);
  }, []);

  // when active report changes, fetch data
  useEffect(() => {
    if (activeReport) {
      (async () => {
        const { aggregateBy, cloudBreakdown, cloudJoin, filters, window } = activeReport;
        const resp = await model.getAllocationExternal(
          window,
          aggregateBy,
          {
            filters,
            accumulate: true,
            cloudBreakdown,
            cloudJoin,
          },
          {},
        );
        if (resp.code === 200) {
          let { items } = resp.data;
          const { totals } = resp.data;
          const undef = resp.data.items.find((item) => item.name === '__undefined__');
          if (undef) {
            items = items.filter((item) => item.name !== '__undefined__');
            totals.totalCost -= undef.totalCost;
            totals.externalCost -= undef.externalCost;
          }
          setTableData({ items, totals });
        }
      })();
    }
  }, [activeReport]);

  const saveReport = async (report: any) => {
    try {
      const newReport = report;
      const { id: reportId } = await saveAdvancedReport(newReport);

      newReport.id = reportId;

      // Unfortunately the order of our keys matter because we use `JSON.stringify` in `isActiveReportSaved`.
      savedReport.current = Object.fromEntries(Object.entries(newReport).sort());

      setSnackbar({
        message: 'Report saved',
        severity: 'success',
      });

      setSearchParams((prevParams: URLSearchParams) => ({
        ...[...prevParams.entries()].reduce(
          (previousValue, [key, value]) => ({ ...previousValue, [key]: value }),
          {},
        ),
        ...newReport,
      }));
    } catch (err) {
      setSnackbar({
        message: 'Failed to save report',
        severity: 'error',
      });
    }
  };

  const unsaveReport = async () => {
    try {
      await deleteAdvancedReport(activeReport.id);

      setSearchParams((prevParams) => {
        prevParams.delete('id');

        return prevParams;
      });

      setSnackbar({
        message: 'Report unsaved',
        severity: 'success',
      });
    } catch (err) {
      setSnackbar({
        message: 'Failed to unsave report',
        severity: 'error',
      });
    }
  };

  return (
    <div>
      <SaveReportDialog
        onClose={() => setSaveDialogOpen(false)}
        open={saveDialogOpen}
        report={activeReport}
        save={saveReport}
        title={'New Advanced Report'}
      />
      <UnsaveReportDialog
        onClose={() => setUnsaveDialogOpen(false)}
        open={unsaveDialogOpen}
        report={activeReport}
        title={'New Advanced Report'}
        unsave={unsaveReport}
      />
      <Drawer anchor={'right'} onClose={() => setActiveRow(null)} open={!!activeRow}>
        <div style={{ padding: '2em' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '.5em',
              marginBottom: '2em',
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <Typography variant={'h5'}>
                {activeRow && toCurrency(activeRow.externalCost, 'USD')}
              </Typography>
              <Typography variant={'h5'}>OOC</Typography>
            </div>
            <div style={{ textAlign: 'center' }}>
              <Typography variant={'h5'}>
                {activeRow &&
                  toCurrency(
                    activeRow.cpuCost +
                      activeRow.gpuCost +
                      activeRow.ramCost +
                      activeRow.pvCost +
                      activeRow.loadBalancerCost +
                      activeRow.networkCost,
                    'USD',
                  )}
              </Typography>
              <Typography variant={'h5'}>K8s</Typography>
            </div>
          </div>
          <Typography variant={'h5'}>Cloud Breakdown</Typography>
          <div>
            <Table className={'w-full'}>
              <TableHead>
                <TableRow>
                  <TableHeadCell>Name</TableHeadCell>
                  <TableHeadCell>Cost</TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activeRow &&
                  activeRow.externalBreakdown &&
                  activeRow.externalBreakdown.map((item: any) => (
                    <TableRow>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{toCurrency(item.cost, 'USD')}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </Drawer>
      <Header
        helpHref={'https://docs.kubecost.com/cost-allocation'}
        helpTooltip={'Product Documentation'}
        title={'Advanced Reporting'}
      />
      <div className={'mb-4'}>
        <HolsterAlert
          content={'Advanced Reports are a beta feature.'}
          title={'Beta Feature'}
          variant={'warning'}
        />
      </div>
      <Typography variant={'p-large'}>{activeReport.title}</Typography>
      <div className={'mt-6 flex justify-between'}>
        <div className={'flex justify-start'}>
          <div className={'mr-3'}>
            <QueryWindowSelector<APIQueryWindow> options={apiQueryWindowParams} />
          </div>
          <AggregateByControl
            aggregateBy={[activeReport.aggregateBy]}
            setAggregateBy={(aggBy) =>
              setSearchParams((prevParams) => {
                const agg = aggBy.toString();
                const apiConfigLabel = apiConfig[`${agg}_external_label`];
                const defaultLabel = labelAssetsMap[agg];

                prevParams.set('agg', agg);
                prevParams.set('cloudJoinLabel', `label:${apiConfigLabel || defaultLabel}`);

                return prevParams;
              })
            }
            disallowMultiAgg
          />
          <CloudBreakdownSelector
            aggregateBy={activeReport.cloudBreakdown}
            allocationAgg={activeReport.aggregateBy}
            joinLabel={activeReport.cloudJoin}
            setAggregateBy={(breakdownBy) =>
              setSearchParams((prevParams) => {
                prevParams.set('breakdown', breakdownBy);

                return prevParams;
              })
            }
            setJoinLabel={(joinLabel: string) => {
              setSearchParams((prevParams) => {
                prevParams.set('cloudJoinLabel', joinLabel);

                return prevParams;
              });
            }}
          />
        </div>
        <div className={'flex items-stretch'}>
          <SaveControl
            saved={!!activeReport.id && isActiveReportSaved}
            setSaveDialogOpen={setSaveDialogOpen}
            setUnsaveDialogOpen={setUnsaveDialogOpen}
          />
          <EditControl onClick={(e) => setEditDialogAnchorEl(e.currentTarget)} />
          <EditReportDialog
            anchorEl={editDialogAnchorEl}
            customSharedLabels={activeReport.sharedLabels}
            customSharedNamespaces={activeReport.sharedNamespaces}
            customSharedOverhead={activeReport.sharedOverhead}
            defaultSharedLabels={defaultConfig.shareLabelNames || []}
            defaultSharedNamespaces={defaultConfig.shareNamespaces || []}
            defaultSharedOverhead={defaultConfig.sharedOverhead}
            filters={activeReport.filters}
            onClose={() => setEditDialogAnchorEl(undefined)}
            setCustomSharedLabels={(lbls: string) =>
              setSearchParams((prevParams) => {
                if (lbls !== null) {
                  prevParams.set('sharedLabels', lbls);
                } else {
                  prevParams.delete('sharedLabels');
                }

                return prevParams;
              })
            }
            setCustomSharedNamespaces={(ns: string) =>
              setSearchParams((prevParams) => {
                if (ns !== null) {
                  prevParams.set('sharedNamespaces', ns);
                } else {
                  prevParams.delete('sharedNamespaces');
                }

                return prevParams;
              })
            }
            setCustomSharedOverhead={(o: string | null) =>
              setSearchParams((prevParams) => {
                if (o !== null) {
                  prevParams.set('sharedOverhead', o);
                } else {
                  prevParams.delete('sharedOverhead');
                }

                return prevParams;
              })
            }
            setFilters={(fs: { property: string; value: string }[]) =>
              setSearchParams((prevParams) => {
                prevParams.set('filters', global.window.btoa(JSON.stringify(fs)));

                return prevParams;
              })
            }
            shareTenancyCosts={defaultConfig.shareTenancyCosts}
          />
        </div>
      </div>
      <div className={'my-8 w-full'} ref={arChartRef}>
        {tableData && (
          <ARChart data={tableData.items ?? []} dataKey={'totalCost'} width={chartWidth} />
        )}
      </div>
      <div className={'overflow-x-auto'}>
        {tableData && (
          <NewARDataTable
            currency={modelConfig.currencyCode}
            setActiveRow={setActiveRow}
            tableData={tableData}
          />
        )}
      </div>
      <Snackbar autoHideDuration={4000} onClose={() => setSnackbar({})} open={!!snackbar.message}>
        <Alert onClose={() => setSnackbar({})} severity={snackbar.severity} variant={'filled'}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

NewAdvancedReporting.displayName = 'NewAdvancedReporting';

export { NewAdvancedReporting };
