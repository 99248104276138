import { FC, useEffect, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import HelpIcon from '@material-ui/icons/Help';
import MuiAlert from '@material-ui/lab/Alert';

import {
  Button,
  Alert as HolsterAlert,
  Input,
  Toggle,
  Tooltip,
  Typography,
} from '@kubecost-frontend/holster';

import { AlertsList } from '../../components/AlertsListNew';
import { Header } from '../../components/Header2New';
import { If } from '../../components/If';
import { FetchStates } from '../../constants';
import {
  Alert,
  AlertService,
  AlertTypes,
  BudgetAlert,
  DiagnosticAlert,
  RBACUnauthorizedError,
} from '../../services/alerts';
import { analytics } from '../../services/analytics';
import Logger from '../../services/logger';

import {
  EmailRecipientsInput,
  EmailSubjectInput,
  LinkbackURLInput,
  MSTeamsInput,
  SlackInput,
} from './AlertInputFields';
import { HealthAlert } from './ClusterHealthAlerts/HealthAlert';
import { CreateAlertModal } from './CreateAlertModal';
import { DiagnosticAlertModal as CreateDiagnosticAlertModal } from './CreateDiagnosticAlertModal';
import { DeleteAlertModal } from './DeleteAlertModal';
import { ExportAlertModal } from './ExportAlertModal';
import { TestAlertButton } from './TestAlertButton';
import { WEBHOOKS } from './types';

const AlertsNew: FC = () => {
  // alerts
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [tableAlerts, setTableAlerts] = useState<Alert[]>([]);
  const [displayedAlerts, setDisplayedAlerts] = useState<Alert[]>([]);
  const [alertListFilter, setAlertListFilter] = useState('');
  const [diagnosticAlert, setDiagnosticAlert] = useState<Alert | null>(null);

  // alert being created / edited
  const [newAlert, setNewAlert] = useState<Alert | null>(null);
  const [newDiagnosticAlert, setNewDiagnosticAlert] = useState<DiagnosticAlert | null>(null);

  // alert selected for removal
  const [deletingAlert, setDeletingAlert] = useState<Alert | null>(null);

  // alert for selected export
  const [exportAlert, setExportAlert] = useState<Alert | null>(null);

  // global settings
  const [currentEmail, setCurrentEmail] = useState('');
  const [globalEmails, setGlobalEmails] = useState<string[]>([]);
  const [emailSubject, setEmailSubject] = useState('');
  const [globalSlack, setGlobalSlack] = useState(WEBHOOKS.SLACK.DEFAULT);
  const [globalSlackDirty, setGlobalSlackDirty] = useState(false);
  const [globalMSTeams, setGlobalMSTeams] = useState(WEBHOOKS.MS_TEAMS.DEFAULT);
  const [globalMSTeamsDirty, setGlobalMSTeamsDirty] = useState(false);
  const [globalLinkback, setGlobalLinkback] = useState('');

  // data initialization
  const [fetchState, setFetchState] = useState(FetchStates.INIT);

  // user messages
  const [badMessage, setBadMessage] = useState('');
  const [goodMessage, setGoodMessage] = useState('');

  const removeAlert = async (a: Alert) => {
    try {
      if (await AlertService.deleteAlert(a)) setAlerts(alerts.filter((aa) => aa.id !== a.id));
    } catch (err) {
      if (err instanceof RBACUnauthorizedError) {
        setBadMessage('You are not authorized to do that. Please contact your administrator.');
      } else {
        setBadMessage('Failed to remove alert. See console.');
      }
    }
  };

  const testAlert = async (a: Alert) => {
    try {
      const response = await AlertService.testAlert(a);
      const json = await response.json();

      setGoodMessage('Test Alert Sent!');
    } catch (err) {
      setBadMessage(
        'Uh oh, looks like the server had an error building your results. Check your logs.',
      );
    }
  };

  const fetchData = async () => {
    setFetchState(FetchStates.LOADING);
    try {
      const [alertResponse, emailResponse, slackResponse, msTeamsResponse, linkbackResponse] =
        await Promise.all([
          AlertService.getAlerts(),
          AlertService.getGlobalEmailConfig(),
          AlertService.getGlobalSlackWebhook(),
          AlertService.getGlobalMSTeamsWebhook(),
          AlertService.getGlobalLinkback(),
        ]);

      setAlerts(alertResponse);
      analytics.setProfileValue('alerts_count', alertResponse.length);
      setGlobalEmails(emailResponse.recipients || []);
      setEmailSubject(emailResponse.subject || '');
      setGlobalSlack(slackResponse ? WEBHOOKS.SLACK.PLACEHOLDER : WEBHOOKS.SLACK.DEFAULT);
      setGlobalMSTeams(msTeamsResponse ? WEBHOOKS.MS_TEAMS.PLACEHOLDER : WEBHOOKS.MS_TEAMS.DEFAULT);
      setGlobalLinkback(linkbackResponse);
      setFetchState(FetchStates.DONE);
    } catch (err) {
      setFetchState(FetchStates.ERROR);
      Logger.error(err);
    }
  };

  const upsertAlert = async (a: Alert) => {
    try {
      const response = await AlertService.upsertAlert(a);
      if (!a.id) {
        setAlerts([...alerts, response]);
      } else {
        setAlerts([...alerts.filter((alert) => alert.id !== a.id), response]);
      }
    } catch (err) {
      setBadMessage('Failed to create alert. See console.');
      Logger.error(err);
    }
  };

  // initial request to fetch all alerts
  useEffect(() => {
    fetchData();
  }, []);

  // differentiate diagnostic and health alerts from other types.
  // other types belong in the table. Diagnostic and Health get their own controls.
  useEffect(() => {
    const da = alerts.find((a) => a.type === AlertTypes.Diagnostic);
    const ta = alerts.filter((a) => ![AlertTypes.Diagnostic, AlertTypes.Health].includes(a.type));
    setDiagnosticAlert(da || null);
    setTableAlerts(ta);
  }, [alerts]);

  // determine alerts to display in the table
  // this is tableAlerts, less any alerts failing the search filter
  useEffect(() => {
    const das = tableAlerts
      .filter(
        (a) =>
          a.type.toLowerCase().includes(alertListFilter.toLowerCase()) ||
          a.window.toLowerCase().includes(alertListFilter.toLowerCase()) ||
          a.aggregation.toLowerCase().includes(alertListFilter.toLowerCase()) ||
          a.filter.toLowerCase().includes(alertListFilter.toLowerCase()),
      )
      .sort((a, b) => {
        if (a.type > b.type) {
          return 1;
        }
        if (a.type < b.type) {
          return -1;
        }
        if (a.aggregation > b.aggregation) {
          return 1;
        }
        if (b.aggregation > a.aggregation) {
          return -1;
        }
        if (a.filter > b.filter) {
          return 1;
        }
        return -1;
      });
    setDisplayedAlerts(das);
  }, [tableAlerts, alertListFilter]);

  return (
    <div>
      {/* heading */}
      <Header
        helpHref={'https://docs.kubecost.com/alerts.html'}
        helpTooltip={'Alerts Documentation'}
        refreshCallback={() => document.location.reload()}
        title={'Alerts'}
      />

      <HolsterAlert
        content={
          'Kubecost alerts allow teams to receive updates on real-time Kubernetes spend. They are configurable via the Kubecost UI or via Helm values.'
        }
        link={{
          href: 'https://guide.kubecost.com/hc/en-us/articles/4407601796759-Alerts-Documentation',
          target: '_blank',
          text: 'Learn More',
        }}
        title={"It's important to know"}
        variant={'info'}
      />

      {/* app-wide settings */}
      <If condition={fetchState === FetchStates.LOADING}>
        <CircularProgress className={'m-auto'} />
      </If>

      <If condition={fetchState === FetchStates.ERROR}>
        <Typography className={'text-center'} variant={'h5'}>
          Something went wrong. Check console for details.
        </Typography>
      </If>

      <If condition={fetchState === FetchStates.DONE}>
        <div
          /* TODO: design system offWhiteDarker */
          className={'my-9 border border-solid border-kc-white-200 p-6'}
        >
          {/* health and diagnostics alerts */}
          <Typography variant={'h5'}>Cluster and Kubecost Health Alerts</Typography>

          <Typography variant={'p'}>
            Activating these alerts monitors the health of Kubecost, as well as that of the cluster
            running Kubecost.
          </Typography>
          <HealthAlert />
          <div className={'mt-2'}>
            <div className={'w-64'}>
              <Toggle
                checked={!!diagnosticAlert}
                label={'Monitor Kubecost Health'}
                onChange={async () => {
                  if (!diagnosticAlert) {
                    setNewDiagnosticAlert(
                      new DiagnosticAlert({
                        type: AlertTypes.Diagnostic,
                        window: '5m',
                      }),
                    );
                  } else {
                    removeAlert(diagnosticAlert);
                    setDiagnosticAlert(null);
                  }
                }}
              />
              {diagnosticAlert && (
                <Button
                  className={'mr-2'}
                  onClick={() => setNewDiagnosticAlert(diagnosticAlert)}
                  variant={'primary'}
                >
                  Edit
                </Button>
              )}
              {diagnosticAlert && <TestAlertButton alert={diagnosticAlert} />}
            </div>
          </div>
          <Typography className={'mt-7'} variant={'h5'}>
            Global Recipients
            <Tooltip
              className={'w-96'}
              content={
                'Alerts that do not define a Slack recipient will be sent to the global Slack recipient. This is also true for Microsoft Teams and Email, as well as when other recipients are defined.'
              }
            >
              <HelpIcon
                className={'cursor-pointer text-kc-gray-200'}
                style={{
                  fontSize: 18,
                }}
              />
            </Tooltip>
          </Typography>
          <SlackInput
            formGroupClassName={'mt-2'}
            handleOnChange={(e) => {
              setGlobalSlackDirty(true);
              setGlobalSlack(e.currentTarget.value);
            }}
            value={globalSlack}
          />
          <MSTeamsInput
            formGroupClassName={'mt-2'}
            handleOnChange={(e) => {
              setGlobalMSTeamsDirty(true);
              setGlobalMSTeams(e.currentTarget.value);
            }}
            value={globalMSTeams}
          />
          <EmailSubjectInput
            formGroupClassName={'mt-2'}
            handleOnChange={(e) => {
              setEmailSubject(e.currentTarget.value);
            }}
            value={emailSubject}
          />
          <EmailRecipientsInput
            addItem={(email) => {
              setGlobalEmails((emails) => [...emails, email]);
              setCurrentEmail('');
            }}
            handleOnChange={(e) => setCurrentEmail(e.currentTarget.value)}
            recipients={globalEmails}
            removeItem={(i) => {
              setGlobalEmails((emails) => [...emails.slice(0, i), ...emails.slice(i + 1)]);
            }}
            value={currentEmail}
          />
          <LinkbackURLInput
            formGroupClassName={'mb-2 mt-4'}
            handleOnChange={(e) => {
              setGlobalLinkback(e.currentTarget.value);
            }}
            value={globalLinkback}
          />
          <div className={'mt-4'}>
            <Button
              data-test={'globalContactSave'}
              onClick={() => {
                // Emails
                const globalEmailsReq = AlertService.setGlobalEmailRecipients(
                  globalEmails,
                  emailSubject,
                );

                // Link Callback
                const linkCallbackReq = AlertService.setGlobalLinkback(globalLinkback);

                // Slack
                let slackReq = Promise.resolve(new Response());
                if (globalSlackDirty) {
                  slackReq = AlertService.setGlobalSlackWebhook(globalSlack, globalLinkback);
                }
                setGlobalSlackDirty(false);

                // MS Teams
                let msTeamsReq = Promise.resolve(new Response());
                if (globalMSTeamsDirty) {
                  msTeamsReq = AlertService.setGlobalMSTeamsWebhook(globalMSTeams, globalLinkback);
                }
                setGlobalMSTeamsDirty(false);

                Promise.all([globalEmailsReq, linkCallbackReq, slackReq, msTeamsReq])
                  .then(([globalEmailsRes, linkCallbackRes, slackRes, msTeamsRes]) => {
                    if (globalEmailsRes.ok && linkCallbackRes.ok && slackRes.ok && msTeamsRes.ok) {
                      setGoodMessage('Alert default settings updated');
                    } else {
                      setBadMessage('Failed to update default alert settings');
                    }
                  })
                  .catch((/* err */) => {
                    setBadMessage('Failed to update default alert settings');
                  });
              }}
              variant={'primary'}
            >
              Save
            </Button>
          </div>
        </div>

        {/* alerts table */}
        <div className={'flex'}>
          <Typography className={'grow'} variant={'h5'}>
            Alerts
          </Typography>
          <Input
            className={'mr-4'}
            onChange={(e) => setAlertListFilter(e.target.value)}
            placeholder={'Filter alerts'}
            value={alertListFilter}
          />
          <Button
            data-test={'create-alert-button'}
            onClick={() =>
              setNewAlert(
                new BudgetAlert({
                  type: AlertTypes.Budget,
                  window: '7d',
                  aggregation: 'namespace',
                  filter: '',
                  threshold: 0,
                }),
              )
            }
            variant={'primary'}
          >
            + Create Alert
          </Button>
        </div>
        <If condition={!tableAlerts.length}>
          <Typography className={'text-center'} variant={'h5'}>
            No alerts found! Why not create one?
          </Typography>
        </If>
        <If condition={!!tableAlerts.length && !displayedAlerts.length}>
          <Typography className={'text-center'} variant={'h5'}>
            Applied filter has hidden all alerts.
          </Typography>
        </If>
        <If condition={!!tableAlerts.length && !!displayedAlerts.length}>
          <
            AlertsList
            alerts={displayedAlerts}
            deleteAlert={(alert) => setDeletingAlert(alert)}
            editAlert={(alert) => setNewAlert(alert)}
            testAlert={(alert) => testAlert(alert)}
            exportAlert={(alert) => setExportAlert(alert)}
          />
        </If>
      </If>
      <Snackbar autoHideDuration={4000} onClose={() => setGoodMessage('')} open={!!goodMessage}>
        <MuiAlert onClose={() => setGoodMessage('')} severity={'success'}>
          {goodMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar autoHideDuration={4000} onClose={() => setBadMessage('')} open={!!badMessage}>
        <MuiAlert onClose={() => setBadMessage('')} severity={'error'}>
          {badMessage}
        </MuiAlert>
      </Snackbar>
      <CreateAlertModal alert={newAlert} close={() => setNewAlert(null)} save={upsertAlert} />
      <CreateDiagnosticAlertModal
        alert={newDiagnosticAlert}
        close={() => setNewDiagnosticAlert(null)}
        save={async (alert: DiagnosticAlert) => upsertAlert(alert)}
        test={testAlert}
      />
      <DeleteAlertModal
        alert={deletingAlert}
        close={() => setDeletingAlert(null)}
        remove={(alert) => removeAlert(alert)}
      />
      <ExportAlertModal
        alert={exportAlert}
        close={() => setExportAlert(null)}
      />
    </div>
  );
};

AlertsNew.displayName = 'AlertsNew';

export { AlertsNew };
