import { FC, useState } from 'react';

import Popover from '@material-ui/core/Popover';

import { Button, FormControlLabel, Input, Typography } from '@kubecost-frontend/holster';

interface CloudBreakdownSelectorProps {
  aggregateBy: string;
  allocationAgg: string;
  joinLabel: string;
  setAggregateBy: (agg: string) => void;
  setJoinLabel: (breakdown: string) => void;
}

// currently only service, provider, and account are supported
const aggOptions = [
  { display: 'Service', value: 'service' },
  { display: 'Provider', value: 'provider' },
  { display: 'Account', value: 'account' },
  // { display: 'Category', value: 'category' },
  // { display: 'Cluster', value: 'cluster' },
  // { display: 'Type', value: 'type' },
  // { display: 'Provider ID', value: 'providerid' },
];

const CloudBreakdownSelector: FC<CloudBreakdownSelectorProps> = ({
  aggregateBy,
  allocationAgg,
  joinLabel,
  setAggregateBy,
  setJoinLabel,
}) => {
  const [anchorEl, setAnchorEl] = useState<EventTarget & Element>();
  const [labelValue, setLabelValue] = useState(joinLabel);

  const col1Options = aggOptions.slice(0, Math.floor(aggOptions.length / 2));
  const col2Options = aggOptions.slice(Math.floor(aggOptions.length / 2));
  const open = Boolean(anchorEl);

  const handleSelectAggOption = (agg: string) => {
    setAggregateBy(agg);
    setAnchorEl(undefined);
  };

  return (
    <>
      <Button className={'mr-3'} onClick={(e) => setAnchorEl(e.currentTarget)} variant={'default'}>
        Cloud Breakdown
      </Button>
      <Popover
        PaperProps={{
          className: 'p-6',
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={() => {
          setAnchorEl(undefined);
        }}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={'grid'} style={{ gridTemplateColumns: '1fr 1fr' }}>
          <div className={'flex flex-col'}>
            {col2Options.map((opt) => (
              <Typography
                className={`cursor-pointer rounded p-2 hover:bg-kc-success-light ${
                  aggregateBy == opt.value ? 'text-kc-link' : ''
                }`}
                onClick={() => handleSelectAggOption(opt.value)}
                variant={'p'}
              >
                {opt.display}
              </Typography>
            ))}
          </div>
          <div className={'flex flex-col'}>
            {col1Options.map((opt) => (
              <Typography
                className={`mr-6 cursor-pointer rounded p-2 hover:bg-kc-success-light ${
                  aggregateBy === opt.value ? 'text-kc-link' : ''
                }`}
                onClick={() => handleSelectAggOption(opt.value)}
                variant={'p'}
              >
                {opt.display}
              </Typography>
            ))}
          </div>
        </div>
        <section className={'my-3'}>
          <FormControlLabel>Custom Data Mapping</FormControlLabel>
          <Typography variant={'h6'}>
            Override default label mappings for Agg/Asset aggregations
          </Typography>
          <Input
            className={'mt-2 w-full'}
            defaultValue={joinLabel}
            helperText={allocationAgg.toUpperCase()}
            onChange={(e) => setLabelValue(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setJoinLabel(labelValue);
              }
            }}
          />
        </section>
      </Popover>
    </>
  );
};

export default CloudBreakdownSelector;
