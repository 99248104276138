/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';

import DeleteIcon from '@material-ui/icons/Delete';

import { Button, Chip, Typography } from '@kubecost-frontend/holster';

import { useClusters } from '../../../contexts/ClusterConfig';

import { Cluster, regex } from './util';

type ContextRowProps = {
  cluster: Cluster;
};

const ContextRow = ({ cluster }: ContextRowProps) => {
  const { activateContext, activeContext, removeContext } = useClusters();
  const [showDelete, setShowDelete] = useState(false);
  if (!cluster) return <></>;

  const basicAuthName = regex.exec(cluster.name);
  const basicAuthAddress = regex.exec(cluster.address);

  const selectNewContext = () => {
    activateContext(cluster.address);
    setTimeout(() => window.location.reload(), 500);
  };

  return (
    <div
      className={`box-border flex gap-4 overflow-hidden border-b border-b-kc-white-200 py-4 px-6 hover:cursor-pointer hover:bg-kc-white-100 ${
        activeContext === cluster.address && 'border-l-8 border-l-kc-primary'
      }`}
      key={cluster.address}
      onClick={() => {
        selectNewContext();
      }}
    >
      <div className={'flex-grow'}>
        <Typography className={'text-lg font-bold text-kc-gray-300'} variant={'p'}>
          {basicAuthName ? basicAuthName[4] : cluster.name}
        </Typography>
        <Typography className={'text-kc-gray-100'} variant={'p'}>
          {basicAuthAddress ? basicAuthAddress[4] : cluster.address}
        </Typography>
      </div>
      {!cluster.online && <Chip className={'mt-2 h-8'} color={'default'} label={'Unconnected'} />}
      {showDelete && (
        <>
          <Button
            className={'mt-2 h-6'}
            onClick={(e) => {
              e.stopPropagation();
              setShowDelete(false);
            }}
            variant={'default'}
          >
            Cancel
          </Button>
          <Button
            className={'mt-2 h-6'}
            onClick={(e) => {
              e.stopPropagation();
              removeContext(cluster.address);
            }}
            variant={'primary'}
          >
            Delete
          </Button>
        </>
      )}
      {!showDelete && (
        <>
          <div style={{ textAlign: 'right', paddingTop: '12px' }}>
            {cluster!.federatedCluster ? 'Multi Cluster' : 'Single Cluster'}
          </div>
          <DeleteIcon
            className={'mt-3 text-kc-gray-200'}
            onClick={(e) => {
              e.stopPropagation();
              setShowDelete(true);
            }}
          />
        </>
      )}
    </div>
  );
};

export { ContextRow };
