import { useQuery } from 'react-query';

import { useClusters } from '../contexts/ClusterConfig';
import { getCurrentContainerAddressModel } from '../services/util';

type SupportedProviders = 'AWS' | 'GCP' | 'AZURE';

const isSupportedProvider = (s: string): s is SupportedProviders =>
  ['AWS', 'AZURE', 'GCP'].includes(s);

const getAllProviders = async (): Promise<SupportedProviders[]> => {
  const baseApiUrl = getCurrentContainerAddressModel();
  const URL = `${baseApiUrl}/assets?window=3d&aggregate=provider&accumulate=true`;
  const response = await fetch(URL);
  const js = await response.json();
  return Object.keys(js.data[0])
    .map((providerName) => providerName.toUpperCase())
    .filter(isSupportedProvider);
};

const useAllProviders = () => {
  const { clusterConfig } = useClusters();
  const queryKey = [clusterConfig.id, 'allProviders'];
  return useQuery(queryKey, getAllProviders);
};

export { useAllProviders };
