import React from 'react';

import { IconProps } from './types';

const SquareInfoIcon = React.forwardRef<SVGSVGElement, IconProps>(({ ...args }, ref) => (
  <svg
    height={'1em'}
    preserveAspectRatio={'xMidYMid meet'}
    ref={ref}
    viewBox={'0 0 24 24'}
    width={'1em'}
    xmlns={'http://www.w3.org/2000/svg'}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...args}
  >
    <path
      d={
        'M3 4v16a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1zm8 3h2v2h-2V7zm0 4h2v6h-2v-6z'
      }
      fill={'currentColor'}
    />
  </svg>
));

SquareInfoIcon.displayName = 'SquareInfoIcon';
export { SquareInfoIcon };
