import { useMemo, useState } from 'react';

import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Button, Toggle } from '@kubecost-frontend/holster';

import { AlertService, AlertTypes, ClusterHealthAlert } from '../../../services/alerts';
import { TestAlertButton } from '../TestAlertButton';

import { HealthAlertModal as CreateHealthAlertModal } from './HealthAlertModal';

const HealthAlert = () => {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const { data } = useQuery('alerts', AlertService.getAlerts);
  const createAlert = useMutation(AlertService.upsertAlert, {
    onSuccess: () => {
      queryClient.invalidateQueries('alerts');
    },
  });
  const deleteAlert = useMutation(AlertService.deleteAlert, {
    onSuccess: () => {
      queryClient.invalidateQueries('alerts');
    },
  });

  const clusterHealthAlert = useMemo((): ClusterHealthAlert | undefined => {
    if (!data) return undefined;
    const values = data.find((a) => a.type === 'health');
    if (!values) return undefined;

    return new ClusterHealthAlert({
      ...values,
      type: AlertTypes.Health,
    });
  }, [data]);

  const newAlertCreated = async (alert: ClusterHealthAlert) => {
    await createAlert.mutateAsync(alert);
    setShowDialog(false);
  };

  const deleteAlertClicked = () => {
    if (clusterHealthAlert === undefined) return;
    deleteAlert.mutate(clusterHealthAlert);
  };

  return (
    <div className={'pt-5'}>
      <Toggle
        checked={clusterHealthAlert !== undefined}
        data-testid={'alert-health-switch'}
        label={'Monitor Cluster Health'}
        onChange={async () => {
          if (clusterHealthAlert === undefined) {
            setShowDialog(true);
          } else {
            deleteAlertClicked();
          }
        }}
      />

      {clusterHealthAlert && (
        <Button
          className={'mr-2'}
          data-testid={'ha-edit-button'}
          onClick={() => setShowDialog(true)}
          variant={'primary'}
        >
          Edit
        </Button>
      )}
      {clusterHealthAlert && <TestAlertButton alert={clusterHealthAlert} />}

      <CreateHealthAlertModal
        alert={clusterHealthAlert}
        alertSaved={newAlertCreated}
        onClose={() => setShowDialog(false)}
        open={showDialog}
      />
    </div>
  );
};

export { HealthAlert };
