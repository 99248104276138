import { FC } from "react"

import {Modal, CodeBlock} from '@kubecost-frontend/holster';

interface Alert {
    aggregation: string;
    baselineWindow?: string;
    efficiencyThreshold?: number;
    filter: string;
    id?: string;
    ownerContact?: string[];
    relativeThreshold?: number;
    slackWebhookUrl?: string;
    msTeamsWebhookUrl?: string;
    spendThreshold?: number;
    threshold?: number;
    type: string;
    window: string;
  }

interface ExportAlertProps {
    alert: Alert | null;
    close: () => void;
};

type LineFn<T extends keyof Alert = keyof Alert> = (value: T, getString: ((x:Alert[T]) => string)) => void;

const sectionYaml = `notifications:
  alertConfigs:
    alerts:`;

const getAlertYAML = (alert: Alert) => {
    const yaml = [];
    const line: LineFn = (value, getString) => {
        if (alert[value]) {
            yaml.push(getString(alert[value]));
        }
    };

    line('type',
        x => `- type: ${x}`
    );

    line('threshold',
        x => `  threshold: ${x}`
    );

    line('efficiencyThreshold',
        x => `  efficiencyThreshold: ${x}`
    );

    line('relativeThreshold',
        x => `  relativeThreshold: ${x}`
    );

    line('spendThreshold',
        x => `  spendThreshold: ${x}`
    );

    line('window',
        x => `  window: ${x}`
    );

    line('baselineWindow',
        x => `  baselineWindow: ${x}`
    );

    line('aggregation',
        x => `  aggregation: ${x}`
    );

    line('filter',
        x => `  filter: ${x}`
    );

    line('slackWebhookUrl', 
        x => `  slackWebhookUrl: ${x}`
    );

    line('msTeamsWebhookUrl',
        x => `  msTeamsWebhookUrl: ${x}`    
    );

    line('ownerContact',
        x => `  ownerContact:`
    );

    for (let contact of (alert.ownerContact ?? [])) {
        yaml.push(`    - ${contact}`);
    }

    return yaml.map(l => `    ${l}`).join('\n');
};

export const ExportAlertModal: FC<ExportAlertProps> = ({alert, close}) => {
    if (!alert) {
        return null;
    }

    return (
        <Modal
            title="Export Alert"
            data-test="export-alert-dialog"
            onClose={close}
            open
        >
            <p className="py-4">To the following section of <strong>values.yaml</strong>:</p>
            <CodeBlock
                text={sectionYaml}
                language="YAML"
                showLineNumbers={false}
            />
            <p className="py-4">Add the following alert:</p>
            <CodeBlock
                copy
                text={getAlertYAML(alert)}
                language="YAML"
                showLineNumbers={false}
            />
            <br />
            <p>
                For more info visit the <a href="https://docs.kubecost.com/using-kubecost/getting-started/alerts">Alerts Doc Page.</a>
            </p>
        </Modal>
    );
};