import { FC, useEffect, useState } from 'react';

import { Button, Modal, Typography } from '@kubecost-frontend/holster';

import { AlertTypes, DiagnosticAlert } from '../../services/alerts';

import { MSTeamsInput, SlackInput } from './AlertInputFields';
import { TestAlertButton } from './TestAlertButton';
import { WEBHOOKS } from './types';

const DEFAULTS = {
  slackUrl: '',
  slackDirty: false,
  msTeamsUrl: '',
  msTeamsDirty: false,
};

interface ComponentProps {
  alert: DiagnosticAlert | null;
  close: () => void;
  save: (alert: DiagnosticAlert) => Promise<void>;
  test: (alert: DiagnosticAlert) => Promise<void>;
}

const DiagnosticAlertModal: FC<ComponentProps> = ({ alert, close, save, test }: ComponentProps) => {
  const [slackDirty, setSlackDirty] = useState(DEFAULTS.slackDirty);
  const [slackUrl, setSlackUrl] = useState(DEFAULTS.slackUrl);
  const [msTeamsDirty, setMSTeamsDirty] = useState(DEFAULTS.msTeamsDirty);
  const [msTeamsUrl, setMSTeamsUrl] = useState(DEFAULTS.msTeamsUrl);
  useEffect(() => {
    if (alert && alert.slackWebhookUrl) {
      setSlackUrl(WEBHOOKS.SLACK.PLACEHOLDER);
    }
    if (alert && alert.msTeamsWebhookUrl) {
      setMSTeamsUrl(WEBHOOKS.MS_TEAMS.PLACEHOLDER);
    }
  }, [alert]);

  const handleOnClose = () => {
    setSlackUrl(DEFAULTS.slackUrl);
    setSlackDirty(DEFAULTS.slackDirty);
    setMSTeamsUrl(DEFAULTS.msTeamsUrl);
    setMSTeamsDirty(DEFAULTS.msTeamsDirty);
    close();
  };

  const buildAlert = () => {
    const a = new DiagnosticAlert({
      type: AlertTypes.Diagnostic,
      window: '5m',
      slackWebhookUrl: alert?.slackWebhookUrl,
      msTeamsWebhookUrl: alert?.msTeamsWebhookUrl,
      id: alert?.id,
    });
    if (slackDirty) {
      a.slackWebhookUrl = slackUrl;
    }
    if (msTeamsDirty) {
      a.msTeamsWebhookUrl = msTeamsUrl;
    }

    return a;
  };

  const handleOnSave = async () => {
    const a = buildAlert();
    await save(a);
    handleOnClose();
  };

  const handleOnTest = async () => {
    const a = buildAlert();
    test(a);
  };

  return (
    <Modal
      data-test={'diagnostic-alert-dialog'}
      onClose={handleOnClose}
      open={!!alert}
      title={'Enable Kubecost Health Monitoring'}
    >
      <Typography className={'mb-4'} variant={'p'}>
        With Kubecost Health monitoring enabled, Kubecost will send a message to the designated
        webhook(s) any time it is unable to contact its backing data store.
      </Typography>

      <SlackInput
        formGroupClassName={'my-1'}
        handleOnChange={(e) => {
          setSlackUrl(e.currentTarget.value);
          setSlackDirty(true);
        }}
        helperText={''}
        inputClassName={'w-full'}
        value={slackUrl}
      />

      <MSTeamsInput
        formGroupClassName={'my-1'}
        handleOnChange={(e) => {
          setMSTeamsUrl(e.currentTarget.value);
          setMSTeamsDirty(true);
        }}
        helperText={''}
        inputClassName={'w-full'}
        value={msTeamsUrl}
      />

      <div className={'mt-2 flex justify-end'}>
        <Button className={'mr-2'} onClick={handleOnClose} variant={'primary'}>
          Cancel
        </Button>
        <Button className={'mr-2'} onClick={handleOnSave} variant={'primary'}>
          Save
        </Button>
        <TestAlertButton alert={buildAlert()} />
      </div>
    </Modal>
  );
};

export { DiagnosticAlertModal };
