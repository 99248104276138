import { useCallback } from 'react';

import { useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';

const unsupportedPages: string[] = [
  '/abandoned-workloads',
  '/context-list',
  '/diagnostics',
  '/etl-status',
  '/orphaned-resources',
  '/reservations',
  '/spot',
];

const getValueFromLocalStorage = (key: string, defaultValue = true): boolean => {
  try {
    const value = window.localStorage.getItem(key);

    return value ? JSON.parse(value) : defaultValue;
  } catch (error) {
    console.error(error);

    return defaultValue;
  }
};

const useBetaUI = (): {
  isBetaUI: boolean;
  isEnabled: boolean;
  setIsBetaUI: typeof setIsBetaUI;
} => {
  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const storageKey = 'oldUI';

  const { data: isEnabled } = useQuery([storageKey], () => getValueFromLocalStorage(storageKey), {
    initialData: () => getValueFromLocalStorage(storageKey),
    // manually control query refetch because data doesn't change unexpectedly
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });

  const setIsBetaUI = useCallback(
    (value: boolean) => {
      try {
        window.localStorage.setItem(storageKey, JSON.stringify(value));
        // triggers observers to refetch
        queryClient.invalidateQueries([storageKey]);
      } catch (error) {
        console.error(error);
      }
    },
    [queryClient],
  );

  // combines frontend logic with user preference
  const getFeatureFlag = () => {
    if (unsupportedPages.includes(pathname)) return false;

    /* React Query is entirely async and isn't expecting our value to come from
     * an always available source like `localStorage` */
    return isEnabled === true;
  };

  return {
    isBetaUI: getFeatureFlag(), // use as a feature flag
    isEnabled: isEnabled === true, // use as persisted user preference
    setIsBetaUI,
  };
};

export { useBetaUI };
