import React, { useEffect, useState } from 'react';

import {
  Alert,
  Chip,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '@kubecost-frontend/holster';

import { Header } from '../../../components/Header2New';

import { definitions } from './ActionDefinitions';
import { ActionDetailModal } from './ActionDetailModal';
import { ActionListItem } from './types';
import { DeleteActionButton } from './DeleteActionButton';

const AllActions = () => {
  const [actionRows, setActionRows] = useState<{
    [key: string]: {
      rows: ActionListItem[];
      deleteAction: any;
    };
  }>({});
  const [selectedAction, setSelectedAction] = useState<ActionListItem | undefined>();

  useEffect(() => {
    definitions.forEach(async (definition) => {
      const data = await definition.getData();
      setActionRows((preVal) => ({
        ...preVal,
        [definition.name]: {
          rows: data,
          deleteAction: definition.deleteAction,
        },
      }));
    });
  }, []);

  const handleOnClose = () => {
    setSelectedAction(undefined);
  };

  return (
    <>
      <Header
        helpHref={'https://docs.kubecost.com/'}
        helpTooltip={'Product Documentation'}
        refreshCallback={() => {}}
        title={'Actions'}
      />

      <div>
        <Alert
          content={
            "Welcome to Kubecost Actions! Kubecost Actions is a one stop shop for managing all automated jobs that Kubecost executes for you! You're able to see what jobs are enabled and when. Please note, this is a beta feature."
          }
          title={'Kubecost Actions'}
          variant={'info'}
        />
        <h5 className={'mt-4'}>Scheduled Actions</h5>
        <Table className={'w-full'}>
          <TableHead>
            <TableRow>
              <TableHeadCell>Action</TableHeadCell>
              <TableHeadCell>Schedule</TableHeadCell>
              <TableHeadCell>Next Run</TableHeadCell>
              <TableHeadCell>Affected Workloads</TableHeadCell>
              <TableHeadCell>Status</TableHeadCell>
              <TableHeadCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(actionRows).map((key) => {
              const config = actionRows[key];

              return config.rows.map((action) => (
                <TableRow key={action.id}>
                  <TableCell className={'font-bold'}>{action.displayName}</TableCell>
                  <TableCell>{action.scheduleFrequency}</TableCell>
                  <TableCell>{action.nextRun}</TableCell>
                  <TableCell>{action.numberOfAffectedWorkloads} Affected Workloads</TableCell>
                  <TableCell>
                    <Chip color={'primary'} label={'Active'} />
                  </TableCell>
                  <TableCell>
                    <a onClick={() => setSelectedAction(action)}>Edit</a>
                  </TableCell>
                  <TableCell>
                    <DeleteActionButton item={action} deleteAction={config.deleteAction} />
                  </TableCell>
                </TableRow>
              ));
            })}
          </TableBody>
        </Table>
        <ActionDetailModal handleOnClose={handleOnClose} item={selectedAction} />
      </div>
    </>
  );
};

export { AllActions };
