import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AlertComponent from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles';
import { ChangeEvent, useEffect, useState } from 'react';

import { SelectWindowMemoized } from '../../components/SelectWindow';
import {
  Alert as AlertModel,
  BudgetAlert,
  EfficiencyAlert,
  RecurringUpdate,
  SpendChangeAlert,
  AssetBudget,
  AssetRecurring,
  AlertTypes,
} from '../../services/alerts';
import Logger from '../../services/logger';

import EmailRecipients from './EmailRecipients';

interface Alert {
  aggregation: string;
  baselineWindow?: string;
  efficiencyThreshold?: number;
  filter: string;
  id?: string;
  ownerContact?: string[];
  relativeThreshold?: number;
  slackWebhookUrl?: string;
  spendThreshold?: number;
  threshold?: number;
  type: string;
  window: string;
}

interface ComponentProps {
  alert: Alert | null;
  close: () => void;
  save: (alert: AlertModel) => Promise<void>;
  test: (alert: AlertModel) => Promise<void>;
}

const allocationAggregationOptions = [
  {
    name: 'Cluster',
    value: 'cluster',
  },
  {
    name: 'Controller',
    value: 'controller',
  },
  {
    name: 'Container',
    value: 'container',
  },
  {
    name: 'Deployment',
    value: 'deployment',
  },
  {
    name: 'Label',
    value: 'label',
  },
  {
    name: 'Namespace',
    value: 'namespace',
  },
  {
    name: 'Pod',
    value: 'pod',
  },
  {
    name: 'Service',
    value: 'service',
  },
];

const assetAggregationOptions = [
  {
    name: 'Service',
    value: 'service',
  },
  {
    name: 'Type',
    value: 'type',
  },
  {
    name: 'Category',
    value: 'category',
  },
  {
    name: 'Cluster',
    value: 'cluster',
  },
  {
    name: 'Provider',
    value: 'provider',
  },
  {
    name: 'Account',
    value: 'account',
  },
  {
    name: 'Provider ID',
    value: 'ProviderID',
  },
];

const windowOptions = [
  { label: '1 day', value: '1d' },
  { label: '2 days', value: '2d' },
  { label: '3 days', value: '3d' },
  { label: '4 days', value: '4d' },
  { label: '5 days', value: '5d' },
  { label: '6 days', value: '6d' },
  { label: '7 days', value: '7d' },
];

const useStyles = makeStyles({
  formGroup: {
    margin: '20px 0px 20px 0px',
  },
  helpIcon: {
    color: '#999',
    fontSize: '1.2rem',
    cursor: 'pointer',
  },
  tooltip: {
    fontSize: '.9rem',
  },
});

const CreateAlertModal = ({
  alert: initialAlert,
  close,
  save,
  test,
}: ComponentProps): JSX.Element => {
  const classes = useStyles();

  const [alertId, setAlertId] = useState('');
  const [type, setType] = useState('budget');
  const [alertWindow, setAlertWindow] = useState('7d');
  const [aggregation, setAggregation] = useState('namespace');
  const [aggLabel, setAggLabel] = useState('');
  const [alertFilter, setAlertFilter] = useState('');
  const [threshold, setThreshold] = useState('');
  const [efficiencyThreshold, setEfficiencyThreshold] = useState('');
  const [spendThreshold, setSpendThreshold] = useState('');
  const [baselineWindow, setBaselineWindow] = useState('7d');
  const [relativeChangeThreshold, setRelativeChangeThreshold] = useState('');
  const [alertEmailRecipients, setAlertEmailRecipients] = useState<string[]>([]);
  const [alertSlackHook, setAlertSlackHook] = useState('');
  const [alertSlackHookDirty, setAlertSlackHookDirty] = useState(false);
  const [formError, setFormError] = useState('');
  const [emailRecipientText, setEmailRecipientText] = useState('');
  const [aggregationOptions, setAggregationOptions] = useState(allocationAggregationOptions);
  useEffect(() => {
    if (initialAlert) {
      setType(initialAlert.type);
      setAlertWindow(initialAlert.window);
      if (initialAlert.aggregation.startsWith('label:')) {
        setAggregation('label');
        setAggLabel(initialAlert.aggregation.slice(6));
      } else {
        setAggregation(initialAlert.aggregation);
      }
      if (initialAlert.filter) {
        setAlertFilter(initialAlert.filter);
      }
      if (initialAlert.threshold) {
        setThreshold(initialAlert.threshold.toString());
      }
      if (initialAlert.efficiencyThreshold) {
        setEfficiencyThreshold((initialAlert.efficiencyThreshold * 100).toString());
      }
      if (initialAlert.spendThreshold) {
        setSpendThreshold(initialAlert.spendThreshold.toString());
      }
      if (initialAlert.baselineWindow) {
        setBaselineWindow(initialAlert.baselineWindow);
      }
      if (initialAlert.relativeThreshold) {
        setRelativeChangeThreshold((initialAlert.relativeThreshold * 100).toString());
      }
      if (initialAlert.ownerContact) {
        setAlertEmailRecipients(initialAlert.ownerContact);
      }
      if (initialAlert.slackWebhookUrl) {
        setAlertSlackHook(
          'https://hooks.slack.com/services/XXXXXXXXXXX/XXXXXXXXXXX/XXXXXXXXXXXXXXXXXXXXXXXX',
        );
      }
      if (initialAlert.id) {
        setAlertId(initialAlert.id);
      }
    }
  }, [initialAlert]);

  useEffect(() => {
    if (type.startsWith('asset') || type.startsWith('cloud')) {
      if (aggregationOptions !== assetAggregationOptions) {
        setAggregation('service');
      }
      setAggregationOptions(assetAggregationOptions);
    } else {
      if (aggregationOptions !== allocationAggregationOptions) {
        setAggregation('namespace');
      }
      setAggregationOptions(allocationAggregationOptions);
    }
  }, [type]);

  return (
    <Dialog data-test={'create-alert-dialog'} onClose={onClose} open={!!initialAlert} fullWidth>
      <DialogTitle>{alertId ? 'Edit Alert' : 'Create Alert'}</DialogTitle>
      <DialogContent>
        {/* Alert Type */}
        <FormGroup>
          <FormControl className={classes.formGroup}>
            <InputLabel>Alert Type</InputLabel>
            <Select
              data-test={'type-select'}
              onChange={(e: ChangeEvent<{ value: unknown }>) =>
                setType(e.target.value as AlertTypes)
              }
              value={type}
            >
              <MenuItem value={AlertTypes.Budget}>Allocation Budget</MenuItem>
              <MenuItem value={AlertTypes.Efficiency}>Allocation Efficiency</MenuItem>
              <MenuItem value={AlertTypes.Recurring}>Allocation Recurring Update</MenuItem>
              <MenuItem value={AlertTypes.SpendChange}>Allocation Spend Change</MenuItem>
              <MenuItem value={AlertTypes.AssetBudget}>Asset Budget</MenuItem>
              <MenuItem value={AlertTypes.AssetRecurring}>Cloud Report</MenuItem>
            </Select>
            <FormHelperText>{getAlertTypeText()}</FormHelperText>
          </FormControl>
        </FormGroup>

        <Box display={'flex'}>
          {/* Alert Window */}
          <SelectWindowMemoized
            helperText={'The date range over which to query items'}
            setWindow={setAlertWindow}
            window={alertWindow}
            windowOptions={windowOptions}
          />

          {/* Alert Aggregation */}
          <FormControl style={{ marginLeft: 24 }}>
            <InputLabel>Aggregation</InputLabel>
            <Select
              onChange={(e: ChangeEvent<{ value: unknown }>) => {
                setAggLabel('');
                setAggregation(e.target.value as string);
              }}
              value={aggregation}
            >
              {aggregationOptions.map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              Type of{' '}
              {aggregationOptions === assetAggregationOptions ? 'cloud asset' : 'Kubernetes object'}{' '}
              to consider
            </FormHelperText>
          </FormControl>

          {/* An input for Label, if that aggregation is selected */}
          {aggregation === 'label' ? (
            <TextField
              helperText={'The label to aggregate by'}
              label={'Label'}
              onChange={(e) => setAggLabel(e.target.value)}
              placeholder={'app:kubecost'}
              style={{ marginLeft: 12 }}
              value={aggLabel}
            />
          ) : (
            <></>
          )}
        </Box>

        {/* Alert Filter */}
        <FormGroup className={classes.formGroup}>
          <TextField
            helperText={getFilterHelperText()}
            label={'Filter'}
            onChange={(e) => setAlertFilter(e.target.value)}
            value={alertFilter}
          />
        </FormGroup>

        {/* Content depends on the type of Alert we are creating */}
        {fieldsForType(type)}

        {/* Finally, optional settings for recipient channels */}
        <Typography variant={'h6'}>Recipients</Typography>
        <FormGroup>
          <TextField
            helperText={
              alertId
                ? 'Slack webhook for this alert (optional). Obfuscated for security purposes.'
                : 'Slack webhook for this alert (optional).'
            }
            label={'Slack webhook'}
            onChange={(e) => {
              setAlertSlackHookDirty(true);
              setAlertSlackHook(e.target.value);
            }}
            placeholder={
              'https://hooks.slack.com/services/XXXXXXXXXXX/XXXXXXXXXXX/XXXXXXXXXXXXXXXXXXXXXXXX'
            }
            style={{ marginTop: 8, marginBottom: 16, width: '100%' }}
            value={alertSlackHook}
          />
        </FormGroup>

        <EmailRecipients
          addItem={(item: string) => {
            if (!alertEmailRecipients.includes(item)) {
              setAlertEmailRecipients((recp) => [...recp, item]);
            }
          }}
          recipients={alertEmailRecipients}
          removeItem={(item: number) => {
            setAlertEmailRecipients((recp) => recp.slice(0, item).concat(recp.slice(item + 1)));
          }}
          setText={setEmailRecipientText}
          text={emailRecipientText}
        />
        <div>
          <Button
            color={'primary'}
            onClick={onTest}
            style={{ marginTop: 36 }}
            variant={'contained'}
            disableElevation
          >
            Test Alert
          </Button>
          <Typography style={{ fontSize: 12 }}>
            Test out alert configuration by sending a test message to all recipients.
          </Typography>
        </div>
      </DialogContent>

      <DialogActions>
        <Button color={'primary'} onClick={onClose}>
          Cancel
        </Button>
        <Button
          color={'primary'}
          data-test={'save-button'}
          onClick={onSave}
          variant={'contained'}
          disableElevation
        >
          Save
        </Button>
      </DialogActions>
      <Snackbar autoHideDuration={4000} onClose={() => setFormError('')} open={Boolean(formError)}>
        <AlertComponent severity={'error'}>{formError}</AlertComponent>
      </Snackbar>
    </Dialog>
  );

  function fieldsForType(alertType: string) {
    if (alertType === 'budget' || alertType === 'assetBudget') {
      return (
        <FormGroup className={classes.formGroup}>
          <TextField
            helperText={'Total costs rising beyond this threshold will trigger the alert'}
            inputProps={{
              'data-test': 'cost-threshold',
            }}
            label={'Cost Threshold'}
            onChange={(e) => setThreshold(e.target.value)}
            value={threshold}
          />
        </FormGroup>
      );
    }
    if (alertType === 'efficiency') {
      return (
        <>
          <FormGroup className={classes.formGroup}>
            <TextField
              InputProps={{
                endAdornment: <InputAdornment position={'end'}>%</InputAdornment>,
              }}
              helperText={
                'Total efficiency of queried items falling below this threshold triggers the alert. Ranges from 0.0 to 100.0'
              }
              inputProps={{
                'data-test': 'efficiency-threshold',
              }}
              label={'Efficiency Threshold'}
              onChange={(e) => setEfficiencyThreshold(e.target.value)}
              value={efficiencyThreshold}
            />
          </FormGroup>
          <FormGroup className={classes.formGroup}>
            <TextField
              helperText={
                'The minimum spend threshold for alerting. Items whose total costs are below this number will not trigger alerts, even if they fall below the efficiency threshold.'
              }
              inputProps={{
                'data-test': 'cost-threshold',
              }}
              label={'Cost Threshold'}
              onChange={(e) => setSpendThreshold(e.target.value)}
              value={spendThreshold}
            />
          </FormGroup>
        </>
      );
    }
    if (alertType === 'spendChange') {
      return (
        <>
          <SelectWindowMemoized
            helperText={
              'Collect data N days prior to the queried items, in order to establish a cost baseline'
            }
            setWindow={setBaselineWindow}
            window={baselineWindow}
            windowOptions={windowOptions}
          />
          <FormGroup className={classes.formGroup}>
            <TextField
              InputProps={{
                endAdornment: <InputAdornment position={'end'}>%</InputAdornment>,
              }}
              helperText={
                'Percentage of change from the baseline (positive or negative) which will trigger the alert. Ranges from -100% (costs dropped to 0) upward.'
              }
              label={'Relative change threshold'}
              onChange={(e) => setRelativeChangeThreshold(e.target.value)}
              value={relativeChangeThreshold}
            />
          </FormGroup>
        </>
      );
    }
    return <></>;
  }

  function getAlertTypeText() {
    if (type === 'budget') {
      return `
        Allocation Budget alerts are triggered when the total cost of allocations goes over a set budget limit.
      `;
    }
    if (type === 'efficiency') {
      return `
        Allocation Efficiency alerts are triggered when the average efficiency of allocations falls below a set threshold.
      `;
    }
    if (type === 'recurringUpdate') {
      return `
        Allocation Recurring alerts have no trigger. They simply issue a report on the state of the allocations every <date range> days.
      `;
    }
    if (type === 'spendChange') {
      return `
        Allocation Spend change alerts are triggered when a significant jump or drop in spend relative to the average occurs. 
      `;
    }
    if (type === 'assetBudget') {
      return `
        Asset Budget alerts are triggered when the total cost of assets goes over a set budget limit.
      `;
    }
    if (type === 'cloudReport') {
      return `
        Asset Recurring alerts have no trigger. They simply issue a report on the state of the assets every <date range> days.
      `;
    }
    return '';
  }

  function getFilterHelperText() {
    const aggOpt = aggregationOptions.find((opt) => opt.value === aggregation);
    if (!aggOpt) {
      return 'Filter to a specific item within the chosen aggregation';
    }
    return `Filter to a specific ${aggOpt.name}`;
  }

  function onTest() {
    setFormError('');
    try {
      validate();
    } catch (err) {
      if (err instanceof Error) {
        setFormError(err.message);
      } else {
        Logger.error(err);
      }
      return;
    }
    let recipients = [...alertEmailRecipients];
    if (emailRecipientText && !recipients.includes(emailRecipientText)) {
      recipients = [...recipients, emailRecipientText];
      setAlertEmailRecipients(recipients);
      setEmailRecipientText('');
    }
    const aType = type as AlertTypes;
    const agg = aggregation === 'label' ? `label:${aggLabel}` : aggregation;
    const slackWebhookUrl = alertSlackHookDirty
      ? alertSlackHook
      : initialAlert?.slackWebhookUrl || '';
    const alert = {
      type: aType,
      aggregation: agg,
      window: alertWindow,
      filter: alertFilter,
      id: alertId,
      threshold: parseFloat(threshold),
      efficiencyThreshold: parseFloat(efficiencyThreshold) / 100,
      spendThreshold: parseFloat(spendThreshold),
      baselineWindow,
      relativeThreshold: parseFloat(relativeChangeThreshold) / 100,
      ownerContact: recipients,
      slackWebhookUrl,
    };
    if (aType === AlertTypes.Budget) {
      test(new BudgetAlert(alert));
    } else if (aType === AlertTypes.Efficiency) {
      test(new EfficiencyAlert(alert));
    } else if (aType === AlertTypes.Recurring) {
      test(new RecurringUpdate(alert));
    } else if (aType === AlertTypes.SpendChange) {
      test(new SpendChangeAlert(alert));
    } else if (aType === AlertTypes.AssetBudget) {
      test(new AssetBudget(alert));
    } else if (aType === AlertTypes.AssetRecurring) {
      test(new AssetRecurring(alert));
    }
  }

  function onSave() {
    setFormError('');
    try {
      validate();
    } catch (err) {
      if (err instanceof Error) {
        setFormError(err.message);
      }
      return;
    }
    let recipients = [...alertEmailRecipients];
    if (emailRecipientText && !recipients.includes(emailRecipientText)) {
      recipients = [...recipients, emailRecipientText];
      setEmailRecipientText('');
    }
    const aType = type as AlertTypes;
    const agg = aggregation === 'label' ? `label:${aggLabel}` : aggregation;
    const slackWebhookUrl = alertSlackHookDirty
      ? alertSlackHook
      : initialAlert?.slackWebhookUrl || '';
    const alert = {
      type: aType,
      aggregation: agg,
      window: alertWindow,
      filter: alertFilter,
      id: alertId,
      threshold: parseFloat(threshold),
      efficiencyThreshold: parseFloat(efficiencyThreshold) / 100,
      spendThreshold: parseFloat(spendThreshold),
      baselineWindow,
      relativeThreshold: parseFloat(relativeChangeThreshold) / 100,
      ownerContact: recipients,
      slackWebhookUrl,
    };
    if (aType === AlertTypes.Budget) {
      save(new BudgetAlert(alert));
    } else if (aType === AlertTypes.Efficiency) {
      save(new EfficiencyAlert(alert));
    } else if (aType === AlertTypes.Recurring) {
      save(new RecurringUpdate(alert));
    } else if (aType === AlertTypes.SpendChange) {
      save(new SpendChangeAlert(alert));
    } else if (aType === AlertTypes.AssetBudget) {
      save(new AssetBudget(alert));
    } else if (aType === AlertTypes.AssetRecurring) {
      save(new AssetRecurring(alert));
    }
    onClose();
  }

  function onClose() {
    setAlertId('');
    setType('budget');
    setAlertWindow('7d');
    setAggregation('namespace');
    setAggLabel('');
    setAlertFilter('');
    setThreshold('');
    setEfficiencyThreshold('');
    setSpendThreshold('');
    setBaselineWindow('7d');
    setRelativeChangeThreshold('');
    setAlertEmailRecipients([]);
    setAlertSlackHook('');
    setAlertSlackHookDirty(false);
    close();
  }

  function validate(): void {
    // validate window
    const validWindows = windowOptions.map((w) => w.value);
    if (!validWindows.includes(alertWindow)) {
      throw new Error(
        `Expected window to be one of: ${validWindows.join(', ')}. Instead got ${alertWindow}`,
      );
    }

    // validate aggregation
    const validAggs = aggregationOptions.map((agg) => agg.value);
    if (!validAggs.includes(aggregation)) {
      throw new Error(
        `Expected aggregation to be one of: ${validAggs.join(', ')}. Instead got ${aggregation}`,
      );
    }

    // type-specific validation
    if (type === 'budget' || type === 'assetBudget') {
      if (Number.isNaN(parseFloat(threshold))) {
        throw new Error('No cost threshold set');
      }
    } else if (type === 'efficiency') {
      if (Number.isNaN(parseFloat(spendThreshold))) {
        throw new Error('No spend threshold set');
      }
      const et = parseFloat(efficiencyThreshold);
      if (Number.isNaN(et)) {
        throw new Error('No efficiency threshold set');
      }
      if (et < 0.0 || et > 100.0) {
        throw new Error('Efficiency threshold is outside the permitted range (0 to 100)');
      }
    } else if (type === 'recurringUpdate' || type === 'cloudReport') {
    } else if (type === 'spendChange') {
      if (!validWindows.includes(baselineWindow)) {
        throw new Error(
          `Expected baseline window to be one of: ${validWindows.join(
            ', ',
          )}. Instead got ${baselineWindow}`,
        );
      }
      const rt = parseFloat(relativeChangeThreshold);
      if (Number.isNaN(rt)) {
        throw new Error('No relative change threshold set');
      }
      if (rt < -100.0) {
        throw new Error('Relative change threshold cannot be less than -100%');
      }
    } else {
      throw new Error(
        `Expected alert type to be one of: budget, assetBudget, efficiency, recurringUpdate, cloudReport, spendChange. Instead got ${type}`,
      );
    }
  }
};

export default CreateAlertModal;
