import { FC, MouseEventHandler } from 'react';

import { Button } from '@kubecost-frontend/holster';

import { SettingsIcon } from '../../assets/images/settings-icon';

interface EditControlProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export const EditControl: FC<EditControlProps> = ({ onClick }) => (
  <Button className={'h-33px mr-3 flex items-center'} onClick={onClick} variant={'default'}>
    <SettingsIcon className={'mr-2'} />
    Edit
  </Button>
);
