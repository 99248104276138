import { FC } from 'react';

const AggregationIcon: FC = (props) => (
    <svg width="18" height="20" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3.73901 3.70886L5.94787 1.5L8.15674 3.70886" stroke="#222325" stroke-width="0.8" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M5.9668 10.7634V1.5" stroke="#222325" stroke-width="0.8" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.92746 4.96875L3.22778 7.66797" stroke="#222325" stroke-width="0.8" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.99906 4.96838L8.69873 7.6676" stroke="#222325" stroke-width="0.8" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.30345 10.1324C3.02333 10.1324 3.60691 9.54886 3.60691 8.82898C3.60691 8.1091 3.02333 7.52551 2.30345 7.52551C1.58358 7.52551 1 8.1091 1 8.82898C1 9.54886 1.58358 10.1324 2.30345 10.1324Z" stroke="#222325" stroke-width="0.8" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.49364 10.1326C10.2135 10.1326 10.7971 9.54898 10.7971 8.8291C10.7971 8.10922 10.2135 7.52563 9.49364 7.52563C8.77376 7.52563 8.19019 8.10922 8.19019 8.8291C8.19019 9.54898 8.77376 10.1326 9.49364 10.1326Z" stroke="#222325" stroke-width="0.8" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.94945 13.4999C6.66933 13.4999 7.2529 12.9163 7.2529 12.1964C7.2529 11.4765 6.66933 10.8929 5.94945 10.8929C5.22957 10.8929 4.646 11.4765 4.646 12.1964C4.646 12.9163 5.22957 13.4999 5.94945 13.4999Z" stroke="#222325" stroke-width="0.8" stroke-miterlimit="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export { AggregationIcon };
