import { useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import { Alert, Table, TableHead, TableHeadCell, TableRow } from '@kubecost-frontend/holster';

import { Loading } from '../../../components/Loading';
import { useSortableData } from '../../../hooks/useSortableData';
import { Allocation } from '../../../types/allocation';
import { useGetAllocation } from '../../AllocationNew/hooks/useGetAllocationSummary';

import { NetworkAllocationTableBody } from './NetworkAllocationTableBody';

const HEADERS = [
  { key: 'pod', label: 'Pod' },
  { key: 'namespace', label: 'Namespace' },
  { key: 'networkInternetCost', label: 'Internet Egress' },
  { key: 'networkCrossZoneCost', label: 'Cross Zone' },
  { key: 'networkCrossRegionCost', label: 'Cross Region' },
  { key: 'networkCost', label: 'Total' },
];

interface NetworkAllocationTableProps {
  setSelectedAllocationData: (allocation: Allocation) => void;
}

const NetworkAllocationTable = ({ setSelectedAllocationData }: NetworkAllocationTableProps) => {
  const [search] = useSearchParams();

  const namespace = search.get('namespace')?.split(',')[0];
  const window = search.get('window');
  const { allocations, isError, isLoading } = useGetAllocation({
    window: window !== null ? window : '1d',
    aggregateBy: 'namespace,pod',
    filterNamespaces: namespace !== null ? namespace : '',
    accumulate: true,
    shareIdle: true,
  });

  const { networkAllocationRowData, totals } = useMemo(() => {
    const totalsBuilder = {
      networkInternetCost: 0,
      networkCrossZoneCost: 0,
      networkCrossRegionCost: 0,
      networkCost: 0,
    };
    if (!allocations) {
      return {
        totals: totalsBuilder,
        networkAllocationRowData: [],
      };
    }

    const networkAllocationRowDataBuilder = Object.keys(allocations[0]).map((allocationKey) => {
      const allocation = allocations[0][allocationKey];

      // Accumulate totals
      totalsBuilder.networkInternetCost += allocation.networkInternetCost;
      totalsBuilder.networkCrossZoneCost += allocation.networkCrossZoneCost;
      totalsBuilder.networkCrossRegionCost += allocation.networkCrossRegionCost;
      totalsBuilder.networkCost += allocation.networkCost;

      // Assemble row data in object that is easy to sort by
      return {
        allocation,
        pod: allocation.properties?.pod,
        namespace: allocation.properties?.namespace,
        networkInternetCost: allocation.networkInternetCost,
        networkCrossZoneCost: allocation.networkCrossZoneCost,
        networkCrossRegionCost: allocation.networkCrossRegionCost,
        networkCost: allocation.networkCost,
      };
    });

    return {
      totals: totalsBuilder,
      networkAllocationRowData: networkAllocationRowDataBuilder,
    };
  }, [allocations]);

  const {
    handleSort,
    sortConfig,
    sortedData: sortedRowData,
  } = useSortableData(networkAllocationRowData, { ascending: false, key: 'networkCost' });

  if (isLoading) {
    return <Loading className={'mt-5'} message={'Loading network data...'} />;
  }

  if (isError) {
    return (
      <>
        <br />
        <Alert
          content={'There was an error when retrieving your network cost allocation data.'}
          title={'Error!'}
          variant={'danger'}
        />
      </>
    );
  }

  return (
    <Table className={'mt-5 w-full'}>
      <TableHead>
        <TableRow className={'font-bold'}>
          {HEADERS.map(({ key, label }) => {
            let icon = '';
            if (sortConfig.key === key) {
              icon = sortConfig.ascending ? '↑' : '↓';
            }
            return (
              <TableHeadCell
                className={'hover:cursor-pointer'}
                key={key}
                onClick={() => handleSort(key)}
                style={{
                  textAlign: key.endsWith('Cost') ? 'right' : 'left',
                  paddingLeft: 16,
                  paddingRight: 16,
                }}
              >
                {label} {icon}
              </TableHeadCell>
            );
          })}
        </TableRow>
      </TableHead>
      <NetworkAllocationTableBody
        setSelectedAllocationData={setSelectedAllocationData}
        sortedRowData={sortedRowData}
        totals={totals}
      />
    </Table>
  );
};

export { NetworkAllocationTable };
