import { forwardRef, useContext, useEffect, useState } from 'react';

import { Dialog, Slide, TextField, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { zendeskToken } from '../constants';
import { analytics as Analytics } from '../services/analytics';

import { SearchBarContext } from './SearchBarContext';

const Transition = forwardRef((props, ref) => <Slide direction={'up'} ref={ref} {...props} />);

/* --- Sample Search Result
  name: "Alerts Documentation"
  snippet: "<em>Alerts</em> Summary Kubecost <em>alerts</em> allow teams to receive updates on real-time Kubernetes spend."
  url: "https://kubecost.zendesk.com/api/v2/help_center/en-us/articles/4407601796759.json"
*/

interface SearchResult {
  html_url: string;
  name: string;
  snippet: string;
  url: string;
}

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    top: 100,
  },
});

export const NavigationBar = () => {
  const classes = useStyles();
  const { isVisible, setIsVisible } = useContext(SearchBarContext);

  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [kDown, setkDown] = useState(false);
  const [metaDown, setMetaDown] = useState(false);

  async function handleSearch() {
    fetch(`https://kubecost.zendesk.com/api/v2/help_center/articles/search?query=${searchText}`, {
      headers: { Authorization: `Bearer ${zendeskToken}` },
    })
      .then((response) => response.json())
      .then((data) => {
        setSearchResults(data.results);
      });
  }

  useEffect(() => {
    if (searchText.length >= 2) {
      handleSearch();
    }
  }, [searchText]);

  // key combination CMD + K
  function checkKeyDown(e: KeyboardEvent) {
    if (e.key === 'k') {
      setkDown(true);
    }
    if (e.key === 'Meta') {
      setMetaDown(true);
    }
  }

  function checkKeyUp(e: KeyboardEvent) {
    if (e.key === 'k') {
      setkDown(false);
    }
    if (e.key === 'Meta') {
      setMetaDown(false);
    }
  }

  function registerKeydown() {
    document.addEventListener('keydown', checkKeyDown);
    return () => {
      document.removeEventListener('keydown', checkKeyDown);
    };
  }

  function registerKeyUp() {
    document.addEventListener('keyup', checkKeyUp);
    return () => {
      document.removeEventListener('keyup', checkKeyUp);
    };
  }

  useEffect(() => {
    registerKeyUp();
    registerKeydown();
  }, []);

  useEffect(() => {
    // attach the event listener
    if (kDown && metaDown) {
      setIsVisible(true);
    }
  }, [kDown, metaDown]);

  return (
    <Dialog
      TransitionComponent={Transition}
      classes={{
        paper: classes.dialog,
      }}
      maxWidth={'sm'}
      onClose={() => setIsVisible(false)}
      open={isVisible}
      fullWidth
    >
      <div style={{ padding: '1em' }}>
        <Autocomplete
          getOptionLabel={(option: SearchResult) => option.name}
          id={'grouped-demo'}
          onChange={(e: any, value: SearchResult | null) => {
            if (value !== null) {
              Analytics.record('search_documentation', {
                link: value.url,
                name: value.name,
              });
              window.open(value.html_url, '_blank');
            }
          }}
          options={searchResults}
          renderInput={(params) => (
            <TextField
              {...params}
              label={'Search Documentation'}
              onChange={(e: any) => setSearchText(e.target.value)}
              value={searchText}
              variant={'outlined'}
              autoFocus
              fullWidth
            />
          )}
          renderOption={(option: SearchResult) => (
            <div>
              <div>
                <strong>{option.name}</strong>
              </div>
              <div dangerouslySetInnerHTML={{ __html: option.snippet }} />
            </div>
          )}
        />
      </div>
    </Dialog>
  );
};
