enum ActionType {
  AutomaticRequestSizing = 1,
}

type ActionListItem = {
  actionType: ActionType;
  data: any;
  displayName: string;
  id: string;
  nextRun: string;
  numberOfAffectedWorkloads: number;
  scheduleFrequency: string;
};

type ActionDefinition = {
  getData: () => Promise<ActionListItem[]>;
  deleteAction: (item: ActionListItem) => {};
  name: string;
};

// Request Right Sizing Schedule Items
type RequestRightSizingScheduleItem = {
  schedule: {
    frequencyMinutes: string;
    lastModified: string | null;
    start: string;
    targetUtilizationCPU: number;
    targetUtilizationMemory: number;
  };
  workload: {
    clusterID: string;
    controllerKind: string;
    controllerName: string;
    namespace: string;
  };
};

export { ActionDefinition, ActionListItem, ActionType, RequestRightSizingScheduleItem };
