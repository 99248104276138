import { useQuery } from 'react-query';

import configs from '../../../services/appconfig';
import { SummaryQueryOpts, model } from '../../../services/model';

const useTotalCostEfficiency = (queryWindow = '7d') => {
  const queryOpts: SummaryQueryOpts = {
    accumulate: true,
    external: false,
    idleByNode: false,
    shareCost: configs.getSharedOverhead(),
    shareIdle: false,
    shareLabels: configs.getSharedLabels(),
    shareNamespaces: configs.getSharedNamespaces(),
    shareSplit: 'weighted',
    shareTenancyCosts: configs.getShareTenancyCosts(),
  };
  const queryKey = ['totalCostEfficiency', queryWindow, queryOpts];
  return useQuery(queryKey, async () => model.getAllocationSummary(queryWindow, 'all', queryOpts));
};

export { useTotalCostEfficiency };
