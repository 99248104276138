/* eslint-disable react/no-unstable-nested-components */
import { FC, useMemo, useState } from 'react';

import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { TableSortLabel } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import {
  Button,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@kubecost-frontend/holster';

import { ArrowIcon } from '../../assets/images';
import { toCurrency } from '../../services/format';

import { AutoscalingWorkloadSchedule, RequestSizingRecommendation } from './types';

interface ResultsTableProps {
  currency: string;
  currentAutoscalings: AutoscalingWorkloadSchedule[];
  onRowSelect: (Recommendation: RequestSizingRecommendation) => void;
  recommendations: RequestSizingRecommendation[];
}

const ResultsTable: FC<ResultsTableProps> = ({
  currency,
  currentAutoscalings,
  onRowSelect,
  recommendations,
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);

  const columnHelper = createColumnHelper<RequestSizingRecommendation>();

  const columns = [
    columnHelper.display({
      id: 'autosizing',
      cell: (cell) => {
        const currentAutoScaling = currentAutoscalings.find(
          (item: AutoscalingWorkloadSchedule) =>
            item.workload.clusterID === cell.row.original.clusterID &&
            item.workload.namespace === cell.row.original.namespace &&
            item.workload.controllerKind === cell.row.original.controllerKind &&
            item.workload.controllerName === cell.row.original.controllerName,
        );

        return currentAutoscalings.length && currentAutoScaling ? (
          <Tooltip content={'Auto-scaling enabled'}>
            <CheckCircleIcon htmlColor={'#63E892'} />
          </Tooltip>
        ) : (
          <Tooltip content={'Auto-scaling not enabled'}>
            <ErrorIcon htmlColor={'#607971'} />
          </Tooltip>
        );
      },
    }),
    columnHelper.accessor('clusterID', {
      header: 'Cluster',
    }),
    columnHelper.accessor((row) => `${row.namespace}\\${row.controllerKind}`, {
      header: 'Namespace\\Controller',
    }),
    columnHelper.accessor('containerName', {
      header: 'Container',
    }),
    columnHelper.accessor((row) => row.latestKnownRequest.memory, {
      header: 'RAM Requested',
    }),
    columnHelper.accessor((row) => row.recommendedRequest.memory, {
      header: 'RAM Recommended',
    }),
    columnHelper.accessor((row) => row.latestKnownRequest.cpu, {
      header: 'CPU Requested',
    }),
    columnHelper.accessor((row) => row.recommendedRequest.cpu, {
      header: 'CPU Recommended',
    }),
    columnHelper.accessor('currentEfficiency.total', {
      header: 'Current Efficiency',
      cell: (value) => (
        <Tooltip content={'Efficency over the requested window'}>
          <span>{`${(value.cell.getValue() * 100).toFixed(2)}%` || '-'}</span>
        </Tooltip>
      ),
    }),
    columnHelper.accessor((row) => row.monthlySavings.cpu + row.monthlySavings.memory, {
      header: 'Est. Savings (Monthly)',
      cell: (value) => toCurrency(value.cell.getValue(), currency),
    }),
  ];

  const data = useMemo(() => recommendations, [recommendations]);

  const table = useReactTable({
    columns,
    data,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const [numRowsMenuOpen, setNumRowsMenuOpen] = useState(false);

  // NOTES
  // So - This is a first attempt at using react-table
  // in order to fix the perf issues with rendering a million items at once.
  // So. Here goes lol. This isn't pretty, and ideally we'd push this
  // to a datataable style component in Holster.
  // -- Thomas

  return (
    <>
      <Table className={'w-full'}>
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                // Apply the header cell props
                <TableCell
                  className='cursor-pointer'
                  id={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <TableSortLabel
                    active={header.column.getIsSorted() !== false}
                    direction={header.column.getIsSorted() || 'asc'}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} onClick={() => onRowSelect(row.original)}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div className={'mt-3 flex h-6 w-full justify-between'}>
        <div className={'flex items-center'}>
          <Typography className={'mr-1'} variant={'p'}>
            Rows per page
          </Typography>
          <Button className={'p-1'} onClick={() => setNumRowsMenuOpen(true)} variant={'default'}>
            <ArrowIcon className={'inline'} direction={'LEFT'} />
            {table.getState().pagination.pageSize}
          </Button>
          {numRowsMenuOpen && (
            <Menu
              items={['10', '25', '50']}
              onClose={() => setNumRowsMenuOpen(false)}
              selectItem={(v) => {
                table.setPageSize(parseInt(v.text, 10));
                setNumRowsMenuOpen(false);
              }}
            />
          )}
        </div>
        <div className={'flex items-center justify-end'}>
          <Typography style={{ marginRight: 19 }} variant={'p'}>
            Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
          </Typography>
          <Button
            disabled={!table.getCanPreviousPage()}
            onClick={() => table.previousPage()}
            style={{ marginRight: 7 }}
            variant={'default'}
          >
            Previous
          </Button>
          <Button
            disabled={!table.getCanNextPage()}
            onClick={() => table.nextPage()}
            style={{ marginRight: 15 }}
            variant={'default'}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
};
ResultsTable.displayName = 'ResultsTable';
export { ResultsTable };
