import React from 'react';

import { NavIconProps } from './types';

const AssetsNavIcon = React.forwardRef<SVGSVGElement, NavIconProps>(({ active, ...args }, ref) => (
  <svg
    fill={'none'}
    height={'30'}
    ref={ref}
    viewBox={'0 0 30 30'}
    width={'30'}
    xmlns={'http://www.w3.org/2000/svg'}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...args}
  >
    <rect
      height={'18'}
      rx={'1'}
      stroke={active ? '#63E892' : '#607971'}
      strokeWidth={'2'}
      width={'18'}
      x={'6'}
      y={'6'}
    />
    <rect fill={active ? '#63E892' : '#607971'} height={'5'} width={'5'} x={'9'} y={'9'} />
    <rect fill={active ? '#63E892' : '#607971'} height={'5'} width={'5'} x={'9'} y={'16'} />
    <rect fill={active ? '#63E892' : '#607971'} height={'5'} width={'5'} x={'16'} y={'9'} />
    <rect fill={active ? '#63E892' : '#607971'} height={'5'} width={'5'} x={'16'} y={'16'} />
  </svg>
));

AssetsNavIcon.displayName = 'AssetsNavIcon';
export { AssetsNavIcon };
