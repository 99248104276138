import { FC } from 'react';

import { Typography } from '@kubecost-frontend/holster';

import { QuestionIcon } from '../assets/images/question-icon';
import { RefreshIcon } from '../assets/images/refresh-icon';
import { analytics as Analytics } from '../services/analytics';

import { DiagnosticsChecker } from './DiagnosticsCheckerNew';

interface ComponentProps {
  helpHref?: string;
  helpTooltip?: string;
  refreshCallback?: () => void;
  title: React.ReactNode;
}

const Header: FC<ComponentProps> = ({ helpHref, helpTooltip, refreshCallback, title }) => (
  <div className={'mb-[40px] flex'}>
    <Typography className={'font-bold'} variant={'h4'}>
      {title}
    </Typography>
    <div className={'flex grow items-center justify-end'}>
      {refreshCallback && (
        <RefreshIcon
          className={'mr-3 cursor-pointer'}
          height={24}
          onClick={refreshCallback}
          width={24}
        />
      )}
      <DiagnosticsChecker />
      {helpHref && helpTooltip && (
        <a
          className={'ml-3'}
          href={helpHref}
          onClick={() => Analytics.record('documentation_visited', { url: helpHref })}
          target={'_blank'}
          title={helpTooltip}
        >
          <QuestionIcon height={24} width={24} />
        </a>
      )}
    </div>
  </div>
);

Header.defaultProps = {
  helpHref: '',
  helpTooltip: '',
  refreshCallback: undefined,
};

Header.displayName = 'Header';

export { Header };
