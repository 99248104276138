import { FC } from 'react';

import { useNavigate } from 'react-router';

import { Modal } from '@kubecost-frontend/holster';

import { AssetFilter } from '../../types/asset';

import { EditoControlMemoized } from './AssetControls';

interface CustomizeReportModalProps {
  accumulate: any;
  accumulateOptions: any;
  closeModal: () => void;
  filters: any;
  open: boolean;
  rate: any;
}

export const CustomizeReportModal: FC<CustomizeReportModalProps> = ({
  accumulate,
  accumulateOptions,
  closeModal,
  filters,
  open,
  rate,
}) => {
  const searchParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  const handleSetFilter = (nextFilter: AssetFilter[]) => {
    const fltr = btoa(JSON.stringify(nextFilter));
    searchParams.set('filters', fltr);
    navigate({
      search: `?${searchParams.toString()}`,
    });
  };

  return (
    <Modal onClose={closeModal} open={open} title={'Edit Report'}>
      <EditoControlMemoized
        accumulate={accumulate}
        accumulateOptions={accumulateOptions}
        filters={filters}
        onClose={closeModal}
        rate={rate}
        setAccumulate={(acc: any) => {
          searchParams.set('acc', String(acc));
          navigate({
            search: `?${searchParams.toString()}`,
          });
        }}
        setFilters={handleSetFilter}
        setRate={(r: string) => {
          searchParams.set('rate', r);
          navigate({
            search: `?${searchParams.toString()}`,
          });
        }}
      />
    </Modal>
  );
};
