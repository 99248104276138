import { MouseEvent, useState } from 'react';

import { Menu, Tooltip, Modal } from '@kubecost-frontend/holster';

import { AdvancedReport } from '../../types/advancedReport';
import { AllocationReport } from '../../types/allocation';
import { AssetReport } from '../../types/asset';

import {ReportExport} from './ReportExport';

type ReportActionMenuProps = {
  deleteClicked: () => void;
  report: AllocationReport | AssetReport | AdvancedReport;
  readonly?: boolean;
};

const ReportActionMenu = ({ deleteClicked, report, readonly = false }: ReportActionMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [exportOpen, setExportOpen] = useState<boolean>(false);

  const open = Boolean(anchorEl);

  const handleOnClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnClose = () => {
    setAnchorEl(null);
  };

  const handleMenuSelect = ({text}: {text: string}) => {
    if (text === 'Delete') {
      deleteClicked();
    } else if (text === 'Export YAML') {
      setExportOpen(true);
    }
  };

  const handleExportClose = () => setExportOpen(false);

  if (readonly) {
    return (
      <Tooltip
        content={'Only admin or editor users may delete reports'}
        style={{ width: 200, left: -100 }}
      >
        <button
          aria-controls={open ? 'actions-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup={'true'}
          data-testid={'menu-button'}
          id={'action-positioned-button'}
          style={{ cursor: 'default' }}
          type={'button'}
          disabled
        >
          ...
        </button>
      </Tooltip>
    );
  }

  return (
    <>
      <div style={{ position: 'relative' }}>
        <button
          aria-controls={open ? 'actions-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup={'true'}
          data-testid={'menu-button'}
          id={'action-positioned-button'}
          onClick={handleOnClick}
          style={{ cursor: 'pointer' }}
          type={'button'}
        >
          ...
        </button>
        {open && (
          <Menu items={[{ text: 'Delete' }, {text: 'Export YAML'}]} onClose={handleOnClose} selectItem={handleMenuSelect} />
        )}
      </div>
      {!exportOpen ? null : (
        <Modal title="Export YAML" open onClose={handleExportClose}>
          <ReportExport report={report} />
        </Modal>
      )}
    </>
  );
};

export { ReportActionMenu };
