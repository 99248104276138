import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import FormGroup from '@material-ui/core/FormGroup';
import Snackbar from '@material-ui/core/Snackbar';
import AlertComponent from '@material-ui/lab/Alert';

import {
  Alert,
  Button,
  FormControlLabel,
  Input,
  Modal,
  Select,
  Typography,
} from '@kubecost-frontend/holster';

import { SelectWindowMemoized as SelectWindow } from '../../components/SelectWindow';
import {
  Alert as AlertModel,
  AlertService,
  AlertTypes,
  AssetBudget,
  AssetRecurring,
  BudgetAlert,
  EfficiencyAlert,
  RecurringUpdate,
  SpendChangeAlert,
} from '../../services/alerts';
import Logger from '../../services/logger';

import {
  EfficiencyThresholdInput,
  EmailRecipientsInput,
  EmailSubjectInput,
  MSTeamsInput,
  MaximumCostThresholdInput,
  MinimumCostThresholdInput,
  RelativeChangeThresholdInput,
  SlackInput,
} from './AlertInputFields';
import { TestResultItem, TestResults } from './TestStatus';
import {
  WEBHOOKS,
  allocationAggregationOptions,
  assetAggregationOptions,
  windowOptions,
} from './types';
import { getAlertTypeText, validate } from './utils';

interface Alert {
  aggregation: string;
  baselineWindow?: string;
  efficiencyThreshold?: number;
  emailSubject?: string;
  filter: string;
  id?: string;
  msTeamsWebhookUrl?: string;
  ownerContact?: string[];
  relativeThreshold?: number;
  slackWebhookUrl?: string;
  spendThreshold?: number;
  threshold?: number;
  type: string;
  window: string;
}

interface FieldsForTypeProps {
  alertType: string;
  baselineWindow: string;
  efficiencyThreshold: string;
  relativeChangeThreshold: string;
  setBaselineWindow: Dispatch<SetStateAction<string>>;
  setEfficiencyThreshold: Dispatch<SetStateAction<string>>;
  setRelativeChangeThreshold: Dispatch<SetStateAction<string>>;
  setSpendThreshold: Dispatch<SetStateAction<string>>;
  setThreshold: Dispatch<SetStateAction<string>>;
  spendThreshold: string;
  threshold: string;
}

const FieldsForType = ({
  alertType,
  baselineWindow,
  efficiencyThreshold,
  relativeChangeThreshold,
  setBaselineWindow,
  setEfficiencyThreshold,
  setRelativeChangeThreshold,
  setSpendThreshold,
  setThreshold,
  spendThreshold,
  threshold,
}: FieldsForTypeProps) => {
  if (alertType === 'budget' || alertType === 'assetBudget') {
    return (
      <MaximumCostThresholdInput
        formGroupClassName={'my-5'}
        handleOnChange={(e) => setThreshold(e.currentTarget.value)}
        value={threshold}
      />
    );
  }
  if (alertType === 'efficiency') {
    return (
      <>
        <EfficiencyThresholdInput
          formGroupClassName={'my-5'}
          handleOnChange={(e) => setEfficiencyThreshold(e.currentTarget.value)}
          value={efficiencyThreshold}
        />
        <MinimumCostThresholdInput
          formGroupClassName={'my-5'}
          handleOnChange={(e) => setSpendThreshold(e.currentTarget.value)}
          value={spendThreshold}
        />
      </>
    );
  }
  if (alertType === 'spendChange') {
    return (
      <>
        <SelectWindow
          helperText={
            'Collect data N days prior to the queried items, in order to establish a cost baseline'
          }
          setWindow={setBaselineWindow}
          window={baselineWindow}
          windowOptions={windowOptions}
        />
        <RelativeChangeThresholdInput
          formGroupClassName={'my-5'}
          handleOnChange={(e) => setRelativeChangeThreshold(e.currentTarget.value)}
          value={relativeChangeThreshold}
        />
      </>
    );
  }
  return null;
};

const DEFAULTS = {
  alertId: '',
  type: AlertTypes.Budget,
  alertWindow: '7d',
  aggregation: 'namespace',
  aggLabel: '',
  alertFilter: '',
  threshold: '',
  efficiencyThreshold: '',
  spendThreshold: '',
  baselineWindow: '7d',
  relativeChangeThreshold: '',
  alertEmailRecipients: [],
  emailSubject: '',
  alertSlackHook: '',
  alertSlackHookDirty: false,
  alertMSTeamsHook: '',
  alertMSTeamsHookDirty: false,
  formError: '',
  emailRecipientText: '',
  aggregationOptions: allocationAggregationOptions,
};

interface ComponentProps {
  alert: Alert | null;
  close: () => void;
  save: (alert: AlertModel) => Promise<void>;
}

const CreateAlertModal = ({ alert: initialAlert, close, save }: ComponentProps): JSX.Element => {
  const [alertId, setAlertId] = useState(DEFAULTS.alertId);
  const [type, setType] = useState<AlertTypes>(DEFAULTS.type);
  const [alertWindow, setAlertWindow] = useState(DEFAULTS.alertWindow);
  const [aggregation, setAggregation] = useState(DEFAULTS.aggregation);
  const [aggLabel, setAggLabel] = useState(DEFAULTS.aggLabel);
  const [alertFilter, setAlertFilter] = useState(DEFAULTS.alertFilter);
  const [threshold, setThreshold] = useState(DEFAULTS.threshold);
  const [efficiencyThreshold, setEfficiencyThreshold] = useState(DEFAULTS.efficiencyThreshold);
  const [spendThreshold, setSpendThreshold] = useState(DEFAULTS.spendThreshold);
  const [baselineWindow, setBaselineWindow] = useState(DEFAULTS.baselineWindow);
  const [relativeChangeThreshold, setRelativeChangeThreshold] = useState(
    DEFAULTS.relativeChangeThreshold,
  );
  const [alertEmailRecipients, setAlertEmailRecipients] = useState<string[]>(
    DEFAULTS.alertEmailRecipients,
  );
  const [emailSubject, setEmailSubject] = useState(DEFAULTS.emailSubject);
  const [alertSlackHook, setAlertSlackHook] = useState(DEFAULTS.alertSlackHook);
  const [alertSlackHookDirty, setAlertSlackHookDirty] = useState(DEFAULTS.alertSlackHookDirty);
  const [alertMSTeamsHook, setAlertMSTeamsHook] = useState(DEFAULTS.alertMSTeamsHook);
  const [alertMSTeamsHookDirty, setAlertMSTeamsHookDirty] = useState(
    DEFAULTS.alertMSTeamsHookDirty,
  );
  const [formError, setFormError] = useState(DEFAULTS.formError);
  const [emailRecipientText, setEmailRecipientText] = useState(DEFAULTS.emailRecipientText);
  const [aggregationOptions, setAggregationOptions] = useState(DEFAULTS.aggregationOptions);
  const [testStatus, setTestStatus] = useState<TestResults | null>(null);

  const handleOnClose = () => {
    setAlertId(DEFAULTS.alertId);
    setType(DEFAULTS.type);
    setAlertWindow(DEFAULTS.alertWindow);
    setAggregation(DEFAULTS.aggregation);
    setAggLabel(DEFAULTS.aggLabel);
    setAlertFilter(DEFAULTS.alertFilter);
    setThreshold(DEFAULTS.threshold);
    setEfficiencyThreshold(DEFAULTS.efficiencyThreshold);
    setSpendThreshold(DEFAULTS.spendThreshold);
    setBaselineWindow(DEFAULTS.baselineWindow);
    setRelativeChangeThreshold(DEFAULTS.relativeChangeThreshold);
    setAlertEmailRecipients(DEFAULTS.alertEmailRecipients);
    setEmailSubject(DEFAULTS.emailSubject);
    setAlertSlackHook(DEFAULTS.alertSlackHook);
    setAlertSlackHookDirty(DEFAULTS.alertSlackHookDirty);
    setAlertMSTeamsHook(DEFAULTS.alertMSTeamsHook);
    setAlertMSTeamsHookDirty(DEFAULTS.alertMSTeamsHookDirty);
    setFormError(DEFAULTS.formError);
    setEmailRecipientText(DEFAULTS.emailRecipientText);
    setAggregationOptions(DEFAULTS.aggregationOptions);
    close();
  };

  const test = async (a: Alert) => {
    setTestStatus(null);
    try {
      const response = await AlertService.testAlert(a);
      const json = await response.json();
      setTestStatus(json as TestResults);

    } catch (err) {
      setFormError(
        'Uh oh, looks like the server had an error building your results. Check your logs.',
      );
    }
  };

  const handleDataSubmission =
    (executionFunction: (alert: AlertModel) => Promise<void>, closeWhenDone = false) =>
    () => {
      setFormError('');
      try {
        validate(
          aggregationOptions,
          aggregation,
          type,
          threshold,
          alertWindow,
          efficiencyThreshold,
          spendThreshold,
          baselineWindow,
          relativeChangeThreshold,
        );
      } catch (err) {
        if (err instanceof Error) {
          setFormError(err.message);
        } else {
          Logger.error(err);
        }
        return;
      }
      let recipients = [...alertEmailRecipients];
      if (emailRecipientText && !recipients.includes(emailRecipientText)) {
        recipients = [...recipients, emailRecipientText];
        setAlertEmailRecipients(recipients);
        setEmailRecipientText('');
      }
      const aType = type as AlertTypes;
      const agg = aggregation === 'label' ? `label:${aggLabel}` : aggregation;
      const slackWebhookUrl = alertSlackHookDirty
        ? alertSlackHook
        : initialAlert?.slackWebhookUrl || '';
      const msTeamsWebhookUrl = alertMSTeamsHookDirty
        ? alertMSTeamsHook
        : initialAlert?.msTeamsWebhookUrl || '';
      const alert = {
        type: aType,
        aggregation: agg,
        window: alertWindow,
        filter: alertFilter,
        id: alertId,
        threshold: parseFloat(threshold),
        efficiencyThreshold: parseFloat(efficiencyThreshold) / 100,
        spendThreshold: parseFloat(spendThreshold),
        baselineWindow,
        relativeThreshold: parseFloat(relativeChangeThreshold) / 100,
        ownerContact: recipients,
        slackWebhookUrl,
        msTeamsWebhookUrl,
        emailSubject,
      };
      if (aType === AlertTypes.Budget) {
        executionFunction(new BudgetAlert(alert));
      } else if (aType === AlertTypes.Efficiency) {
        executionFunction(new EfficiencyAlert(alert));
      } else if (aType === AlertTypes.Recurring) {
        executionFunction(new RecurringUpdate(alert));
      } else if (aType === AlertTypes.SpendChange) {
        executionFunction(new SpendChangeAlert(alert));
      } else if (aType === AlertTypes.AssetBudget) {
        executionFunction(new AssetBudget(alert));
      } else if (aType === AlertTypes.AssetRecurring) {
        executionFunction(new AssetRecurring(alert));
      }

      if (closeWhenDone) handleOnClose();
    };
  const handleOnTest = handleDataSubmission(test, false);
  const handleOnSave = handleDataSubmission(save, true);

  const getFilterHelperText = () => {
    const aggOpt = aggregationOptions.find((opt) => opt.value === aggregation);
    if (!aggOpt) {
      return 'Filter to a specific item within the chosen aggregation';
    }
    return `Filter to a specific ${aggOpt.name}`;
  };

  useEffect(() => {
    if (initialAlert) {
      setType(initialAlert.type as AlertTypes);
      setAlertWindow(initialAlert.window);
      if (initialAlert.aggregation.startsWith('label:')) {
        setAggregation('label');
        setAggLabel(initialAlert.aggregation.slice(6));
      } else {
        setAggregation(initialAlert.aggregation);
      }
      if (initialAlert.filter) {
        setAlertFilter(initialAlert.filter);
      }
      if (initialAlert.threshold) {
        setThreshold(initialAlert.threshold.toString());
      }
      if (initialAlert.efficiencyThreshold) {
        setEfficiencyThreshold((initialAlert.efficiencyThreshold * 100).toString());
      }
      if (initialAlert.spendThreshold) {
        setSpendThreshold(initialAlert.spendThreshold.toString());
      }
      if (initialAlert.baselineWindow) {
        setBaselineWindow(initialAlert.baselineWindow);
      }
      if (initialAlert.relativeThreshold) {
        setRelativeChangeThreshold((initialAlert.relativeThreshold * 100).toString());
      }
      if (initialAlert.ownerContact) {
        setAlertEmailRecipients(initialAlert.ownerContact);
      }
      if (initialAlert.emailSubject) {
        setEmailSubject(initialAlert.emailSubject);
      }
      if (initialAlert.slackWebhookUrl) {
        setAlertSlackHook(WEBHOOKS.SLACK.PLACEHOLDER);
      }
      if (initialAlert.msTeamsWebhookUrl) {
        setAlertMSTeamsHook(WEBHOOKS.MS_TEAMS.PLACEHOLDER);
      }
      if (initialAlert.id) {
        setAlertId(initialAlert.id);
      }
    }
  }, [initialAlert]);

  useEffect(() => {
    if (type.startsWith('asset') || type.startsWith('cloud')) {
      if (aggregationOptions !== assetAggregationOptions) {
        setAggregation('service');
      }
      setAggregationOptions(assetAggregationOptions);
    } else {
      if (aggregationOptions !== allocationAggregationOptions) {
        setAggregation('namespace');
      }
      setAggregationOptions(allocationAggregationOptions);
    }
  }, [type, aggregationOptions]);

  return (
    <Modal
      data-test={'create-alert-dialog'}
      onClose={handleOnClose}
      open={!!initialAlert}
      title={alertId ? 'Edit Alert' : 'Create Alert'}
    >
      {/* Alert Type */}
      <div className={'my-6'}>
        <FormControlLabel>Alert Type</FormControlLabel>
        <Select
          data-test={'type-select'}
          options={[
            { label: 'Allocation Budget', value: AlertTypes.Budget },
            { label: 'Allocation Efficiency', value: AlertTypes.Efficiency },
            {
              label: 'Allocation Recurring Update',
              value: AlertTypes.Recurring,
            },
            {
              label: 'Allocation Spend Change',
              value: AlertTypes.SpendChange,
            },
            { label: 'Asset Budget', value: AlertTypes.AssetBudget },
            { label: 'Cloud Report', value: AlertTypes.AssetRecurring },
          ]}
          setValue={(alertType: string) => setType(alertType as AlertTypes)}
          value={type}
        />
        <Typography variant={'h6'}>{getAlertTypeText(type)}</Typography>
      </div>

      <div className={'items-top flex justify-between'}>
        {/* Alert Window */}
        <div>
          <FormControlLabel>Window</FormControlLabel>
          <Select options={windowOptions} setValue={setAlertWindow} value={alertWindow} />
          <Typography variant={'h6'}>The date range over which to query items</Typography>
        </div>

        {/* Alert Aggregation */}
        <div>
          <FormControlLabel>Aggregation</FormControlLabel>
          <Select
            options={aggregationOptions.map((opt) => ({
              label: opt.name,
              value: opt.value,
            }))}
            setValue={(agg) => {
              setAggLabel(DEFAULTS.aggLabel);
              setAggregation(agg);
            }}
            value={aggregation}
          />
          <Typography variant={'h6'}>{`Type of ${
            aggregationOptions === assetAggregationOptions ? 'cloud asset' : 'Kubernetes object'
          } to consider`}</Typography>
        </div>

        {/* An input for Label, if that aggregation is selected */}
        {aggregation === 'label' && (
          <Input
            className={'ml-3'}
            helperText={'The label to aggregate by'}
            label={'Label'}
            onChange={(e) => setAggLabel(e.target.value)}
            placeholder={'app:kubecost'}
            value={aggLabel}
          />
        )}
      </div>

      {/* Alert Filter */}
      <FormGroup className={'my-5'}>
        <Input
          helperText={getFilterHelperText()}
          label={'Filter'}
          onChange={(e) => setAlertFilter(e.target.value)}
          value={alertFilter}
        />
      </FormGroup>

      {/* Content depends on the type of Alert we are creating */}
      <FieldsForType
        alertType={type}
        baselineWindow={baselineWindow}
        efficiencyThreshold={efficiencyThreshold}
        relativeChangeThreshold={relativeChangeThreshold}
        setBaselineWindow={setBaselineWindow}
        setEfficiencyThreshold={setEfficiencyThreshold}
        setRelativeChangeThreshold={setRelativeChangeThreshold}
        setSpendThreshold={setSpendThreshold}
        setThreshold={setThreshold}
        spendThreshold={spendThreshold}
        threshold={threshold}
      />

      {/* Finally, optional settings for recipient channels */}
      <Typography variant={'h6'}>Recipients</Typography>

      <SlackInput
        formGroupClassName={'mt-2 mb-4 w-full'}
        handleOnChange={(e) => {
          setAlertSlackHookDirty(true);
          setAlertSlackHook(e.currentTarget.value);
        }}
        helperText={
          alertId
            ? 'Slack webhook for this alert (optional). Obfuscated for security purposes.'
            : 'Slack webhook for this alert (optional).'
        }
        label={'Slack webhook'}
        value={alertSlackHook}
      />

      <MSTeamsInput
        formGroupClassName={'mt-2 mb-4 w-full'}
        handleOnChange={(e) => {
          setAlertMSTeamsHookDirty(true);
          setAlertMSTeamsHook(e.currentTarget.value);
        }}
        helperText={
          alertId
            ? 'Microsoft Teams webhook for this alert (optional). Obfuscated for security purposes.'
            : 'Microsoft Teams webhook for this alert (optional).'
        }
        label={'Microsoft Teams webhook'}
        value={alertMSTeamsHook}
      />

      <EmailSubjectInput
        handleOnChange={(e) => {
          setEmailSubject(e.currentTarget.value);
        }}
        helperText={'Custom email subject line (optional).'}
        value={emailSubject}
      />

      <EmailRecipientsInput
        addItem={(item: string) => {
          if (!alertEmailRecipients.includes(item)) {
            setAlertEmailRecipients((recp) => [...recp, item]);
            setEmailRecipientText('');
          }
        }}
        handleOnChange={(e) => setEmailRecipientText(e.currentTarget.value)}
        recipients={alertEmailRecipients}
        removeItem={(item: number) => {
          setAlertEmailRecipients((recp) => recp.slice(0, item).concat(recp.slice(item + 1)));
        }}
        value={emailRecipientText}
      />
      <div>
        <div className={'mt-4 flex flex-col gap-4 overflow-hidden'}>
          {testStatus && (
            <>
              <TestResultItem testStatus={testStatus.slack} title={'Slack'} />
              <TestResultItem testStatus={testStatus.ms_teams} title={'MS Teams'} />
              <TestResultItem testStatus={testStatus.email} title={'E-Mail'} />
            </>
          )}
        </div>
        <Button className={'mt-4'} onClick={handleOnTest} variant={'primary'}>
          Test Alert
        </Button>
        <Typography className={'text-xs my-2'} variant={'p'}>
          Test out alert configuration by sending a test message to all recipients.
        </Typography>
        <Typography variant={'h6'}>
          Testing this alert will also test your global recipient settings for this alert.
        </Typography>
      </div>

      <div className={'flex justify-end'}>
        <Button className={'mr-4'} onClick={handleOnClose} variant={'primary'}>
          Cancel
        </Button>
        <Button data-test={'save-button'} onClick={handleOnSave} variant={'primary'}>
          Save
        </Button>
      </div>
      <Snackbar autoHideDuration={4000} onClose={() => setFormError('')} open={Boolean(formError)}>
        <AlertComponent severity={'error'}>{formError}</AlertComponent>
      </Snackbar>
    </Modal>
  );
};

export { CreateAlertModal };
