import { FC } from 'react';

import { CircularProgress } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import { Chip, Tooltip as HolsterTooltip, TableCell, TableRow } from '@kubecost-frontend/holster';
import { ChipColor } from '@kubecost-frontend/holster/dist/components/Chip';

import { useClusters } from '../../../contexts/ClusterConfig';
import { toCurrency } from '../../../services/format';

/**
 * How to interpret `value` in this context:
 * - If `value` is null, the data is still loading
 * - If `value` is false, either there was an error or no relevant data
 * - If `value` is NaN, there was an error
 *
 * The color of the chip will be 'default' for any loading state, error state,
 * empty state, or when the trend is unchanged.
 *
 * The color of the chip will be 'green' when the trend is decreasing (this
 * reflects a reduction in cost) and 'red' when the trend is increasing (this
 * reflect an increase in cost).
 */
const TrendChip = ({ value }: { value: boolean | number | null }) => {
  if (value === null) {
    return (
      <Chip
        color={'default'}
        label={
          <div className={'flex'}>
            {'XX.X% '}
            <CircularProgress className={'mt-0.5 ml-1'} size={10} />
          </div>
        }
      />
    );
  }

  if (typeof value !== 'number' || Number.isNaN(value)) {
    return (
      <HolsterTooltip content={'There is no data for the time period selected'}>
        <Chip color={'default'} label={'N/A'} />
      </HolsterTooltip>
    );
  }

  let color: ChipColor = 'default';
  if (value !== 0) {
    color = value > 0 ? 'danger' : 'success';
  }
  const prefix = value > 0 ? '+' : '';
  return (
    <HolsterTooltip
      content={'Comparing the selected date range to the equivalent number of days prior.'}
    >
      <Chip color={color} label={`${prefix}${Math.round((value || 0) * 1000) / 10}%`} />
    </HolsterTooltip>
  );
};

interface ComponentProps {
  canDrillDown: boolean;
  costSuffix: string;
  cpuCost: number;
  cpuRequest: number;
  efficiency: number;
  gpuCost: number;
  handleOnClick: () => void;
  isIdle: boolean;
  isUnmounted: boolean;
  loadBalancerCost: number;
  name: string | JSX.Element;
  networkCost: number;
  pvCost: number;
  ramCost: number;
  ramRequest: number;
  sharedCost: number;
  totalCost: number;
  trendData: number | null | false;
}

const AllocationReportRow: FC<ComponentProps> = ({
  canDrillDown,
  costSuffix,
  cpuCost,
  cpuRequest,
  efficiency,
  gpuCost,
  handleOnClick,
  isIdle,
  isUnmounted,
  loadBalancerCost,
  name,
  networkCost,
  pvCost,
  ramCost,
  ramRequest,
  sharedCost,
  totalCost,
  trendData,
}: ComponentProps) => {
  const { modelConfig } = useClusters();
  const tooltipText = canDrillDown ? '' : 'Cannot drill down';
  const showEfficiency = !(isIdle || isUnmounted);
  let shownEfficiency;
  if (!showEfficiency) {
    shownEfficiency = <>&mdash;</>;
  } else if (efficiency === 1 && !(cpuRequest || ramRequest)) {
    shownEfficiency = 'Inf';
  } else {
    shownEfficiency = `${(efficiency * 100).toFixed(1)}%`;
  }

  return (
    <Tooltip title={tooltipText}>
      <TableRow onClick={handleOnClick} style={{ cursor: canDrillDown ? 'pointer' : 'auto' }}>
        {/* row name */}
        <TableCell align={'left'}>{name}</TableCell>

        {/* cpu cost */}
        <TableCell align={'right'}>
          {`${toCurrency(cpuCost, modelConfig.currencyCode)}${costSuffix}`}
        </TableCell>

        {/* gpu cost */}
        <TableCell align={'right'}>
          {`${toCurrency(gpuCost, modelConfig.currencyCode)}${costSuffix}`}
        </TableCell>

        {/* ram cost */}
        <TableCell align={'right'}>
          {`${toCurrency(ramCost, modelConfig.currencyCode)}${costSuffix}`}
        </TableCell>

        {/* pv cost */}
        <TableCell align={'right'}>
          {`${toCurrency(pvCost, modelConfig.currencyCode)}${costSuffix}`}
        </TableCell>

        {/* network cost */}
        <TableCell align={'right'}>
          {`${toCurrency(networkCost, modelConfig.currencyCode)}${costSuffix}`}
        </TableCell>

        {/* load balancer cost */}
        <TableCell align={'right'}>
          {`${toCurrency(loadBalancerCost, modelConfig.currencyCode)}${costSuffix}`}
        </TableCell>

        {/* shared  cost */}
        <TableCell align={'right'}>
          {`${toCurrency(sharedCost, modelConfig.currencyCode)}${costSuffix}`}
        </TableCell>

        {/* efficiency */}
        <TableCell align={'left'}>{shownEfficiency}</TableCell>

        {/* total cost */}
        <TableCell align={'right'}>
          {`${toCurrency(totalCost, modelConfig.currencyCode)}${costSuffix}`}
        </TableCell>
        <TableCell>
          <TrendChip value={trendData} />
        </TableCell>
      </TableRow>
    </Tooltip>
  );
};
AllocationReportRow.displayName = 'AllocationReportRow';
export { AllocationReportRow };
