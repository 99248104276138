/* eslint-disable no-underscore-dangle */
import { FC, useCallback, useEffect, useState } from 'react';

import { Grid, Tooltip } from '@material-ui/core';

import { Header } from '../../components/Header2New';
import { FetchStates } from '../../constants';
import { useClusters } from '../../contexts/ClusterConfig';
import { toCurrency } from '../../services/format';
import { KubecostResponse } from '../../services/kubecost';
import logger from '../../services/logger';
import { model } from '../../services/model';
import { AllocationSummaryResponse } from '../../types/allocation';

import {
  Api,
  ClusterTotals,
  NamespaceTotals,
  NetworkTotals,
  ProviderAccount,
  ProviderService,
} from './api';
import { EfficiencyGraphCard } from './EfficiencyGraphCard';
import { GraphCard } from './GraphCard';
import { HighlightCard } from './HighlightCard';
import { HighlightCardTooltip } from './HighlightCardTooltip';
import { useTotalCostEfficiency } from './hooks/useTotalCostEfficiency';
import { SectionBreak } from './SectionBreak';
import { TableCard } from './TableCard';

const defaultNumDays = 7;
const server = new Api();

const Overview: FC = () => {
  const {
    modelConfig: { currencyCode },
  } = useClusters();
  const [costsLoading, setCostsLoading] = useState(FetchStates.LOADING);
  const [assetFetchState, setAssetFetchState] = useState(FetchStates.LOADING);
  const [savingsFetchState, setSavingsFetchState] = useState(FetchStates.LOADING);

  const [k8sCosts, setK8sCosts] = useState(0);
  const [k8sCostTrend, setK8sCostTrend] = useState(0);
  const [clusterCount, setClusterCount] = useState(0);
  const [cloudCosts, setCloudCosts] = useState(0);
  const [usedStorage, setUsedStorage] = useState(0);
  const [totalStorage, setTotalStorage] = useState(0);
  const [providerCount, setProviderCount] = useState(0);
  const [clusters, setClusters] = useState<ClusterTotals[]>([]);
  const [namespaces, setNamespaces] = useState<NamespaceTotals[]>([]);
  const [cloudServices, setCloudServices] = useState<ProviderService[]>([]);
  const [providers, setProviders] = useState<ProviderAccount[]>([]);
  const [allNamespaces, setAllNamespaces] = useState<string[]>([]);
  const [networkTotals, setNetworkTotals] = useState<NetworkTotals[]>([]);
  const [allClusters, setAllClusters] = useState<string[]>([]);
  const [allSavings, setAllSavings] = useState(0);
  const [namespaceDailyCostData, setNamespaceDailyCostData] = useState<
    Record<string, number | string>[]
  >([]);
  const [clusterDailyCostData, setClusterDailyCostData] = useState<
    Record<string, number | string>[]
  >([]);

  // we compare trends on 7d windows starting 3 and 10 days ago, so that we're sure both windows have been fully reconciled.
  const trendWindows = model.relativeToAbsoluteWindows('17d').reverse();
  const oldWindow = `${trendWindows[0].split(',')[0]},${trendWindows[6].split(',')[1]}`;
  const newWindow = `${trendWindows[7].split(',')[0]},${trendWindows[13].split(',')[1]}`;

  const getTotalEfficiency = (
    effCardData: KubecostResponse<AllocationSummaryResponse> | undefined,
  ) => {
    if (!effCardData) return 0;
    const __idle__ = effCardData.data.sets[0].allocations.__idle__;
    const all = effCardData.data.sets[0].allocations[''];
    const usedCpuCost = all.cpuCost * model.getSummaryCpuEfficiency(all);
    const usedRamCost = all.ramCost * model.getSummaryRamEfficiency(all);
    return (
      (100 * (usedCpuCost + usedRamCost)) /
      (all.cpuCost + all.ramCost + __idle__.cpuCost + __idle__.ramCost)
    );
  };

  const {
    data: effCardData,
    isError: effCardError,
    isLoading: effCardLoading,
  } = useTotalCostEfficiency();
  const {
    data: oldEffCardData,
    isError: oldEffCardError,
    isLoading: oldEffCardLoading,
  } = useTotalCostEfficiency(oldWindow);
  const {
    data: newEffCardData,
    isError: newEffCardError,
    isLoading: newEffCardLoading,
  } = useTotalCostEfficiency(newWindow);

  const totalCostEfficiency = getTotalEfficiency(effCardData);
  let effTrendText = 'NaN';
  let effTrend;
  try {
    const oldCostEff = getTotalEfficiency(oldEffCardData);
    const newCostEff = getTotalEfficiency(newEffCardData);
    effTrend = (100 * (newCostEff - oldCostEff)) / oldCostEff || 0;
    effTrendText = effTrend.toFixed(2);
  } catch (err) {
    effTrend = undefined;
    effTrendText = 'NaN';
  }

  const refreshCallback = useCallback(() => {
    setCostsLoading(FetchStates.LOADING);
    setAssetFetchState(FetchStates.LOADING);
    setSavingsFetchState(FetchStates.LOADING);
  }, []);

  // fetch allocations
  useEffect(() => {
    let costCounter = 0;
    if (costsLoading !== FetchStates.LOADING) {
      return;
    }
    server.initAllocations(defaultNumDays, () => {
      costCounter += 1;
      if (costCounter === 16) {
        const diff = (server.kubernetesCostsNew / server.kubernetesCostsOld) * 100 - 100;
        setK8sCostTrend(diff);
        setAllNamespaces(Array.from(server.allNamespaces));
        setAllClusters(Array.from(server.allClusters));
        setK8sCosts(server.kubernetesCosts);
        setAllClusters(Array.from(server.allClusters));
        setAllNamespaces(Array.from(server.allNamespaces));
        setClusters(Object.values(server.clusterTotals).sort((a, b) => (a.cost > b.cost ? -1 : 1)));
        setNamespaces(
          Object.values(server.namespaceTotals).sort((a, b) => (a.cost > b.cost ? -1 : 1)),
        );
        setNetworkTotals(
          Object.values(server.namespaceNetworkCosts)
            .filter((a) => a.cost > 0.01)
            .sort((a, b) => (a.cost > b.cost ? -1 : 1)),
        );
        setClusterDailyCostData(server.clusterDailyCosts);
        setNamespaceDailyCostData(server.namespaceDailyCosts);
        setCostsLoading(FetchStates.DONE);
      }
    });
  }, [costsLoading]);

  // fetch assets
  useEffect(() => {
    if (assetFetchState !== FetchStates.LOADING) {
      return;
    }
    server
      .initAssets(defaultNumDays)
      .then(() => {
        setCloudCosts(server.cloudCosts);
        setClusterCount(server.clusterCount);
        setProviders(server.providers);
        setProviderCount(server.providerCount);
        setCloudServices(server.cloudServices);
        setUsedStorage(server.usedStorageCosts);
        setTotalStorage(server.totalStorageCosts);
        setAssetFetchState(FetchStates.DONE);
      })
      .catch((err) => {
        logger.error(err);
        setAssetFetchState(FetchStates.ERROR);
      });
  }, [assetFetchState]);

  // fetch savings data
  useEffect(() => {
    if (savingsFetchState !== FetchStates.LOADING) {
      return;
    }

    server.initSavings(
      () => {
        setAllSavings(server.totalSavings);
        setSavingsFetchState(FetchStates.DONE);
      },
      (err) => {
        logger.error(err);
        setSavingsFetchState(FetchStates.ERROR);
      },
    );
  }, [savingsFetchState]);

  // check for network daemonset
  useEffect(() => {
    server.networkCheck();
  }, []);

  const clusterTableRows = clusters.map((c) => ({
    ...c,
    totalCost: toCurrency(c.cost, currencyCode, 2, false),
  }));
  const accountTableRows = providers.map((provider) => ({
    ...provider,
    totalCost: toCurrency(provider.totalCost, currencyCode, 2, false),
    provider: provider.provider,
  }));
  const cloudTableRows = cloudServices.map((cs) => ({
    ...cs,
    totalCost: toCurrency(cs.totalCost, currencyCode, 2, false),
  }));

  return (
    <>
      <Header
        refreshCallback={refreshCallback}
        title={
          <>
            Overview <div style={{ fontSize: '0.5em' }}>Last {defaultNumDays} Days</div>
          </>
        }
      />

      <section
        className={
          'grid grid-cols-[repeat(auto-fit,_minmax(14rem,_auto))] border-l border-t border-kc-gray-100'
        }
      >
        <HighlightCard
          content={toCurrency(k8sCosts, currencyCode, 2, true)}
          footer={`Including ${clusterCount} ${clusterCount > 1 ? 'clusters' : 'cluster'}`}
          header={
            <HighlightCardTooltip
              description={
                'Estimated cost based on last 7 days of resource consumption. Does not include external cloud costs.'
              }
              title={'Kubernetes Costs'}
            />
          }
          href={`allocations?window=${defaultNumDays}d`}
          isEfficiency={false}
          loading={costsLoading === FetchStates.LOADING || assetFetchState === FetchStates.LOADING}
          trendContent={`${k8sCostTrend.toFixed(2)}`}
          trendValue={k8sCostTrend}
        />
        <HighlightCard
          content={toCurrency(cloudCosts, currencyCode, 2, true)}
          footer={`Including ${providerCount} cloud ${
            providerCount > 1 ? 'providers' : 'provider'
          }`}
          header={
            <HighlightCardTooltip
              description={'Top non-K8s cloud service expenditures over the last 7 days.'}
              title={'Cloud Costs'}
            />
          }
          href={`assets?window=${defaultNumDays}d&agg=service&filters=${encodeURIComponent(
            btoa(JSON.stringify([{ property: 'type', value: 'cloud' }])),
          )}`}
          isEfficiency={false}
          loading={assetFetchState === FetchStates.LOADING}
        />
        <HighlightCard
          content={toCurrency(k8sCosts + cloudCosts, currencyCode, 2, true)}
          footer={'Combined Kubernetes and Cloud costs'}
          header={
            <HighlightCardTooltip
              description={
                'Cumulative Kubernetes cluster costs per namespace over the last 7 days. Includes Network costs (if enabled).'
              }
              title={'Total Costs'}
            />
          }
          href={`assets?window=${defaultNumDays}d`}
          isEfficiency={false}
          loading={costsLoading === FetchStates.LOADING || assetFetchState === FetchStates.LOADING}
        />
        <HighlightCard
          content={
            savingsFetchState === FetchStates.ERROR
              ? 'Error getting savings data'
              : toCurrency(allSavings, currencyCode, 2, true)
          }
          footer={'See Recommendations'}
          header={
            <HighlightCardTooltip
              description={
                'Estimated savings are probability adjusted and include both Kubernetes and external cloud insights.'
              }
              title={'Possible Savings'}
            />
          }
          href={'savings'}
          loading={savingsFetchState === FetchStates.LOADING}
        />
        {!effCardError && !oldEffCardError && !newEffCardError ? (
          <HighlightCard
            content={`${totalCostEfficiency.toFixed(1)}%`}
            footer={`Including ${clusterCount} ${clusterCount > 1 ? 'clusters' : 'cluster'}`}
            header={
              <HighlightCardTooltip
                description={
                  'Percentage of cluster resources, weighted by cost, used over the last 7 days. Does not include storage.'
                }
                title={'Efficiency'}
              />
            }
            loading={effCardLoading || oldEffCardLoading || newEffCardLoading}
            trendContent={`${effTrendText}`}
            trendValue={effTrend}
            isEfficiency
          />
        ) : (
          <Tooltip
            title={
              'More data is required to report a reliable efficiency number. Try back in a few minutes.'
            }
          >
            <div style={{ height: '100%', width: '100%' }}>
              <HighlightCard
                content={'...'}
                footer={`Including ${clusterCount} ${clusterCount > 1 ? 'clusters' : 'cluster'}`}
                header={'Total Efficiency'}
                href={`allocations?window=${defaultNumDays}d&agg=namespace&chartDisplay=series&idle=shareByCluster`}
                loading={
                  costsLoading === FetchStates.LOADING || assetFetchState === FetchStates.LOADING
                }
              />
            </div>
          </Tooltip>
        )}
      </section>
      <SectionBreak title={'Clusters'} />
      <Grid alignItems={'stretch'} spacing={3} container>
        <Grid lg={6} xs={12} item>
          <TableCard
            columns={[
              { name: 'CLUSTER', prop: 'name' },
              { name: 'COST', prop: 'totalCost' },
            ]}
            loading={costsLoading === FetchStates.LOADING}
            messages={[
              {
                href: 'https://guide.kubecost.com/hc/en-us/articles/4407595970711-Multi-cluster',
                linkStyle: { textDecorationColor: 'rgba(0, 0, 0, 0.5)' },
                show: clusterTableRows.length < 2,
                subhead: 'Click Here to learn how to add more!',
                title: 'Have more clusters?',
              },
            ]}
            rowHref={(row) =>
              `allocations?window=${defaultNumDays}d&context=${encodeURIComponent(
                btoa(JSON.stringify([{ property: 'cluster', value: row.name }])),
              )}&idle=shareByCluster&agg=namespace`
            }
            rows={clusterTableRows}
            title={'Cluster breakdown'}
          />
        </Grid>
        <Grid lg={6} xs={12} item>
          <GraphCard
            colorKeys={allClusters}
            data={{
              cost: clusterDailyCostData,
              count: [],
            }}
            loading={costsLoading === FetchStates.LOADING}
            selectOpts={[{ display: 'Total Cost', value: 'cost' }]}
            title={'Cluster trends'}
          />
        </Grid>
        <Grid lg={6} xs={12} item>
          <EfficiencyGraphCard
            usedStorage={usedStorage}
            totalStorage={totalStorage}
            storageLoading={assetFetchState === FetchStates.LOADING}
            storageError={assetFetchState === FetchStates.ERROR}
          />
        </Grid>
      </Grid>
      <SectionBreak title={'Namespaces'} />
      <Grid alignItems={'stretch'} spacing={3} container>
        <Grid lg={6} xs={12} item>
          <TableCard
            columns={[
              { name: 'CLUSTER', prop: 'cluster' },
              { name: 'NAMESPACE', prop: 'namespace' },
              { name: 'COST', prop: 'totalCost' },
            ]}
            loading={costsLoading === FetchStates.LOADING}
            rowHref={(row) =>
              `allocations?window=${defaultNumDays}d&filters=${encodeURIComponent(
                btoa(
                  JSON.stringify([
                    { property: 'cluster', value: row.cluster },
                    { property: 'namespace', value: row.namespace },
                  ]),
                ),
              )}&idle=shareByCluster&agg=controller`
            }
            rows={namespaces.map((ns) => ({
              ...ns,
              totalCost: toCurrency(ns.cost, currencyCode, 2, false),
            }))}
            title={'Namespace breakdown'}
          />
        </Grid>
        <Grid lg={6} xs={12} item>
          <GraphCard
            colorKeys={allNamespaces}
            data={{
              cost: namespaceDailyCostData,
              count: [],
            }}
            loading={costsLoading === FetchStates.LOADING}
            selectOpts={[{ display: 'Total Cost', value: 'cost' }]}
            title={'Namespace trends'}
          />
        </Grid>
      </Grid>
      <SectionBreak title={'Cloud Costs Breakdown'} />
      <Grid alignItems={'stretch'} spacing={3} container>
        <Grid lg={6} xs={12} item>
          <TableCard
            columns={[
              { name: 'PROVIDER', prop: 'provider' },
              { name: 'ACCOUNT', prop: 'account' },
              { name: 'COST', prop: 'totalCost' },
            ]}
            loading={assetFetchState === FetchStates.LOADING}
            messages={[
              {
                href: 'https://guide.kubecost.com/hc/en-us/articles/4407595968919-Setting-Up-Cloud-Integrations',
                show: accountTableRows.length === 1,
                subhead: 'Click here to learn how to add more!',
                title: 'Have more providers?',
              },
              {
                href: 'https://docs.kubecost.com/install-and-configure/install/cloud-integration',
                show: accountTableRows.length === 0,
                subhead: 'Click here to learn more!',
                title: 'Integrate with your cloud provider to see non-Kubernetes costs.',
              },
            ]}
            rowHref={(row) => {
              if (row.account === '__undefined__') {
                return '';
              }
              const context = [
                { property: 'provider', value: row.provider },
                { property: 'account', value: row.account },
                { property: 'type', value: 'cloud' },
              ];
              return `assets.html?window=${defaultNumDays}d&agg=unaggregated&context=${encodeURIComponent(
                btoa(JSON.stringify(context)),
              )}`;
            }}
            rows={accountTableRows}
            title={'Accounts'}
          />
        </Grid>
        <Grid lg={6} xs={12} item>
          <TableCard
            columns={[
              { name: 'PROVIDER', prop: 'provider' },
              { name: 'SERVICE', prop: 'service' },
              { name: 'COST', prop: 'totalCost' },
            ]}
            loading={assetFetchState === FetchStates.LOADING}
            messages={[
              {
                href: 'https://guide.kubecost.com/hc/en-us/articles/4407595968919-Setting-Up-Cloud-Integrations',
                show: cloudTableRows.length === 1,
                subhead: 'Click here to learn how to add more!',
                title: 'Have more providers?',
              },
              {
                href: 'https://docs.kubecost.com/install-and-configure/install/cloud-integration',
                show: cloudTableRows.length === 0,
                subhead: 'Click here to learn more!',
                title: 'Integrate with your cloud provider to see non-Kubernetes costs.',
              },
            ]}
            rowHref={(row) =>
              `assets.html?window=${defaultNumDays}d&context=${encodeURIComponent(
                btoa(
                  JSON.stringify([
                    { property: 'provider', value: row.provider },
                    { property: 'service', value: row.service },
                  ]),
                ),
              )}&agg=unaggregated`
            }
            rows={cloudTableRows}
            title={'Services'}
          />
        </Grid>
      </Grid>
      <SectionBreak title={'Network Costs Breakdown'} />
      <Grid alignItems={'stretch'} spacing={3} container>
        <Grid lg={6} xs={12} item>
          <TableCard
            columns={[
              { name: 'NAMESPACE', prop: 'namespace' },
              { name: 'COST', prop: 'networkCost' },
            ]}
            loading={costsLoading === FetchStates.LOADING || !server.networkCheckComplete}
            messages={[
              {
                href: server.networkDaemonsetNotActive
                  ? 'https://guide.kubecost.com/hc/en-us/articles/4407595973527'
                  : '',
                show: server.networkDaemonsetNotActive || !networkTotals.length,
                subhead: server.networkDaemonsetNotActive ? 'Learn more' : '',
                title: server.networkDaemonsetNotActive
                  ? 'Set up the Network Costs Daemonset to see data'
                  : 'No namespace network costs recorded yet',
              },
            ]}
            rowHref={(row) => `./network?window=${defaultNumDays}d&namespace=${row.namespace}`}
            rows={
              server.networkDaemonsetNotActive || !networkTotals.length
                ? []
                : networkTotals.map((ns) => ({
                    ...ns,
                    networkCost: toCurrency(ns.cost, currencyCode, 2, false),
                  }))
            }
            title={'Namespace breakdown'}
          />
        </Grid>
      </Grid>
    </>
  );
};

export { Overview };
