import React from 'react';

import { IconProps } from './types';

const MagicWand = React.forwardRef<SVGSVGElement, IconProps>(({ ...args }, ref) => (
  <svg
    fill={'none'}
    height={'16'}
    ref={ref}
    viewBox={'0 0 16 16'}
    width={'16'}
    xmlns={'http://www.w3.org/2000/svg'}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...args}
  >
    <path
      d={
        'M13.5 8v3M12 9.5h3M5.25 2.5V5M4 3.75h2.5M10.5 11.5v2M9.5 12.5h2M11.652 2.352l-9.294 9.294a.5.5 0 0 0 0 .707l1.292 1.293a.5.5 0 0 0 .707 0l9.295-9.294a.5.5 0 0 0 0-.707l-1.293-1.293a.5.5 0 0 0-.707 0ZM9 5l2 2'
      }
      stroke={'currentColor'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
      strokeWidth={'1.2'}
    />
  </svg>
));

MagicWand.displayName = 'MagicWand';
export { MagicWand };
