import { Alert, Button, Modal, Typography } from '@kubecost-frontend/holster';
import { DeleteSharp } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { ActionListItem } from './types';
import { useState } from 'react';

type DeleteActionButtonProps = {
  deleteAction: (item: ActionListItem) => Promise<any>;
  item: ActionListItem;
};

const DeleteActionButton = ({ deleteAction, item }: DeleteActionButtonProps) => {
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteError, setDeleteError] = useState(null);

  const confirmDelete = async () => {
    setLoading(false);
    setDeleteError(null);

    try {
      await deleteAction(item);
      setShowDelete(false);
    } catch (e) {
      setDeleteError(e);
    }

    setLoading(false);
  };

  return (
    <>
      <IconButton>
        <DeleteSharp onClick={() => setShowDelete(true)} />
      </IconButton>
      <Modal title='Confirm Delete' open={showDelete} onClose={() => setShowDelete(false)}>
        <Typography variant='p' className='mb-4'>
          Are you sure you would like to delete this action?
        </Typography>
        {deleteError && (
          <Alert
            variant='danger'
            title='Error'
            content='Could not delete action. Please check your logs.'
          />
        )}
        <div className='flex-inline mt-4 flex justify-end gap-4'>
          <Button onClick={() => setShowDelete(false)} disabled={loading} variant='default'>
            Cancel
          </Button>
          <Button onClick={() => confirmDelete()} disabled={loading} variant='primary'>
            Delete
          </Button>
        </div>
      </Modal>
    </>
  );
};

export { DeleteActionButton };
