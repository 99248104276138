import { Drawer } from '@material-ui/core';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '@kubecost-frontend/holster';

import { CloseIcon } from '../../../assets/images';

import { ActionListItem, ActionType, RequestRightSizingScheduleItem } from './types';

type ActionDetailModalProps = {
  handleOnClose: () => void;
  item?: ActionListItem;
};

const ActionDetailModal = ({ handleOnClose, item }: ActionDetailModalProps) => (
  <Drawer anchor={'right'} open={item !== undefined}>
    <div className={'p-6'}>
      <div className={'flex'}>
        <h5 className={'flex-grow'}>Action Details</h5>
        <CloseIcon onClick={handleOnClose} />
      </div>
      {item && (
        <div className={'py-5'}>
          <dl className={'grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3'}>
            <div className={'sm:col-span-1'}>
              <dt className={'text-sm text-gray-500 font-bold'}>Action</dt>
              <dd className={'text-sm text-gray-900 mt-1'}>{item.displayName}</dd>
            </div>
            <div className={'sm:col-span-1'}>
              <dt className={'text-sm text-gray-500 font-bold'}>Schedule</dt>
              <dd className={'text-sm text-gray-900 mt-1'}>{item.scheduleFrequency}</dd>
            </div>
            <div className={'sm:col-span-1'}>
              <dt className={'text-sm text-gray-500 font-bold'}>Next Run</dt>
              <dd className={'text-sm text-gray-900 mt-1'}>{item.nextRun}</dd>
            </div>
          </dl>
          <div className={'mt-4'}>
            {item.actionType === ActionType.AutomaticRequestSizing && (
              <RightSizeRequestDetails scheduleItems={item.data} />
            )}
          </div>
        </div>
      )}
    </div>
  </Drawer>
);

type RightSizeRequestDetailsProps = {
  scheduleItems: RequestRightSizingScheduleItem[];
};

const RightSizeRequestDetails = ({ scheduleItems }: RightSizeRequestDetailsProps) => (
  <>
    <dl className={'grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3'}>
      <div className={'sm:col-span-1'}>
        <dt className={'text-sm text-gray-500 font-bold'}>Target CPU</dt>
        <dd className={'text-sm text-gray-900 mt-1'}>
          {scheduleItems[0].schedule.targetUtilizationCPU}
        </dd>
      </div>
      <div className={'sm:col-span-1'}>
        <dt className={'text-sm text-gray-500 font-bold'}>Target Mem</dt>
        <dd className={'text-sm text-gray-900 mt-1'}>
          {scheduleItems[0].schedule.targetUtilizationMemory}
        </dd>
      </div>
    </dl>
    <h5 className={'my-4'}>Affected Workloads</h5>
    <Table className={'w-full'}>
      <TableHead>
        <TableRow>
          <TableHeadCell>Cluster\Namespace</TableHeadCell>
          <TableHeadCell>Controller</TableHeadCell>
          <TableHeadCell>Name</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {scheduleItems.map((action, index) => (
          <TableRow key={index}>
            <TableCell>
              {action.workload.clusterID}\{action.workload.namespace}
            </TableCell>
            <TableCell>{action.workload.controllerKind}</TableCell>
            <TableCell>{action.workload.controllerName}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </>
);

export { ActionDetailModal };
