import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import round from 'lodash/round';
import { useState } from 'react';

import { bytesToString, coresToString, toCurrency } from '../../../services/format';

import ContainerDialog from './ContainerDialog';
import WarningPopover from './WarningPopover';

const useCellStyles = makeStyles({
  root: {
    fontSize: '0.800rem',
  },
});

const ContainerRow = ({ container, currency }) => {
  const cellClasses = useCellStyles();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let savings = `${toCurrency(container.monthlySavings, currency)}/mo`;
  if (container.monthlySavings <= 0) {
    savings = <>&ndash;</>;
  }

  const name = container.controller.name
    ? `${container.controller.namespace}/${container.controller.name}:${container.name}`
    : `${container.namespace}/${container.name}`;

  return (
    <>
      <TableRow style={{ cursor: 'pointer' }} hover>
        <TableCell align={'left'} classes={cellClasses} onClick={handleClickOpen} width={200}>
          {name}
        </TableCell>
        <TableCell
          classes={cellClasses}
          onClick={Object.keys(container.warnings).length == 0 ? handleClickOpen : null}
        >
          {Object.keys(container.warnings).length > 0 && <WarningPopover container={container} />}
        </TableCell>
        <TableCell align={'left'} classes={cellClasses} onClick={handleClickOpen}>
          {container.cluster}
        </TableCell>
        <TableCell align={'right'} classes={cellClasses} onClick={handleClickOpen}>
          {coresToString(container.usage.cpuCores)}
        </TableCell>
        <TableCell align={'right'} classes={cellClasses} onClick={handleClickOpen}>
          {coresToString(container.requests.cpuCores)}
        </TableCell>
        <TableCell align={'right'} classes={cellClasses} onClick={handleClickOpen}>
          {coresToString(container.target.cpuCores)}
        </TableCell>
        <TableCell align={'right'} classes={cellClasses} onClick={handleClickOpen}>
          {bytesToString(container.usage.ramBytes)}
        </TableCell>
        <TableCell align={'right'} classes={cellClasses} onClick={handleClickOpen}>
          {bytesToString(container.requests.ramBytes)}
        </TableCell>
        <TableCell align={'right'} classes={cellClasses} onClick={handleClickOpen}>
          {bytesToString(container.target.ramBytes)}
        </TableCell>
        <TableCell align={'right'} classes={cellClasses} onClick={handleClickOpen}>
          {round(container.efficiency * 100, 1)}%
        </TableCell>
        <TableCell align={'right'} classes={cellClasses} onClick={handleClickOpen}>
          {savings}
        </TableCell>
      </TableRow>
      <ContainerDialog
        container={container}
        currency={currency}
        handleClose={handleClose}
        name={name}
        open={open}
      />
    </>
  );
};

export default ContainerRow;
