import { useMemo, useState } from 'react';

interface SortConfig {
  ascending: boolean;
  key: string | null;
}

const useSortableData = (
  items: Array<any>,
  config: SortConfig = { ascending: true, key: null },
) => {
  const [sortConfig, setSortConfig] = useState<SortConfig>(config);

  const sortedData = useMemo(() => {
    const sortableData = [...items];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key as string] < b[sortConfig.key as string]) {
          return sortConfig.ascending ? -1 : 1;
        }
        if (a[sortConfig.key as string] > b[sortConfig.key as string]) {
          return sortConfig.ascending ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [items, sortConfig]);

  const handleSort = (key: string) => {
    const changingColumn = sortConfig.key !== key;
    setSortConfig({
      ascending: changingColumn ? config.ascending : !sortConfig.ascending,
      key,
    });
  };

  return { sortedData, sortConfig, handleSort };
};

export { useSortableData };
