import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';

import { colorMap } from '../../services/colors';
import { toCurrency } from '../../services/format';

interface ARChartData {
  name: string;
  totalCost: number;
}

interface ARChartProps {
  data: ARChartData[];
  dataKey: string;
  width: number;
}

export const ARChart = ({ data, dataKey, width }: ARChartProps) => (
  <BarChart data={data} height={250} margin={{ left: 30 }} width={width}>
    <CartesianGrid strokeDasharray={'0'} vertical={false} />
    <XAxis dataKey={'name'} />
    <YAxis />
    <Tooltip formatter={(value) => toCurrency(value, 'USD')} />
    <Bar dataKey={dataKey} fill={colorMap.green} key={dataKey} />
  </BarChart>
);
