import { FC } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

import { Button, Modal, Typography } from '@kubecost-frontend/holster';

import { AlertTypes, ClusterHealthAlert } from '../../../services/alerts';
import {
  HealthChangeThresholdInput,
  MSTeamsInput,
  SlackInput,
  TimeWindowInput,
} from '../AlertInputFields';
import { TestAlertButton } from '../TestAlertButton';

interface HealthAlertModalProps {
  alert?: ClusterHealthAlert;
  alertSaved?: (values: ClusterHealthAlert) => void;
  onClose?: () => void;
  open?: boolean;
}

const recipientTestName = 'oneRecipientRequired';
const recipientTestError = 'You must provide at least one recipient';
const ensureAtLeastOneRecipient = (value: string | undefined, ctx: Yup.TestContext<AnyObject>) => {
  const keysToEnsure = ['slackWebhookUrl', 'msTeamsWebhookUrl'];
  const webhookWithAValue = Object.keys(ctx.parent).find(
    (key) => keysToEnsure.includes(key) && ctx.parent[key] !== undefined,
  );
  return webhookWithAValue !== undefined;
};

const HealthAlertFormSchema = Yup.object().shape({
  threshold: Yup.number()
    .required('Health score is required.')
    .min(0, 'Health score ranges from 0 to 100!')
    .max(100, 'Health score ranges from 0 to 100!'),
  window: Yup.string().required(
    'Window is required. Must be a relative window, such as 7d or 10m.',
  ),
  slackWebhookUrl: Yup.string().test(
    recipientTestName,
    recipientTestError,
    ensureAtLeastOneRecipient,
  ),
  msTeamsWebhookUrl: Yup.string().test(
    recipientTestName,
    recipientTestError,
    ensureAtLeastOneRecipient,
  ),
});

const HealthAlertModal: FC<HealthAlertModalProps> = ({
  alert,
  alertSaved,
  onClose: handleOnClose,
  open = false,
}: HealthAlertModalProps) => {
  const {
    errors,
    handleChange,
    handleSubmit: handleOnSubmit,
    setTouched,
    submitForm: handleOnClickSubmitForm,
    touched,
    validateForm,
    values,
  } = useFormik<ClusterHealthAlert>({
    enableReinitialize: true,
    initialValues:
      alert ??
      new ClusterHealthAlert({
        type: AlertTypes.Health,
        window: '',
        threshold: 50,
        slackWebhookUrl: '',
        msTeamsWebhookUrl: '',
      }),
    onSubmit: (formValues) => {
      if (!values || !alertSaved) return;
      alertSaved(formValues);
    },
    validationSchema: HealthAlertFormSchema,
  });

  const handleOnValidate = async () => {
    setTouched({
      threshold: true,
      slackWebhookUrl: true,
      msTeamsWebhookUrl: true,
      window: true,
    });
    const formErrors = await validateForm();
    if (Object.keys(formErrors).length > 0) return false;
    return true;
  };

  return (
    <Modal
      data-test={'health-alert-dialog'}
      onClose={handleOnClose}
      open={open}
      title={'Enable Cluster Health Monitoring'}
    >
      <form onSubmit={handleOnSubmit}>
        <Typography style={{ marginBottom: 16 }} variant={'p'}>
          With cluster health monitoring enabled, Kubecost will send a message to the designated
          webhook(s) any time the cluster&apos;s Health score deteriorates by more than the
          designated threshold, within the designated time window.
        </Typography>
        <HealthChangeThresholdInput
          handleOnChange={handleChange}
          helperText={
            touched.threshold && errors.threshold
              ? errors.threshold
              : 'Health scores range from 0 to 100'
          }
          inputClassName={'w-full'}
          inputName={'threshold'}
          inputTestId={'ham-healthScore'}
          inputType={'number'}
          value={values.threshold}
        />
        <TimeWindowInput
          handleOnChange={handleChange}
          helperText={
            touched.window && errors.window
              ? errors.window
              : 'Must be a relative window, such as 7d or 10m'
          }
          inputClassName={'w-full'}
          inputName={'window'}
          inputTestId={'ham-window'}
          value={values.window}
        />
        <SlackInput
          handleOnChange={handleChange}
          helperText={
            touched.slackWebhookUrl && errors.slackWebhookUrl ? errors.slackWebhookUrl : ''
          }
          inputClassName={'w-full'}
          inputName={'slackWebhookUrl'}
          inputTestId={'ham-slackurl'}
          value={values.slackWebhookUrl as string}
        />
        <MSTeamsInput
          handleOnChange={handleChange}
          helperText={
            touched.msTeamsWebhookUrl && errors.msTeamsWebhookUrl ? errors.msTeamsWebhookUrl : ''
          }
          inputClassName={'w-full'}
          inputName={'msTeamsWebhookUrl'}
          inputTestId={'ham-msTeamsUrl'}
          value={values.msTeamsWebhookUrl as string}
        />
        <div className={'mt-2 flex justify-end'}>
          <Button
            onClick={handleOnClose}
            style={{ marginRight: 8 }}
            type={'button'}
            variant={'primary'}
          >
            Cancel
          </Button>
          <Button
            data-testid={'ham-save-button'}
            onClick={handleOnClickSubmitForm}
            style={{ marginRight: 8 }}
            type={'button'}
            variant={'primary'}
          >
            Save
          </Button>
          <TestAlertButton alert={values} onValidate={handleOnValidate} />
        </div>
      </form>
    </Modal>
  );
};

export { HealthAlertModal, HealthAlertModalProps };
