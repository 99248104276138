import { StrictMode } from 'react';

import ReactDOM from 'react-dom/client';

import { App } from './App';
import reportWebVitals from './reportWebVitals';

const rootEl = document.getElementById('root');

if (!rootEl) {
  throw new Error('Could not mount application to document: Element #root not found.');
}
const root = ReactDOM.createRoot(rootEl);
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
