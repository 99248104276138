/* eslint-disable no-underscore-dangle */
import React from 'react';

import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { Typography } from '@kubecost-frontend/holster';

import { useClusters } from '../../contexts/ClusterConfig';
import { toCurrency } from '../../services/format';
import logger from '../../services/logger';
import { model } from '../../services/model';

import { useTotalCostEfficiency } from './hooks/useTotalCostEfficiency';

const CardShell = ({ children }: { children: React.ReactNode }) => (
  <div className={'h-full w-full border border-kc-gray-100'}>
    <div
      className={'flex items-center border-b border-kc-gray-100 text-lg'}
      style={{
        padding: '15px 19px',
      }}
    >
      <Typography className={'font-bold'} style={{ flexGrow: 1 }} variant={'p'}>
        Cluster Efficiency
      </Typography>
    </div>
    {children}
  </div>
);

interface EfficiencyGraphCardProps {
  usedStorage: number;
  totalStorage: number;
  storageLoading: boolean;
  storageError: boolean;
}

const EfficiencyGraphCard = ({
  usedStorage,
  totalStorage,
  storageLoading,
  storageError,
}: EfficiencyGraphCardProps) => {
  const {
    modelConfig: { currencyCode },
  } = useClusters();
  const { data, isError, isLoading } = useTotalCostEfficiency();

  if (isLoading || storageLoading) {
    return (
      <CardShell>
        <ResponsiveContainer height={300} width={'100%'}>
          <BarChart
            data={[
              {
                val: 8,
              },
              {
                val: 10,
              },
              {
                val: 9,
              },
              {
                val: 7,
              },
              {
                val: 10,
              },
              {
                val: 9,
              },
            ]}
            margin={{ top: 30, right: 30, left: 30, bottom: 12 }}
          >
            <CartesianGrid strokeDasharray={'3 3'} vertical={false} />
            <XAxis />
            <Bar className={'animate-pulse'} dataKey={'val'} fill={'#023927'} />
          </BarChart>
        </ResponsiveContainer>
      </CardShell>
    );
  }
  if (isError || storageError) {
    return (
      <CardShell>
        <div className={'border-gray-300 h-[300px] w-full border-b pt-12 text-center'}>
          <Typography className={'font-bold'} variant={'p-large'}>
            Error loading efficiency data.
          </Typography>
        </div>
      </CardShell>
    );
  }
  if (data) {
    const allocData = data.data.sets[0].allocations;
    try {
      const cpuUsage = allocData[''].cpuCost * model.getSummaryCpuEfficiency(allocData['']);
      const ramUsage = allocData[''].ramCost * model.getSummaryRamEfficiency(allocData['']);
      const chartData = [
        {
          Idle: allocData.__idle__.cpuCost,
          Key: 'CPU',
          Usage: cpuUsage,
          Allocation: allocData[''].cpuCost - cpuUsage,
        },
        {
          Idle: allocData.__idle__.ramCost,
          Key: 'RAM',
          Usage: ramUsage,
          Allocation: allocData[''].ramCost - ramUsage,
        },
        {
          Idle: totalStorage - usedStorage,
          Key: 'Storage',
          Usage: usedStorage,
        },
      ];
      return (
        <CardShell>
          <ResponsiveContainer height={300} width={'100%'}>
            <BarChart data={chartData} margin={{ top: 30, right: 30, left: 30, bottom: 12 }}>
              <CartesianGrid vertical={false} />
              <XAxis dataKey={'Key'} />
              <YAxis tickFormatter={(val: number) => toCurrency(val, currencyCode, 0, true)} />
              <Legend />
              <Tooltip formatter={(val) => toCurrency(Number(val), currencyCode)} />
              <Bar dataKey={'Usage'} fill={'#3cba54'} stackId={'a'} />
              <Bar dataKey={'Allocation'} fill={'#78f690'} stackId={'a'} />
              <Bar dataKey={'Idle'} fill={'#b8b8b8'} stackId={'a'} />
            </BarChart>
          </ResponsiveContainer>
        </CardShell>
      );
    } catch (err) {
      logger.log('Error rendering efficiency graph card:');
      logger.error(err);
      return (
        <CardShell>
          <div className={'border-gray-300 h-[300px] w-full border-b pt-12 text-center'}>
            <Typography className={'font-bold'} variant={'p-large'}>
              Error loading efficiency data.
            </Typography>
          </div>
        </CardShell>
      );
    }
  }
  return (
    <CardShell>
      <div className={'border-gray-300 h-[300px] w-full border-b pt-12 text-center'}>
        <Typography className={'font-bold'} variant={'p-large'}>
          No efficiency data available at this time.
        </Typography>
      </div>
    </CardShell>
  );
};

export { EfficiencyGraphCard };
