import { FC, useEffect, useState } from 'react';

import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';

import { Alert } from '@kubecost-frontend/holster';

import { useGetModelConfig } from '../../hooks/useGetModelConfig';
import useProductTier from '../../hooks/useProductTier';
import ConfigService, { ProductKey } from '../../services/config';
import { model } from '../../services/model';
import { currencyList, isUpdateAvailable } from '../../services/util';

import { BetaUISetting } from './BetaUISetting';
import { DurableStorage } from './DurableStorage';
import { LicenseKeyManager } from './LicenseManagement';
import { PrometheusStatus } from './PrometheusStatus';
import {
  DataElement,
  DataElementGrouping,
  SettingDataType,
  SingleDataElement,
} from './SettingsComponentDefinitions';
import { SpotInstanceModal } from './SpotInstanceModal';
import { AWSConfigModal } from './UpdateAWSConfigModal';
import { UpdateAzureConfigModal } from './UpdateAzureConfigModal';
import UpgradeSettings from './UpgradeSettings';

const hoursInMonth = 730.08;

const hourToMonthRateConverter = (hourlyRate: number) => hourlyRate * hoursInMonth;
const monthToHourRateConverter = (monthlyRate: number) => monthlyRate / hoursInMonth;

const Settings: FC = () => {
  const isProductTier = useProductTier();
  const { updateModelConfig } = useGetModelConfig();
  // we should probably just have one massive state store
  const [loading, setLoading] = useState<boolean>(false);
  const [spotModalVisible, setSpotModalVisible] = useState<boolean>(false);
  const [awsConfigModalVisible, setAwsConfigModalVisible] = useState<boolean>(false);
  const [azureConfigModalVisible, setAzureConfigModalVisible] = useState<boolean>(false);
  const [scrollValue, setScrollValue] = useState('General');
  // we should really hold onto initial state as well for the bug report
  const [settingState, setSettingState] = useState(null);
  const [productKey, setProductKey] = useState<ProductKey>();
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [updateAvailable, setUpdateAvailable] = useState(false);

  const initialize = async () => {
    await Promise.all([
      model.getConfigs(),
      model.clusterInfo(),
      model.getAPIConfig(),
      ConfigService.fetchProductKey(),
      isUpdateAvailable(),
    ]).then(([config, clusterInfoResp, apiConfigResp, productKeyResp, update]) => {
      // we should store these all together to prevent rerendering issues
      setProductKey(productKeyResp);
      setIsReadOnly(config.readOnly === 'true');
      setSettingState({
        ...config,
        ...clusterInfoResp,
        ...apiConfigResp,
        ...productKeyResp,
        CPU: hourToMonthRateConverter(parseFloat(config.CPU)),
        spotCPU: hourToMonthRateConverter(parseFloat(config.spotCPU)),
        RAM: hourToMonthRateConverter(parseFloat(config.RAM)),
        spotRAM: hourToMonthRateConverter(parseFloat(config.spotRAM)),
        GPU: hourToMonthRateConverter(parseFloat(config.GPU)),
        storage: hourToMonthRateConverter(parseFloat(config.storage)),
        serviceAddress: localStorage.getItem('container'),
      });
      setUpdateAvailable(update);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    initialize();
  }, []);

  const handleSave = async () => {
    setLoading(true);
    // check to see if service address has changed
    // update it's localStorage item
    if (settingState.serviceAddress !== localStorage.getItem('container')) {
      localStorage.setItem('container', settingState.serviceAddress);
    }

    const updateConfigByKeyPayload = {
      customPricesEnabled: settingState.customPricesEnabled,
      defaultIdle: settingState.defaultIdle,
      currencyCode: settingState.currencyCode,
      CPU: `${monthToHourRateConverter(parseFloat(settingState.CPU))}`,
      spotCPU: `${monthToHourRateConverter(parseFloat(settingState.spotCPU))}`,
      RAM: `${monthToHourRateConverter(parseFloat(settingState.RAM))}`,
      spotRAM: `${monthToHourRateConverter(parseFloat(settingState.spotRAM))}`,
      GPU: `${monthToHourRateConverter(parseFloat(settingState.GPU))}`,
      storage: `${monthToHourRateConverter(parseFloat(settingState.storage))}`,
      azureSubscriptionID: settingState.azureSubscriptionID,
      azureClientID: settingState.azureClientID,
      azureClientSecret: settingState.azureClientSecret,
      azureTenantID: settingState.azureTenantID,
      azureBillingRegion: settingState.azureBillingRegion,
      discount: settingState.discount,
      negotiatedDiscount: settingState.negotiatedDiscount,
      sharedOverhead: settingState.sharedOverhead,
      gpuLabel: settingState.gpuLabel,
      gpuLabelValue: settingState.gpuLabelValue,
      clusterName: settingState.clusterName,
      sharedNamespaces: settingState.sharedNamespaces,
      sharedLabelNames: settingState.sharedLabelNames,
      sharedLabelValues: settingState.sharedLabelValues,
      shareTenancyCosts: settingState.shareTenancyCosts,
    };
    const setApiConfigPayload = {
      grafanaURL: settingState.grafanaURL,
      owner_label: settingState.owner_label,
      team_label: settingState.team_label,
      department_label: settingState.department_label,
      product_label: settingState.product_label,
      environment_label: settingState.environment_label,
    };

    updateModelConfig(updateConfigByKeyPayload as any);

    await Promise.all([model.setApiConfig(setApiConfigPayload)]).then(([updateConfigResp]) => {
      setLoading(false);
      alert('Successfully saved');
      setSettingState(null);
      initialize();
    });
  };

  const generalSettingsGroupings: DataElementGrouping[] = [
    {
      title: 'Context Settings',
      subText: 'High-level settings options.',
      dataElements: [
        {
          dataType: SettingDataType.Text,
          primaryText: 'Context Name',
          secondaryText: 'Name of the Context',
          dataSource: 'clusterName',
          defaultValue: (settingState && settingState.clusterName) || '',
        },
        {
          dataType: SettingDataType.Text,
          primaryText: 'Service Address',
          secondaryText: 'URL of the cost-analyzer service in your cluster.',
          dataSource: 'serviceAddress',
          defaultValue: (settingState && settingState.serviceAddress) || '',
        },
        {
          dataType: SettingDataType.Text,
          primaryText: 'Grafana Address',
          secondaryText: 'Custom location of Grafana dashboard home. Not required.',
          dataSource: 'grafanaURL',
          defaultValue: (settingState && settingState.grafanaURL) || '',
        },
      ],
    },
    {
      title: 'Labels',
      subText: 'Use this section to manipulate and define aliased labels',
      dataElements: [
        {
          dataType: SettingDataType.Text,
          primaryText: 'Owner Label / Annotation',
          secondaryText:
            'Name of k8s label or annotation to designate owner/contact. CSV supported.',
          dataSource: 'owner_label',
          defaultValue: (settingState && settingState.owner_label) || '',
        },
        {
          dataType: SettingDataType.Text,
          primaryText: 'Team Label',
          secondaryText: 'Name of k8s label to designate team. CSV supported.',
          dataSource: 'team_label',
          defaultValue: (settingState && settingState.team_label) || '',
        },
        {
          dataType: SettingDataType.Text,
          primaryText: 'Department Label',
          secondaryText: 'Name of k8s label to designate department/cost center. CSV supported.',
          dataSource: 'department_label',
          defaultValue: (settingState && settingState.department_label) || '',
        },
        {
          dataType: SettingDataType.Text,
          primaryText: 'Product Label',
          secondaryText: 'Name of k8s label to designate app/product. CSV supported',
          dataSource: 'product_label',
          defaultValue: (settingState && settingState.product_label) || '',
        },
        {
          dataType: SettingDataType.Text,
          primaryText: 'Environment Label',
          secondaryText:
            'Name of k8s label to designate environment, i.e. dev, prod, etc. CSV supported.',
          dataSource: 'environment_label',
          defaultValue: (settingState && settingState.environment_label) || '',
        },
        {
          dataType: SettingDataType.Text,
          primaryText: 'GPU Label',
          secondaryText: 'Name of k8s node label that designates GPU type',
          dataSource: 'gpuLabel',
          defaultValue: (settingState && settingState.gpuLabel) || '',
        },
        {
          dataType: SettingDataType.Text,
          primaryText: 'GPU Label Value',
          secondaryText: 'Value of the k8s node label that designates a node has an attached GPU',
          dataSource: 'gpuLabelValue',
          defaultValue: (settingState && settingState.gpuLabelValue) || '',
        },
      ],
    },
    {
      title: 'Cloud Cost Settings',
      subText: 'Integrate with your Cloud Provider',
      dataElements: [
        {
          dataType: SettingDataType.Button,
          primaryText: 'Spot Instance Configuration',
          secondaryText:
            'Provide AWS spot configuration info and enable the spot data feed to reflect actual prices paid for spot nodes.',
          onClick: () => setSpotModalVisible(true),
          buttonText: 'Update',
        },
        {
          dataType: SettingDataType.Button,
          primaryText: 'External Cloud Cost Configuration (AWS)',
          secondaryText:
            'Allocate AWS out of cluster costs (e.g. S3 buckets), apply reserved instance commitments, and find external cost savings (e.g. abandoned assets).',
          onClick: () => setAwsConfigModalVisible(true),
          buttonText: 'Update',
        },
        {
          dataType: SettingDataType.Button,
          primaryText: 'External Cloud Cost Configuration (GCP)',
          secondaryText:
            'Allocate GCP out of cluster costs (e.g. Cloud Storage buckets), apply committed use discounts, and find external cost savings (e.g. abandoned assets).',
          onClick: () => (window.location.href = `keyinstructions`),
          buttonText: 'Update',
        },
        {
          dataType: SettingDataType.Button,
          primaryText: 'External Cloud Cost Configuration (Azure)',
          secondaryText:
            'Allocate Azure out of cluster costs (e.g. Azure Blobs), apply reserved instance commitments, and find external cost savings (e.g. abandoned assets).',
          onClick: () => setAzureConfigModalVisible(true),
          buttonText: 'Update',
        },
      ],
    },
  ];
  // conditionally rendered if `customPricesEnabled` is 'true'
  const customPricingOptions = [
    {
      dataType: SettingDataType.Text,
      primaryText: 'Monthly CPU Price',
      secondaryText:
        'Custom price per CPU that overrides cloud billing API data for cost allocation. Figures should be before any discount is applied.',
      dataSource: 'CPU',
      defaultValue: (settingState && settingState.CPU) || '',
    },
    {
      dataType: SettingDataType.Text,
      primaryText: 'Monthly Spot CPU Price',
      secondaryText: 'Custom CPU price used for spot or preemptible nodes.',
      dataSource: 'spotCPU',
      defaultValue: (settingState && settingState.spotCPU) || '',
    },
    {
      dataType: SettingDataType.Text,
      primaryText: 'Monthly RAM Price',
      secondaryText:
        'Custom RAM price per GB that overrides any cloud billing API for cost allocation.',
      dataSource: 'RAM',
      defaultValue: (settingState && settingState.RAM) || '',
    },
    {
      dataType: SettingDataType.Text,
      primaryText: 'Monthly Spot RAM Price',
      secondaryText: 'Custom RAM price used for spot or preemptible nodes.',
      dataSource: 'spotRAM',
      defaultValue: (settingState && settingState.spotRAM) || '',
    },
    {
      dataType: SettingDataType.Text,
      primaryText: 'Monthly GPU Price',
      secondaryText: 'Custom GPU price',
      dataSource: 'GPU',
      defaultValue: (settingState && settingState.GPU) || '',
    },
    {
      dataType: SettingDataType.Text,
      primaryText: 'Monthly Storage Price',
      secondaryText: 'Custom Storage price per GB',
      dataSource: 'storage',
      defaultValue: (settingState && settingState.storage) || '',
    },
  ];

  const sharingGrouping: DataElementGrouping[] = [
    {
      title: 'Pricing',
      subText: 'Any attained discounts can be managed here',
      dataElements: [
        {
          dataType: SettingDataType.Toggle,
          primaryText: 'Allocate Idle Resources',
          secondaryText: 'Allocates idle/slack cluster capacity to Kubernetes tenants by default.',
          dataSource: 'defaultIdle',
          defaultValue: (settingState && settingState.defaultIdle) || '',
        },
        {
          dataType: SettingDataType.Toggle,
          primaryText: 'Enable Custom Pricing',
          secondaryText:
            'Override cloud provider pricing or set custom pricing for on-prem clusters.',
          dataSource: 'customPricesEnabled',
          defaultValue: (settingState && settingState.customPricesEnabled) || '',
        },
        // conditionally render customPricingOptions
        ...(settingState && settingState.customPricesEnabled === 'true'
          ? customPricingOptions
          : []),
      ],
    },
    {
      title: 'Shared Cost',
      subText: 'Distributed costs that are shared across entities',
      dataElements: [
        {
          dataType: SettingDataType.Text,
          primaryText: 'Shared Overhead',
          secondaryText:
            'Fixed external monthly amount to be split proportionately between Kubernetes aggregations.',
          dataSource: 'sharedOverhead',
          defaultValue: (settingState && settingState.sharedOverhead) || '',
        },
        {
          dataType: SettingDataType.Toggle,
          primaryText: 'Share Tenancy Costs',
          secondaryText:
            'Choose whether or not to share tenanacy costs, such as cluster management fees, on the cost allocation page.',
          dataSource: 'shareTenancyCosts',
          defaultValue: (settingState && settingState.shareTenancyCosts) || '',
        },
        {
          dataType: SettingDataType.Text,
          primaryText: 'Shared Namespaces',
          secondaryText:
            'A comma separated list of namespaces whose costs to distribute proportionately amongst other aggregations.',
          dataSource: 'sharedNamespaces',
          defaultValue: (settingState && settingState.sharedNamespaces) || '',
        },
        {
          dataType: SettingDataType.Text,
          primaryText: 'Shared Labels',
          secondaryText:
            'A comma separated list of label names whose costs to distribute proportionately. Matching label values should be supplied in the following field.',
          dataSource: 'sharedLabelNames',
          defaultValue: (settingState && settingState.sharedLabelNames) || '',
        },
        {
          dataType: SettingDataType.Text,
          primaryText: 'Shared Label Values',
          secondaryText:
            'A comma separated list of label values to match the set of label names provided in the field above.',
          dataSource: 'sharedLabelValues',
          defaultValue: (settingState && settingState.sharedLabelValues) || '',
        },
      ],
    },
    {
      title: 'Discounts',
      subText: 'Any attained discounts can be managed here',
      dataElements: [
        {
          dataType: SettingDataType.Text,
          primaryText: 'Estimated Sustained Use Discount (GCP Only)',
          secondaryText:
            'Estimated percentage monthly discount applied to constantly-running on-demand compute & memory.',
          dataSource: 'discount',
          defaultValue: (settingState && settingState.discount) || '',
        },
        {
          dataType: SettingDataType.Text,
          primaryText: 'Negotiated Discount',
          secondaryText: 'Custom negotiated percentage discount, e.g. 10%',
          dataSource: 'negotiatedDiscount',
          defaultValue: (settingState && settingState.negotiatedDiscount) || '',
        },
      ],
    },
  ];

  const handleScroll = (scrollTo: string) => {
    setScrollValue(scrollTo);
    document.getElementById(scrollTo)?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleUpgrade = async (key: string) => {
    const keyCopy = productKey;
    keyCopy!.productKey.key = key;
    const resp = await ConfigService.setProductKey(keyCopy!);

    alert('Successfully updated');
    // refetch
    initialize();
  };

  return (
    <Paper style={{ padding: '2em' }}>
      {updateAvailable && (
        <div className={'mb-4'}>
          <Alert
            content={'A new version of Kubecost is available.'}
            link={{
              href: 'https://github.com/kubecost/cost-analyzer-helm-chart/releases',
              target: '_blank',
              text: 'View releases',
            }}
            title={'Update available'}
            variant={'warning'}
          />
        </div>
      )}
      <SpotInstanceModal closeModal={() => setSpotModalVisible(false)} visible={spotModalVisible} />
      <AWSConfigModal
        closeModal={() => setAwsConfigModalVisible(false)}
        visible={awsConfigModalVisible}
      />
      <UpdateAzureConfigModal
        closeModal={() => setAzureConfigModalVisible(false)}
        visible={azureConfigModalVisible}
      />
      <Typography style={{ marginBottom: '1em' }} variant={'h4'}>
        Settings
      </Typography>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '250px 1fr',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}
        >
          {['General', 'Pricing', 'Diagnostics'].map((item: string) => (
            <Button
              onClick={() => handleScroll(item)}
              style={{ color: item === scrollValue ? 'green' : '' }}
            >
              {item}
            </Button>
          ))}
        </div>
        <div className={'px-8 pb-8'}>
          <BetaUISetting />
          <section id={'General'}>
            <LicenseKeyManager />
            <Divider style={{ marginTop: '4em', marginBottom: '4em' }} variant={'middle'} />
          </section>
          {isProductTier.Free && <UpgradeSettings />}
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              {settingState !== null &&
                generalSettingsGroupings.map((itemGroup: DataElementGrouping) => (
                  <>
                    <div
                      id={itemGroup.title}
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 2fr',
                      }}
                    >
                      <div>
                        <Typography variant={'h6'}>{itemGroup.title}</Typography>
                        <Typography style={{ color: 'gray' }} variant={'body2'}>
                          {itemGroup.subText}
                        </Typography>
                      </div>
                      <div>
                        {itemGroup.dataElements.map((item: DataElement) => (
                          <div style={{ marginBottom: '1.5em' }}>
                            <SingleDataElement
                              buttonText={item.buttonText}
                              currentState={settingState}
                              dataSource={item.dataSource}
                              dataType={item.dataType}
                              defaultValue={item.defaultValue}
                              disabled={isReadOnly}
                              key={item.primaryText}
                              onClick={item.onClick}
                              primaryText={item.primaryText}
                              secondaryText={item.secondaryText}
                              updateState={setSettingState}
                            />
                          </div>
                        ))}
                        {itemGroup.title === 'Cloud Cost Settings' && (
                          <div style={{ marginBottom: '1.5em' }}>
                            <Grid spacing={3} style={{ marginTop: '.5em' }} container>
                              <Grid xs={9} item>
                                <Typography variant={'h6'}>Currency</Typography>
                                <Typography variant={'subtitle1'}>
                                  Currency conversion applied to Azure cloud pricing, but currency
                                  codes shown for display purposes in other environments.
                                </Typography>
                              </Grid>
                              <Grid style={{ textAlign: 'end' }} xs={3} item>
                                <FormControl>
                                  <InputLabel>Currency</InputLabel>
                                  <Select
                                    onChange={(e) =>
                                      setSettingState({
                                        ...settingState,
                                        currencyCode: e.target.value,
                                      })
                                    }
                                    value={(settingState && settingState.currencyCode) || ''}
                                  >
                                    {currencyList.map((currencyName: string) => (
                                      <MenuItem value={currencyName}>{currencyName}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </div>
                        )}
                      </div>
                    </div>
                    <Divider style={{ marginTop: '4em', marginBottom: '4em' }} variant={'middle'} />
                  </>
                ))}
              {settingState !== null &&
                sharingGrouping.map((itemGroup: DataElementGrouping) => (
                  <>
                    <div
                      id={itemGroup.title}
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 2fr',
                      }}
                    >
                      <div>
                        <Typography variant={'h6'}>{itemGroup.title}</Typography>
                        <Typography style={{ color: 'gray' }} variant={'body2'}>
                          {itemGroup.subText}
                        </Typography>
                      </div>
                      <div>
                        {itemGroup.dataElements.map((item: DataElement) => (
                          <div style={{ marginBottom: '1.5em' }}>
                            <SingleDataElement
                              buttonText={item.buttonText}
                              currentState={settingState}
                              dataSource={item.dataSource}
                              dataType={item.dataType}
                              defaultValue={item.defaultValue}
                              disabled={isReadOnly}
                              key={item.primaryText}
                              onClick={item.onClick}
                              primaryText={item.primaryText}
                              secondaryText={item.secondaryText}
                              updateState={setSettingState}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <Divider style={{ marginTop: '4em', marginBottom: '4em' }} variant={'middle'} />
                  </>
                ))}
              {settingState && (
                <div id={'Diagnostics'}>
                  <PrometheusStatus />
                  <div style={{ marginTop: '2em' }}>
                    <DurableStorage />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Button
        disabled={isReadOnly}
        onClick={handleSave}
        style={{ width: 100, margin: '1em', float: 'right' }}
        variant={'contained'}
      >
        Save
      </Button>

      <div style={{ clear: 'both' }} />
    </Paper>
  );
};

export { Settings };
