/*
 * TODO: Comply with this lint rule or disable it for the project.
 * This disable is just to make it easier to work with this file
 * in the short term.
 */
/* eslint-disable max-classes-per-file */
import { getCurrentContainerAddressModel } from './util';

class RBACUnauthorizedError extends Error {
  constructor(...params: any) {
    super(...params);
    this.name = 'RBAC Unauthorized Error';

    Object.setPrototypeOf(this, RBACUnauthorizedError.prototype);
  }
}

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

async function getGlobalLinkback(): Promise<string> {
  const urlRoot = `${getCurrentContainerAddressModel()}/alerts`;
  const response = await fetch(`${urlRoot}/linkback?req=${Math.floor(Math.random() * 1000000)}`);
  const js = await response.json();
  return js.frontendUrl;
}

async function setGlobalLinkback(frontendUrl: string): Promise<Response> {
  const urlRoot = `${getCurrentContainerAddressModel()}/alerts`;
  return fetch(`${urlRoot}/linkback`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ frontendUrl }),
  });
}

async function getGlobalSlackWebhook(): Promise<string> {
  const urlRoot = `${getCurrentContainerAddressModel()}/alerts`;
  const response = await fetch(`${urlRoot}/slack?req=${Math.floor(Math.random() * 1000000)}`);
  const js = await response.json();
  return js.webhookUrl;
}

async function setGlobalSlackWebhook(webhookUrl: string, frontendUrl: string): Promise<Response> {
  const urlRoot = `${getCurrentContainerAddressModel()}/alerts`;
  return fetch(`${urlRoot}/slack`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ webhookUrl, frontendUrl }),
  });
}

async function getGlobalMSTeamsWebhook(): Promise<string> {
  const urlRoot = `${getCurrentContainerAddressModel()}/alerts`;
  // TODO: Update URL for actual MS Teams endpoint
  const response = await fetch(`${urlRoot}/msTeams?req=${Math.floor(Math.random() * 1000000)}`);
  const js = await response.json();
  return js.webhookUrl;
}

async function setGlobalMSTeamsWebhook(webhookUrl: string, frontendUrl: string): Promise<Response> {
  const urlRoot = `${getCurrentContainerAddressModel()}/alerts`;
  // TODO: Update URL for actual MS Teams endpoint
  return fetch(`${urlRoot}/msTeams`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ webhookUrl, frontendUrl }),
  });
}

async function getGlobalEmailRecipients(): Promise<string[]> {
  const urlRoot = `${getCurrentContainerAddressModel()}/alerts`;
  const response = await fetch(`${urlRoot}/email?req=${Math.floor(Math.random() * 1000000)}`);
  const js = await response.json();
  return js.recipients || [];
}

async function getGlobalEmailConfig(): Promise<{
  recipients?: string[];
  subject?: string;
}> {
  const urlRoot = `${getCurrentContainerAddressModel()}/alerts`;
  const response = await fetch(`${urlRoot}/email?req=${Math.floor(Math.random() * 1000000)}`);
  const js = await response.json();
  return js;
}

async function setGlobalEmailRecipients(recipients: string[], subject?: string): Promise<Response> {
  const urlRoot = `${getCurrentContainerAddressModel()}/alerts`;
  return fetch(`${urlRoot}/email`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ recipients, subject }),
  });
}

async function deleteAlert(alert: Alert): Promise<boolean> {
  const urlRoot = `${getCurrentContainerAddressModel()}/alerts`;
  const response = await fetch(`${urlRoot}/${alert.id}`, {
    method: 'DELETE',
    headers,
  });

  if (response.status === 403) {
    throw new RBACUnauthorizedError();
  }

  return response.status === 200;
}

async function testAlert(alert: Alert): Promise<Response> {
  const urlRoot = `${getCurrentContainerAddressModel()}/alerts`;
  const response = await fetch(`${urlRoot}/test`, {
    method: 'POST',
    headers,
    body: JSON.stringify(alert.toJson()),
  });
  return response;
}

enum AlertTypes {
  Budget = 'budget',
  Efficiency = 'efficiency',
  Recurring = 'recurringUpdate',
  SpendChange = 'spendChange',
  AllocationBudget = 'budget',
  AllocationEfficiency = 'efficiency',
  AllocationRecurring = 'recurringUpdate',
  AllocationSpendChange = 'spendChange',
  Diagnostic = 'diagnostic',
  Health = 'health',
  AssetBudget = 'assetBudget',
  AssetRecurring = 'cloudReport',
  NullAlertType = '',
}

class Alert {
  aggregation: string;

  type: AlertTypes;

  window: string;

  id?: string;

  linkbackURL?: string;

  ownerContact?: string[];

  slackWebhookUrl?: string;

  msTeamsWebhookUrl?: string;

  emailSubject?: string;

  constructor({
    aggregation,
    emailSubject,
    id,
    linkbackURL,
    msTeamsWebhookUrl,
    ownerContact,
    slackWebhookUrl,
    type,
    window,
  }: AlertOpts) {
    this.aggregation = aggregation || '';
    this.type = type || AlertTypes.NullAlertType;
    this.window = window || '';
    this.id = id;
    this.linkbackURL = linkbackURL;
    this.ownerContact = ownerContact;
    this.slackWebhookUrl = slackWebhookUrl;
    this.msTeamsWebhookUrl = msTeamsWebhookUrl;
    this.emailSubject = emailSubject || '';
  }

  isValid(): boolean {
    return !!(this.aggregation && this.type && this.window);
  }

  toJson(): AlertOpts {
    const opts: AlertOpts = {
      aggregation: this.aggregation || '',
      emailSubject: this.emailSubject || '',
      type: this.type,
      window: this.window || '',
    };
    if (typeof this.id !== 'undefined') {
      opts.id = this.id;
    }
    if (typeof this.linkbackURL !== 'undefined') {
      opts.linkbackURL = this.linkbackURL;
    }
    if (typeof this.ownerContact !== 'undefined') {
      opts.ownerContact = this.ownerContact;
    }
    if (typeof this.slackWebhookUrl !== 'undefined') {
      opts.slackWebhookUrl = this.slackWebhookUrl;
    }
    if (typeof this.msTeamsWebhookUrl !== 'undefined') {
      opts.msTeamsWebhookUrl = this.msTeamsWebhookUrl;
    }
    return opts;
  }
}

class RecurringUpdate extends Alert {
  filter: string;

  constructor({ filter, ...baseOpts }: RecurringAlertOpts) {
    super(baseOpts);
    this.filter = filter || '';
  }

  isValid(): boolean {
    return /[1-9]+d/.test(this.window);
  }

  toJson(): RecurringAlertOpts {
    const baseOpts = super.toJson();
    return { ...baseOpts, filter: this.filter, window: this.window };
  }
}

class EfficiencyAlert extends Alert {
  efficiencyThreshold: number;

  filter: string;

  spendThreshold?: number;

  constructor({ efficiencyThreshold, filter, spendThreshold, ...baseOpts }: EfficiencyAlertOpts) {
    super(baseOpts);
    this.efficiencyThreshold = efficiencyThreshold;
    this.filter = filter || '';
    this.spendThreshold = spendThreshold;
  }

  isValid(): boolean {
    return (
      /[1-9]+d/.test(this.window) &&
      this.efficiencyThreshold >= 0.0 &&
      this.efficiencyThreshold <= 1.0
    );
  }

  toJson(): EfficiencyAlertOpts {
    const baseOpts = super.toJson();
    const opts: EfficiencyAlertOpts = {
      ...baseOpts,
      efficiencyThreshold: this.efficiencyThreshold,
      window: this.window,
    };
    opts.filter = this.filter || '';
    if (typeof this.spendThreshold !== 'undefined') {
      opts.spendThreshold = this.spendThreshold;
    }
    return opts;
  }
}

class BudgetAlert extends Alert {
  filter: string;

  threshold: number;

  constructor({ filter, threshold, ...baseOpts }: BudgetAlertOpts) {
    super(baseOpts);
    this.filter = filter || '';
    this.threshold = threshold;
  }

  isValid(): boolean {
    return /[1-7]d/.test(this.window) || /[1-9]|1[0-9]|2[0-4]h/.test(this.window);
  }

  toJson(): BudgetAlertOpts {
    const baseOpts = super.toJson();
    const opts: BudgetAlertOpts = {
      ...baseOpts,
      filter: this.filter,
      threshold: this.threshold,
      window: this.window,
    };
    return opts;
  }
}

class SpendChangeAlert extends Alert {
  baselineWindow: string;

  relativeThreshold: number;

  filter: string;

  constructor({ baselineWindow, filter, relativeThreshold, ...baseOpts }: SpendChangeAlertOpts) {
    super(baseOpts);
    this.type = AlertTypes.SpendChange;
    this.baselineWindow = baselineWindow;
    this.relativeThreshold = relativeThreshold;
    this.filter = filter || '';
  }

  isValid(): boolean {
    return (
      /[1-9]+d/.test(this.baselineWindow) &&
      this.relativeThreshold >= -1 &&
      (/[1-7]d/.test(this.window) || /[1-9]h|1[0-9]h|2[0-4]h/.test(this.window))
    );
  }

  toJson(): SpendChangeAlertOpts {
    const baseOpts = super.toJson();
    const opts: SpendChangeAlertOpts = {
      ...baseOpts,
      baselineWindow: this.baselineWindow,
      relativeThreshold: this.relativeThreshold,
      window: this.window,
    };
    opts.filter = this.filter;
    return opts;
  }
}

class DiagnosticAlert extends Alert {
  constructor({ ...baseOpts }: DiagnosticAlertOpts) {
    super(baseOpts);
  }

  // TODO: Remove this eslint disable when proper validation is introduced
  // eslint-disable-next-line class-methods-use-this
  isValid(): boolean {
    return true;
  }

  toJson(): DiagnosticAlertOpts {
    const baseOpts = super.toJson();
    return { ...baseOpts, type: AlertTypes.Diagnostic };
  }
}

class ClusterHealthAlert extends Alert {
  threshold: number;

  constructor({ threshold, ...baseOpts }: ClusterHealthAlertOpts) {
    super(baseOpts);
    this.threshold = threshold || 0;
  }

  // TODO: Remove this eslint disable when proper validation is introduced
  // eslint-disable-next-line class-methods-use-this
  isValid(): boolean {
    return true;
  }

  toJson(): ClusterHealthAlertOpts {
    const baseOpts = super.toJson();
    return { ...baseOpts, threshold: this.threshold, type: AlertTypes.Health };
  }
}

class AssetBudget extends BudgetAlert {}
class AssetRecurring extends RecurringUpdate {}

function toClientModel(serverModel: AlertOpts) {
  switch (serverModel.type) {
    case 'recurringUpdate':
      return new RecurringUpdate(serverModel as RecurringAlertOpts);
    case 'efficiency':
      return new EfficiencyAlert(serverModel as EfficiencyAlertOpts);
    case 'budget':
      return new BudgetAlert(serverModel as BudgetAlertOpts);
    case 'spendChange':
      return new SpendChangeAlert(serverModel as SpendChangeAlertOpts);
    case 'diagnostic':
      return new DiagnosticAlert(serverModel as DiagnosticAlertOpts);
    case 'health':
      return new ClusterHealthAlert(serverModel as ClusterHealthAlertOpts);
    case 'cloudReport':
      return new AssetRecurring(serverModel as RecurringAlertOpts);
    case 'assetBudget':
      return new AssetBudget(serverModel as BudgetAlertOpts);
    default:
      throw new Error('Unrecognized type');
  }
}

async function getAlerts(): Promise<Alert[]> {
  const urlRoot = `${getCurrentContainerAddressModel()}/alerts`;
  const response = await fetch(`${urlRoot}?req=${Math.floor(Math.random() * 1000000)}`);
  const alerts = await response.json();
  return alerts.map((a: AlertOpts) => toClientModel(a));
}

async function upsertAlert(alert: Alert): Promise<Alert> {
  const urlRoot = `${getCurrentContainerAddressModel()}/alerts`;
  const response = await fetch(`${urlRoot}`, {
    method: 'POST',
    headers,
    body: JSON.stringify(alert.toJson()),
  });
  const payload = await response.json();
  return toClientModel(payload);
}

interface AlertOpts {
  aggregation?: string;
  emailSubject?: string;
  id?: string;
  linkbackURL?: string;
  msTeamsWebhookUrl?: string;
  ownerContact?: string[];
  slackWebhookUrl?: string;
  type?: AlertTypes;
  window?: string;
}

interface RecurringAlertOpts extends AlertOpts {
  filter?: string;
}

interface EfficiencyAlertOpts extends AlertOpts {
  efficiencyThreshold: number;
  filter?: string;
  spendThreshold?: number;
}

interface BudgetAlertOpts extends AlertOpts {
  filter?: string;
  threshold: number;
}

interface SpendChangeAlertOpts extends AlertOpts {
  baselineWindow: string;
  filter?: string;
  relativeThreshold: number;
  window: string;
}

interface DiagnosticAlertOpts {
  aggregation?: string;
  id?: string;
  msTeamsWebhookUrl?: string;
  slackWebhookUrl?: string;
  type: AlertTypes;
  window?: string;
}

interface ClusterHealthAlertOpts {
  aggregation?: string;
  msTeamsWebhookUrl?: string;
  slackWebhookUrl?: string;
  threshold?: number;
  type: AlertTypes;
  window?: string;
}

const AlertService = {
  getGlobalLinkback,
  setGlobalLinkback,
  getGlobalSlackWebhook,
  getGlobalMSTeamsWebhook,
  setGlobalSlackWebhook,
  getGlobalEmailRecipients,
  setGlobalEmailRecipients,
  setGlobalMSTeamsWebhook,
  getGlobalEmailConfig,
  getAlerts,
  upsertAlert,
  deleteAlert,
  testAlert,
};

export {
  Alert,
  AlertService,
  AlertTypes,
  AssetBudget,
  AssetRecurring,
  BudgetAlert,
  ClusterHealthAlert,
  DiagnosticAlert,
  EfficiencyAlert,
  RBACUnauthorizedError,
  RecurringUpdate,
  SpendChangeAlert,
};
