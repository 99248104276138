import api from '../../services/api';
import ConfigService from '../../services/config';
import { version } from '../../services/env';
import { model } from '../../services/model';
import { getCurrentContainerAddressModel, paramsToQuery } from '../../services/util';

const generateHeader = (text: string) =>
  `
================================================================================================================================
${text}
================================================================================================================================
\n`;

const upQuery = `sum(up)`;
const nodeMetricQuery = `sum(node_cpu_seconds_total)`;
const fsUsageQuery = `sum(sum_over_time(container_cpu_usage_seconds_total[10m]))`;
const requestQuery = `sum(sum_over_time(kube_pod_container_resource_requests{resource="memory", unit="byte"}[10m]))`;
const hasCadvisorQuery = `sum(avg_over_time(kube_persistentvolume_capacity_bytes[10m]))`;
const costMetricsQuery = `avg_over_time(kubecost_savings_memory_allocation_bytes[10m])`;
const ksmVersionQuery = `sum(sum_over_time(node_cpu_hourly_cost[10m]))`;

const getProductConfigurationData = async () => {
  let productConfigurationDataString = '';
  await Promise.all([
    model.getConfigs(),
    model.clusterInfo(),
    model.getAPIConfig(),
    ConfigService.fetchProductKey(),
  ]).then(([config, clusterInfoResp, apiConfigResp, productKeyResp]) => {
    productConfigurationDataString += `${JSON.stringify(config)}\n${JSON.stringify(
      clusterInfoResp,
    )}\n${JSON.stringify(apiConfigResp)}\n${JSON.stringify(productKeyResp)}\n`;
  });
  return productConfigurationDataString;
};

const getPromConfigurationData = async () => {
  let promConfigString = '';
  const textPromise = await fetch(`${getCurrentContainerAddressModel()}/status`).then((res) =>
    res.text(),
  );
  promConfigString += textPromise;
  return promConfigString;
};

const getHelmValues = async () => {
  let helmValText = '';
  await model.get('/helmValues').then((resp) => {
    helmValText += JSON.stringify(resp);
  });
  return helmValText;
};

const getPromDataString = async (query: string) => {
  try {
    const promResp = await model.prometheusQuery(query, false, 45000);
    return JSON.stringify(promResp.data);
  } catch (err) {
    if (err instanceof DOMException && err.name === 'AbortError') {
      return `Request for this data timed out. You can attempt to query this data manually by sending the following query to prometheus:
      
      ${query}`;
    }
    return 'Unexpected error occurred.';
  }
};
const getThanosDataString = async (query: string) => {
  try {
    const thanosQuery = await api.thanosQuery(query, 45000);
    return JSON.stringify(thanosQuery);
  } catch (err) {
    if (err instanceof DOMException && err.name === 'AbortError') {
      return `Request for this data timed out. You can attempt to query this data manually by sending the following query to thanos:
      
      ${query}`;
    }
    return 'Unexpected error occurred.';
  }
};

const getPodLogs = async (selector: string, timeFrame: string) => {
  const payload = {
    selector,
    timeFrame,
  };
  const podLogsResp = await fetch(
    `${getCurrentContainerAddressModel()}/podLogs${paramsToQuery(payload)}`,
  ).then((resp) => resp.text());
  return podLogsResp;
};

const captureBugReport = async () => {
  let bugReportString = '';
  bugReportString += generateHeader('App info');
  bugReportString += `Running app version: ${version}`;
  bugReportString += generateHeader('Product Configuration');
  bugReportString += await getProductConfigurationData();
  bugReportString += generateHeader('Prometheus UP Results');
  bugReportString += `Results for Prometheus query: ${upQuery}\n\n`;
  bugReportString += await getPromDataString(upQuery);
  bugReportString += generateHeader('Prometheus Configuration');
  bugReportString += await getPromConfigurationData();
  bugReportString += generateHeader('Helm Values');
  try {
    bugReportString += await getHelmValues();
  } catch (err) {
    bugReportString +=
      'Helm values reporting disabled. Set reporting.valuesReporting=true in Helm Chart to see Helm values here.\n';
  }
  bugReportString += generateHeader('Node Prices');
  bugReportString += 'Results for Prometheus query: node_total_hourly_cost*730\n\n';
  bugReportString += await getPromDataString('node_total_hourly_cost*730');
  bugReportString += generateHeader('Prometheus Scale Data');
  bugReportString +=
    'Results for Prometheus query: topk(25, count by (__name__, job)({__name__=~".+"}))\n\n';
  bugReportString += await getPromDataString(
    'topk(25, count by (__name__, job)({__name__=~".+"}))',
  );
  bugReportString += generateHeader('Namespace memory usage & requests (GBs)');
  bugReportString +=
    'Results for Prometheus query: topk(25,max(avg_over_time (container_memory_working_set_bytes{pod_name!="",container_name!="",container_name!="POD",namespace="kubecost"}[1d])) by (container_name,pod_name)/1024/1024/1024)\n\n';
  bugReportString += await getPromDataString(
    'topk(25,max(avg_over_time (container_memory_working_set_bytes{pod_name!="",container_name!="",container_name!="POD",namespace="kubecost"}[1d])) by (container_name,pod_name)/1024/1024/1024)',
  );
  bugReportString += generateHeader('Kubecost pod logs');
  bugReportString += await getPodLogs('app=cost-analyzer', '24h');
  bugReportString += generateHeader('Prometheus pod logs');
  bugReportString += await getPodLogs('app=prometheus', '24h');
  bugReportString += generateHeader('Thanos pod logs');
  bugReportString += await getPodLogs('app.kubernetes.io/name=thanos', '`2h');
  bugReportString += generateHeader('Thanos scale data');
  bugReportString +=
    'Results for Thanos query: avg(up) by (component, job, kubernetes_namespace)\n\n';
  bugReportString += `${await getThanosDataString(
    'avg(up) by (component, job, kubernetes_namespace)',
  )}\n`;
  bugReportString +=
    'Results for Thanos query: topk(25, count by (__name__, job, cluster_id)({__name__=~".+"} offset 3h))\n\n';
  bugReportString += `${await getThanosDataString(
    'topk(25, count by (__name__, job, cluster_id)({__name__=~".+"} offset 3h))',
  )}\n`;
  bugReportString +=
    'Results for Thanos query: sum(count by (__name__, cluster_id)({__name__=~".+"} offset 3h)) by (cluster_id)\n\n';
  bugReportString += `${await getThanosDataString(
    'sum(count by (__name__, cluster_id)({__name__=~".+"} offset 3h)) by (cluster_id)',
  )}\n`;
  return bugReportString;
};

export {
  captureBugReport,
  costMetricsQuery,
  fsUsageQuery,
  hasCadvisorQuery,
  ksmVersionQuery,
  nodeMetricQuery,
  requestQuery,
  upQuery,
};
