import { FC } from 'react';

import { Avatar, Chip } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const getArrowIcon = (trendValue: unknown) => {
  if (typeof trendValue !== 'number' || Number.isNaN(trendValue)) {
    return null;
  }
  return trendValue > 0 ? (
    <ArrowUpwardIcon fontSize={'small'} />
  ) : (
    <ArrowDownwardIcon fontSize={'small'} />
  );
};

const getBgColor = (trendValue: unknown, upIsGood: boolean) => {
  // the trend is neutral for 0 and non-number values,
  if (typeof trendValue !== 'number' || trendValue === 0 || Number.isNaN(trendValue)) {
    return '#bfcdc9';
  }
  if (trendValue > 0) {
    return upIsGood ? '#f50057' : '#4caf50';
  }
  return upIsGood ? '#4caf50' : '#f50057';
};

interface TrendIndicatorChipProps {
  trendContent: string;
  trendValue: unknown;
  upIsGood: boolean;
}

export const TrendIndicatorChip: FC<TrendIndicatorChipProps> = ({
  trendContent,
  trendValue,
  upIsGood,
}) => (
  <Chip
    avatar={
      getArrowIcon(trendValue) ? (
        <Avatar style={{ backgroundColor: 'transparent', color: 'white' }}>
          {getArrowIcon(trendValue)}
        </Avatar>
      ) : undefined
    }
    label={trendContent}
    size={'small'}
    style={{
      opacity: 0.9,
      color: 'white',
      backgroundColor: getBgColor(trendValue, upIsGood),
    }}
    variant={'default'}
  />
);
