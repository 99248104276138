import { FC, ReactNode, useMemo, useState } from 'react';

import { Tooltip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from '@kubecost-frontend/holster';

/**
 *
 * Common table cards
 */
interface Row extends Record<string, ReactNode> {
  id: string;
}

interface Message {
  href?: string;
  linkStyle?: Record<string, string | number>;
  show: boolean;
  subhead: string;
  title: string;
}

interface TableCardProps {
  columns: Array<{ name: string; prop: string }>;
  loading?: boolean;
  messages?: Message[];
  rowHref?: (row: Row) => string;
  rows: Row[];
  title?: string;
}

const TableCard: FC<TableCardProps> = ({
  columns,
  loading = false,
  messages = [],
  rowHref = null,
  rows,
  title,
}) => {
  const [page, setPage] = useState(0);
  const imgTable = useMemo(() => columns.find((c) => c.prop === 'provider'), [columns]);
  const message = messages.find((m) => m.show);
  const emptyRows = 5 - Math.min(5, rows.length - page * 5) - (message ? 5 - rows.length : 0);

  const emptyTitleHeight = (imgTable ? 53 : 45) * (5 - rows.length);
  const emptyRowHeight = (imgTable ? 53 : 45) * emptyRows;
  const startIndex = page * 5 + 1;
  const stopIndex = (page + 1) * 5;
  const pageBackward = () => {
    setPage((prev) => prev - 1);
  };
  const pageForward = () => setPage((prev) => prev + 1);

  const handleRowClick = (row: Row) => () => {
    if (rowHref) {
      const loc = rowHref(row);
      if (loc) {
        window.location.href = loc;
      } else {
        alert('Cannot navigate to rows with no account');
      }
    }
  };

  return (
    <div
      className={'border border-kc-gray-100'}
      style={{ width: '100%', height: '100%', overflowX: 'auto' }}
    >
      {title && (
        <div className={'flex border-b border-kc-gray-100'} style={{ padding: '15px 0 15px 19px' }}>
          <Typography className={'font-bold'} variant={'p'}>
            {title}
          </Typography>
        </div>
      )}
      {loading && (
        <Table className={'w-full'}>
          <TableHead>
            <TableRow>
              <TableHeadCell>
                <Skeleton />
              </TableHeadCell>
              <TableHeadCell>
                <Skeleton />
              </TableHeadCell>
              <TableHeadCell>
                <Skeleton />
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {!loading && (
        <Table style={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableHeadCell
                  className={'text-kc-gray-300'}
                  key={column.name}
                  style={{
                    textAlign: 'left',
                    paddingTop: 16,
                    paddingBottom: 16,
                    paddingLeft: 19,
                    fontWeight: 700,
                  }}
                >
                  {column.name}
                </TableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * 5, (page + 1) * 5).map((row, i) => (
              <TableRow
                key={row.id}
                onClick={handleRowClick(row)}
                style={{
                  cursor: rowHref ? 'pointer' : '',
                }}
              >
                {columns.map((column) => (
                  <TableCell
                    className={column.name === 'COST' ? 'text-kc-gray-300' : 'text-kc-gray-200'}
                    key={`${row.id}-${column.name}`}
                    style={{
                      padding: '10px 10px 10px 19px',
                      fontWeight: 400,
                    }}
                  >
                    {column.prop === 'provider'
                      ? providerIcon(row[column.prop] as string)
                      : row[column.prop] === '__undefined__'
                      ? 'None'
                      : row[column.prop]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: emptyRowHeight }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
      {message &&
        !loading &&
        (message.href ? (
          <a
            href={message.href}
            style={message.linkStyle || { textDecoration: 'none' }}
            target={'_blank'}
          >
            <div
              style={{
                paddingTop: '3em',
                width: '100%',
                textAlign: 'center',
                height: emptyTitleHeight,
                borderBottom: '1px solid rgb(191 205 201)',
              }}
            >
              <Typography style={{ fontWeight: 700 }} variant={'p-large'}>
                {message.title}
              </Typography>
              {message.subhead && <p style={{ color: '#28b359' }}>{message.subhead}</p>}
            </div>
          </a>
        ) : (
          <div
            style={{
              paddingTop: '3em',
              width: '100%',
              textAlign: 'center',
              height: emptyTitleHeight,
              borderBottom: '1px solid rgb(191 205 201)',
            }}
          >
            <Typography style={{ fontWeight: 700 }} variant={'p-large'}>
              {message.title}
            </Typography>
            {message.subhead && <p style={{ color: '#28b359' }}>{emptySubhead}</p>}
          </div>
        ))}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: 54,
          width: '100%',
        }}
      >
        <Typography style={{ marginRight: 19 }} variant={'p'}>
          {startIndex}-{stopIndex} of {rows.length}
        </Typography>
        <Button
          disabled={!page}
          onClick={pageBackward}
          style={{ marginRight: 7 }}
          variant={'default'}
        >
          Previous
        </Button>
        <Button
          disabled={(page + 1) * 5 >= rows.length}
          onClick={pageForward}
          style={{ marginRight: 15 }}
          variant={'default'}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

TableCard.defaultProps = {
  loading: false,
};

function providerIcon(provider: string): ReactNode {
  const src =
    {
      AWS: 'images/aws_logo_square.png',
      GCP: 'images/gcp_logo_square.png',
      Azure: 'images/azure_logo_square.png',
    }[provider] || 'images/kube_icon.png';
  return (
    <Tooltip title={provider}>
      <img alt={provider} src={src} width={32} />
    </Tooltip>
  );
}

export { Row, TableCard, TableCardProps };
