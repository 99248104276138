import { memo, useMemo, useState } from 'react';

import keys from 'lodash/keys';
import orderBy from 'lodash/orderBy';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import SpeakerIcon from '@material-ui/icons/Speaker';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/styles';

import { CopyToClipBoard } from '../../components/CopyToClipboard';
import { DiagnosticsChecker } from '../../components/DiagnosticsChecker';
import { Header } from '../../components/Header';
import { useClusters } from '../../contexts/ClusterConfig';
import { toCurrency } from '../../services/format';

import { OrphanedResourcesResponse, useOrphanedResources } from './hooks/useOrphanedResources';

const useStyles = makeStyles({
  description: {
    padding: '24px 36px',
    marginBottom: 20,
  },
  errors: {
    padding: '12px 18px',
    marginBottom: 20,
  },
  loading: {
    textAlign: 'center',
    width: '100%',
  },
  totalSavings: {
    alignItems: 'center',
    color: green[700],
    display: 'flex',
    textAlign: 'center',
  },
  flexGrow: {
    flexGrow: 1,
  },
  panelNameWrapper: {
    flex: '1 0 auto',
    display: 'flex',
  },
  chip: {
    margin: '-2px 12px -2px 0',
  },
  form: {
    alignItems: 'center',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    display: 'flex',
    padding: '16px 24px',
    marginBottom: 0,
  },
  formControl: {
    margin: 8,
    minWidth: 120,
  },
  green: {
    color: green[700],
  },
});

const AddressDescription = ({ description, ipAddress, region }: OrphanedResourcesResponse) => (
  <div>
    <Typography paragraph>Address: {ipAddress}</Typography>
    <Typography paragraph>Region: {region || 'global'}</Typography>
    <Typography>Description: {JSON.stringify(description)}</Typography>
  </div>
);

const DiskDescription = ({ description, diskSizeInGB, region }: OrphanedResourcesResponse) => (
  <div>
    <Typography paragraph>Size: {diskSizeInGB} GB</Typography>
    <Typography paragraph>Region: {region || 'global'}</Typography>
    <Typography>Description: {JSON.stringify(description)}</Typography>
  </div>
);

const OrphanedResourceLineItem = (item: OrphanedResourcesResponse) =>
  item.resourceKind === 'disk'
    ? {
        description: DiskDescription(item),
        icon: <SpeakerIcon />,
        label: `${item.diskSizeInGB} GB`,
        name: item.diskName,
        monthlyCost: item.monthlyCost,
        region: item.region || 'global',
        type: 'disk',
        url: item.url,
      }
    : {
        description: AddressDescription(item),
        icon: <DeviceHubIcon />,
        label: item.ipAddress,
        name: item.ipAddress,
        monthlyCost: item.monthlyCost,
        region: item.region || 'global',
        type: 'address',
        url: item.url,
      };

const OrphanedResourcesPage = () => {
  const classes = useStyles();
  const [regionFilter, setRegionFilter] = useState('all');
  const [resourceFilter, setResourceFilter] = useState('all');
  const { data, isError, isLoading, refetch } = useOrphanedResources();
  const {
    modelConfig: { currencyCode: currency },
  } = useClusters();

  // Handle filter states
  const regionSet: Record<string, string> = {};
  if (data) {
    data.forEach((item) => {
      if (item.region) {
        regionSet[item.region] = item.region;
      }
    });
  }
  const regions = keys(regionSet);

  const handleOnRegionFilterChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) =>
    setRegionFilter(String(e.target.value));
  const handleOnResourceFilterChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) =>
    setResourceFilter(String(e.target.value));

  const totalSavings = data?.reduce((total, item) => total + item.monthlyCost, 0.0) || 0;
  const filteredSavings = data?.reduce((total, item) => total + item.monthlyCost, 0.0) || 0; // need to filter

  const filteredItems = useMemo(
    () =>
      orderBy(
        !data
          ? []
          : data
              .filter((item) => {
                if (regionFilter !== 'all' && item.region !== regionFilter) {
                  return false;
                }
                if (resourceFilter !== 'all' && item.resourceKind !== resourceFilter) {
                  return false;
                }
                return true;
              })
              .map(OrphanedResourceLineItem),
        ['monthlyCost', 'name'],
        ['desc', 'asc'],
      ),
    [data, regionFilter, resourceFilter],
  );

  return (
    <>
      <Header
        breadcrumbs={[
          { name: 'Cluster Savings', href: 'savings.html' },
          { name: 'Orphaned Resources', href: 'orphaned-resources.html' },
        ]}
      >
        <IconButton aria-label={'refresh'} onClick={() => refetch()}>
          <RefreshIcon />
        </IconButton>
        <DiagnosticsChecker />
        <Link href={'settings.html'}>
          <IconButton aria-label={'refresh'}>
            <SettingsIcon />
          </IconButton>
        </Link>
      </Header>

      <Paper className={classes.description}>
        <Grid spacing={3} container>
          <Grid className={classes.totalSavings} md={4} item>
            {isLoading && (
              <div className={classes.loading}>
                <CircularProgress />
              </div>
            )}
            {!isLoading && (
              <Typography
                className={classes.flexGrow}
                id={'total-savings'}
                variant={'h3'}
              >{`${toCurrency(totalSavings, currency)}/mo`}</Typography>
            )}
          </Grid>
          <Grid md={8} item>
            <Typography variant={'h5'} paragraph>
              Orphaned Resources
            </Typography>
            <Typography variant={'body1'}>
              Disks and IP addresses that are not being used by any clusters may continue to incur
              charges. The following resources have been identified as potentially orphaned and are
              candidates for deletion.
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      {!isLoading && isError && (
        <Paper className={classes.errors}>
          <List>
            <ListItem>
              <ListItemIcon>
                <WarningIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Failed to load resources.'}
                secondary={
                  'Check that you have a valid service key and the cost analyzer API is running, then refresh.'
                }
              />
            </ListItem>
          </List>
        </Paper>
      )}

      {!isLoading && data && (
        <Paper className={classes.form}>
          <div className={classes.flexGrow}>
            <FormControl className={classes.formControl}>
              <InputLabel id={'resource-type-select-label'}>Resource</InputLabel>
              <Select
                id={'resource-type-select'}
                labelId={'resource-type-select-label'}
                onChange={handleOnResourceFilterChange}
                value={resourceFilter}
              >
                <MenuItem value={'all'}>All</MenuItem>
                <MenuItem value={'disk'}>Disk</MenuItem>
                <MenuItem value={'address'}>IP Address</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id={'region-select-label'}>Region</InputLabel>
              <Select
                id={'region-select'}
                labelId={'region-select-label'}
                onChange={handleOnRegionFilterChange}
                value={regionFilter}
              >
                <MenuItem key={'all'} value={'all'}>
                  All
                </MenuItem>
                {regions.map((region) => (
                  <MenuItem key={region} value={region}>
                    {region}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ textAlign: 'center', padding: '0 1em' }}>
            <Typography variant={'h5'}>
              <span className={classes.green}>{toCurrency(filteredSavings, currency)}/mo</span>
            </Typography>
            <Typography variant={'body2'}>{filteredItems.length} resources</Typography>
          </div>
        </Paper>
      )}

      {!isLoading &&
        filteredItems.map((item, i) => (
          <ExpansionPanel key={i}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div className={classes.panelNameWrapper}>
                <Chip className={classes.chip} icon={item.icon} label={item.label} size={'small'} />
                <Typography>{item.name}</Typography>
                <div style={{ marginLeft: '.5em' }}>
                  <CopyToClipBoard copyText={item.name} />
                </div>
              </div>
              <Typography style={{ color: green[700] }}>
                {toCurrency(item.monthlyCost, currency)}/mo
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div>{item.description}</div>
            </ExpansionPanelDetails>
            <ExpansionPanelActions>
              <Button color={'primary'} href={item.url} rel={'noopener'} target={'_blank'}>
                View in console
              </Button>
            </ExpansionPanelActions>
          </ExpansionPanel>
        ))}
    </>
  );
};

export default memo(OrphanedResourcesPage);
