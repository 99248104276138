import { UseQueryResult } from 'react-query';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from '@kubecost-frontend/holster';

import { NetworkTraffic, NetworkTrafficDestination } from '../../../hooks/useNetworkTraffic';
import { KubecostResponse } from '../../../services/kubecost';
import { Allocation, AllocationProperties } from '../../../types/allocation';

const getDestinationType = (dest: NetworkTrafficDestination) => {
  if (dest.internet) return 'Internet';
  if (dest.region && dest.zone && !dest.internet) return 'In Zone';
  if (dest.region && !dest.zone && !dest.internet) return 'Cross Zone';
  if (!dest.region && !dest.zone && !dest.internet) return 'Cross Region';
  return 'N/A';
};

const findPodNetworkTraffic = (
  networkTraffic: NetworkTraffic | undefined,
  isSuccess: boolean,
  allocation: Allocation | null,
): Array<{
  destinationType: string;
  megabytes: number;
  name: string;
}> | null => {
  if (
    !isSuccess ||
    networkTraffic === undefined ||
    networkTraffic === null ||
    allocation === null
  ) {
    return null;
  }

  const { namespace, pod } = allocation.properties as AllocationProperties;

  // Search through all of the available traffic data for an entry that matches the selected row
  const matchingNetworkTrafficEntry = networkTraffic.find((networkTrafficEntry) => {
    const hasNetworkTraffic = networkTrafficEntry.sourceIds.some(
      (sourceId) => sourceId.name === pod && sourceId.namespace === namespace,
    );
    return hasNetworkTraffic;
  });

  if (matchingNetworkTrafficEntry === undefined) return null;

  return matchingNetworkTrafficEntry.destinations.map((destination) => {
    const megabytes = Math.round(destination.totalBytes / 1024 / 1024);
    const destinationType = getDestinationType(destination);

    const destinationAddress = destination.destinationAddresses[0];
    const destinationName =
      destinationAddress === ''
        ? `${destination.destinationIp}`
        : `${destination.destinationIp} (${destinationAddress})`;

    return {
      name: destinationName,
      megabytes,
      destinationType,
    };
  });
};

interface NetworkAllocationModalTableProps {
  networkTrafficQuery: UseQueryResult<KubecostResponse<NetworkTraffic>>;
  selectedAllocationData: Allocation | null;
}
const NetworkAllocationModalTable = ({
  networkTrafficQuery,
  selectedAllocationData,
}: NetworkAllocationModalTableProps) => {
  const networkTrafficForPod = findPodNetworkTraffic(
    networkTrafficQuery.data?.data,
    networkTrafficQuery.isSuccess,
    selectedAllocationData,
  );

  if (networkTrafficForPod === null) {
    return (
      <Typography className={'mt-5'} variant={'p'}>
        No recent network data found.
      </Typography>
    );
  }

  return (
    <Table className={'w-full'}>
      <TableHead>
        <TableRow>
          <TableHeadCell>Destination</TableHeadCell>
          <TableHeadCell>Type</TableHeadCell>
          <TableHeadCell>Quantity</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {networkTrafficForPod.map(({ destinationType, megabytes, name }) => (
          <TableRow>
            <TableCell>{name}</TableCell>
            <TableCell className={'min-w-fit'}>{destinationType}</TableCell>
            <TableCell className={'min-w-fit'}>{megabytes} MB</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export { NetworkAllocationModalTable };
