import { FC, SyntheticEvent } from 'react';

import { Link } from 'react-router-dom';

import { Typography } from '@kubecost-frontend/holster';

import { NavIconProps } from '../../assets/images/types';

const activeBoxShadowCSS =
  '0 1px 2px 0 rgba(0,0,0,0.08), 0 1px 1.5px 0 rgba(0,0,0,0.07), 0 2px 5px 0 rgba(48,49,61,0), 0 0 1px 0 rgba(48,49,61,0)';

interface ListItemCoreProps {
  active: boolean;
  handleOnClick?: () => void;
  icon: FC<NavIconProps>;
  name: string;
  title?: string;
}

const ListItemCore = ({ active, handleOnClick, icon, name, title }: ListItemCoreProps) => {
  const Icon = icon;

  return (
    <div
      className={`my-3 flex items-center rounded px-4 py-1 ${
        active ? 'text-kc-gray-300' : 'text-kc-gray-200'
      } hover:text-kc-gray-300`}
      onClick={(e: SyntheticEvent) => {
        if (handleOnClick) {
          handleOnClick();
          e.stopPropagation();
        }
      }}
      style={active ? { backgroundColor: 'white', boxShadow: activeBoxShadowCSS } : {}}
      title={title}
    >
      <Icon active={active} className={'mr-4'} />
      <Typography className={`font-bold`} variant={'p'}>
        {name}
      </Typography>
    </div>
  );
};

interface NavItemProps {
  active: boolean;
  href?: string;
  icon: FC<NavIconProps>;
  name: string;
  onClick?: () => void;
  secondary?: string;
  title?: string;
}

const NavItem = ({ active, href, icon, name, onClick, secondary, title }: NavItemProps) => {
  if (href && !active) {
    return (
      <Link style={{ textDecoration: 'none', color: 'inherit' }} to={`${href}`}>
        <ListItemCore
          active={active}
          handleOnClick={onClick}
          icon={icon}
          name={name}
          title={title}
        />
      </Link>
    );
  }
  return (
    <ListItemCore active={active} handleOnClick={onClick} icon={icon} name={name} title={title} />
  );
};

export { NavItem };
