import { APIClient } from '../../../services/APIClient/api-client';
import { getCurrentContainerAddressClusterController } from '../../../services/util';

import {
  ActionDefinition,
  ActionListItem,
  ActionType,
  RequestRightSizingScheduleItem,
} from './types';

const definitions: ActionDefinition[] = [
  {
    name: 'Right Size Requests',
    deleteAction: async (item: ActionListItem) => {
      let workloads = item.data as RequestRightSizingScheduleItem[];
      let items = workloads.map((workload) => {
        return workload.workload;
      });

      await APIClient.post('/kubescaler/resource/requests/schedules/delete', items, {
        baseURL: getCurrentContainerAddressClusterController(),
      });
    },
    getData: async () => {
      const data = await APIClient.get<RequestRightSizingScheduleItem[]>(
        '/kubescaler/resource/requests/schedules',
        {
          baseURL: getCurrentContainerAddressClusterController(),
        },
      );

      // Get all items into a hashmap on schedule.
      // We're making a rough assumption here that everything
      // with the same start date should be considered the same Set
      // of auto sizing rules

      const scheduleMap: { [key: string]: RequestRightSizingScheduleItem[] } = {};

      data.data.forEach((scheduledWorkload: RequestRightSizingScheduleItem) => {
        // Check if Schedule item exists
        if (!(scheduledWorkload.schedule.start in scheduleMap)) {
          scheduleMap[scheduledWorkload.schedule.start] = [];
        }

        scheduleMap[scheduledWorkload.schedule.start].push(scheduledWorkload);
      });

      // Now map them to the ActionListItems
      return Object.keys(scheduleMap).map((scheduleMapKey: string) => {
        const allItems = scheduleMap[scheduleMapKey];

        const frequencyInt = Math.floor(parseInt(allItems[0].schedule.frequencyMinutes, 10) / 60);
        const frequencyString = `Every ${frequencyInt} Hours`;

        const firstRunDate = new Date(allItems[0].schedule.start);
        const nextRunTime = firstRunDate.toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        });

        return {
          actionType: ActionType.AutomaticRequestSizing,
          data: allItems,
          displayName: 'Request Right Sizing',
          // Unique across all action types
          id: `RSS-${scheduleMapKey}`,
          numberOfAffectedWorkloads: allItems.length,
          scheduleFrequency: frequencyString,
          nextRun: nextRunTime,
        };
      });
    },
  },
];

export { definitions };
