import { Typography } from '@kubecost-frontend/holster';

const Footer = () => (
  <div className={'text-center'} style={{ padding: 24 }}>
    <Typography variant={'p-small'}>
      Have questions? We&apos;re on{' '}
      <a
        className={'text-kc-link'}
        href={'https://join.slack.com/t/kubecost/shared_invite/zt-1dz4a0bb4-InvSsHr9SQsT_D5PBle2rw'}
        target={'_blank'}
      >
        Slack
      </a>{' '}
      or email at{' '}
      <a href={'mailto:team@kubecost.com'} style={{ color: '#28B359' }}>
        team@kubecost.com
      </a>
    </Typography>
  </div>
);

export { Footer };
