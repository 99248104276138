import { MouseEventHandler } from 'react';

import { TableCell, TableRow } from '@kubecost-frontend/holster';

import { useClusters } from '../../../contexts/ClusterConfig';
import { toCurrency } from '../../../services/format';
import { Allocation } from '../../../types/allocation';

const formatCost = (cost: number, currencyCode: string): string => {
  if (cost > 0 && cost < 0.01) {
    return `<${toCurrency(0.01, currencyCode, 2, false)}`;
  }
  return toCurrency(cost, currencyCode, 2, false);
};

interface RowData {
  allocation: Allocation;
  namespace?: string;
  networkCost: number;
  networkCrossRegionCost: number;
  networkCrossZoneCost: number;
  networkInternetCost: number;
  pod?: string;
}
interface Props {
  handleOnClick: MouseEventHandler<HTMLTableRowElement>;
  rowData: RowData;
}

const NetworkAllocationRow = ({ handleOnClick, rowData }: Props) => {
  const { modelConfig } = useClusters();
  const { namespace, pod } = rowData;

  return (
    <TableRow onClick={handleOnClick}>
      <TableCell>{pod}</TableCell>
      <TableCell>{namespace}</TableCell>
      <TableCell style={{ textAlign: 'right' }}>
        {formatCost(rowData.networkInternetCost, modelConfig.currencyCode)}
      </TableCell>
      <TableCell style={{ textAlign: 'right' }}>
        {formatCost(rowData.networkCrossZoneCost, modelConfig.currencyCode)}
      </TableCell>
      <TableCell style={{ textAlign: 'right' }}>
        {formatCost(rowData.networkCrossRegionCost, modelConfig.currencyCode)}
      </TableCell>
      <TableCell style={{ textAlign: 'right' }}>
        {formatCost(rowData.networkCost, modelConfig.currencyCode)}
      </TableCell>
    </TableRow>
  );
};

export { NetworkAllocationRow, RowData as NetworkAllocationRowData };
