/* eslint-disable react/jsx-props-no-spreading */
import { FC, ReactNode } from 'react';

import { Skeleton } from '@material-ui/lab';

import { Typography } from '@kubecost-frontend/holster';

import { HighlightCardArrow } from '../../assets/images/HighlightCardArrow';

import { TrendIndicatorChip } from './TrendIndicatorChip';

const InnerCard: FC<HighlightCardProps> = ({
  content,
  footer,
  header,
  href,
  isEfficiency,
  loading,
  trendContent,
  trendValue,
}) => (
  <figure className={'h-full border-b border-r border-kc-gray-100 p-2.5'}>
    {loading ? (
      <>
        <Skeleton
          animation={'wave'}
          style={{
            height: '28px',
            width: '50%',
            opacity: 0.5,
          }}
        />
        <Skeleton
          animation={'wave'}
          style={{
            marginTop: 5,
            height: '14px',
            width: '70%',
            opacity: 0.5,
          }}
        />
        <Skeleton
          animation={'wave'}
          style={{
            marginTop: 5,
            height: '20px',
            width: '80%',
            opacity: 0.5,
          }}
        />
      </>
    ) : (
      <>
        <figcaption className={'text-base font-bold'}>{header}</figcaption>

        <Typography className={'flex items-center justify-between'} variant={'h5'}>
          {content}

          {trendContent && trendContent !== 'NaN' && trendContent !== 'Infinity' && (
            <TrendIndicatorChip
              trendContent={`${trendContent}%`}
              trendValue={trendValue}
              upIsGood={!isEfficiency}
            />
          )}
        </Typography>
        <Typography className={'pt-4 text-kc-gray-200'} variant={'h6'}>
          {footer}
        </Typography>
        {href && (
          <Typography className={'flex items-center font-bold text-kc-link'} variant={'p-small'}>
            View report
            <HighlightCardArrow className={'ml-2'} />
          </Typography>
        )}
      </>
    )}
  </figure>
);

const HighlightCard: FC<HighlightCardProps> = ({ href = '', ...rest }) =>
  href ? (
    <a href={href} style={{ textDecoration: 'none' }}>
      <InnerCard href={href} {...rest} />
    </a>
  ) : (
    <InnerCard {...rest} />
  );

/**
 *
 * Cards for the top-level highlight metrics
 */
interface HighlightCardProps {
  content: ReactNode;
  footer: ReactNode;
  header: ReactNode;
  href?: string;
  isEfficiency?: boolean;
  loading?: boolean;
  trendContent?: string;
  trendValue?: unknown;
}

export { HighlightCard, HighlightCardProps };
