import { QueryObserverResult, useQuery } from 'react-query';

import { model } from '../services/model';

import { ProductTier } from './useProductTier';

enum EnablementKeys {
  unifiedMultiCluster = 'unified-multi-cluster',
  cloudCosts = 'cloudCost',
}

enum EnablementNames {
  'unified-multi-cluster' = 'Unified multi-cluster',
  'cloudCost' = 'Cloud Costs',
}

interface APIEnablementDatum {
  isEnabled: boolean;
  isViolation: boolean;
  key: EnablementKeys;
  requiredTier: ProductTier;
}

type APIEnablementsResponse = Record<EnablementKeys, APIEnablementDatum>;

const useEnablements = (): QueryObserverResult<APIEnablementsResponse> =>
  useQuery({
    queryKey: ['enablements', Object.keys(EnablementKeys)],
    queryFn: model.getEnablements,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });

export {
  APIEnablementDatum,
  APIEnablementsResponse,
  EnablementKeys,
  EnablementNames,
  useEnablements as default,
};
