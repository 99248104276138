import forEach from 'lodash/forEach';
import get from 'lodash/get';
import reverse from 'lodash/reverse';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';

import { Menu } from '@kubecost-frontend/holster';
import { MenuItemProps } from '@kubecost-frontend/holster/dist/components/Menu';

import { DownloadIcon, FolderIcon } from '../../assets/images';
import {
  APIQueryWindow,
  apiQueryWindowParams,
  useQueryWindowParamState,
} from '../../components/QueryWindowSelector/useQueryWindowParamState';
import { model } from '../../services/model';
import { Asset, AssetFilter } from '../../types/asset';

import { ColumnType } from './AssetControls/types';

interface CustomizeReportMenuProps {
  accumulate: boolean;
  aggregateBy: string[];
  cumulativeData: Asset[];
  filters: AssetFilter[];
  onClose: () => void;
  setOpenReportDialog: (open: boolean) => void;
  sharedMonthlyCost: number;
  title: string;
}

const CustomizeReportMenu = ({
  accumulate,
  aggregateBy,
  cumulativeData,
  filters,
  onClose,
  setOpenReportDialog,
  sharedMonthlyCost,
  title,
}: CustomizeReportMenuProps) => {
  const { queryWindowState } = useQueryWindowParamState<APIQueryWindow>(apiQueryWindowParams);

  const columns: ColumnType[] = [
    {
      head: 'Name',
      prop: 'name',
      currency: false,
    },
    {
      head: 'Provider ID',
      prop: 'providerID',
      currency: false,
    },
    {
      head: 'Credits',
      prop: 'credit',
      currency: true,
    },
    {
      head: 'Adjusted',
      prop: 'adjustment',
      currency: true,
    },
    {
      head: 'Total',
      prop: 'totalCost',
      currency: true,
    },
  ];

  const toCSVLine = (datum: any) => {
    const cols: Array<string | number> = [];

    forEach(columns, (c) => {
      if (c.currency) {
        cols.push(round(Number(`${get(datum, c.prop, 0.0)}`), 2));
      } else {
        cols.push(`"${get(datum, c.prop, '')}"`);
      }
    });
    return cols.join(',');
  };

  const downloadReport = () => {
    const head = columns.map((c) => c.head).join(',');
    const body = reverse(sortBy(cumulativeData, 'totalCost')).map(toCSVLine).join('\r\n');
    const csv = `${head}\r\n${body}`;

    // Create download link
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([csv], { type: 'text/csv' }));
    const filename = title.toLowerCase().replace(/\s/gi, '-');
    a.setAttribute('download', `${filename}-${Date.now()}.csv`);

    // Click the link
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleDownloadControl = () => {
    downloadReport();
    onClose();
  };

  const handleDownloadPDF = () => {
    window.open(
      `${model.getAssetsQueryUrl(
        queryWindowState,
        aggregateBy,
        accumulate,
        filters,
        false,
        sharedMonthlyCost,
      )}&format=pdf`,
    );
  };

  const handleOpenReport = () => {
    setOpenReportDialog(true);
    onClose();
  };

  const handleMenuItemSelect = (item: MenuItemProps) => {
    switch (item.text) {
      case 'Download CSV':
        handleDownloadControl();
        break;
      case 'Download PDF':
        handleDownloadPDF();
        break;
      case 'Open Report':
        handleOpenReport();
        break;
      default:
    }
  };

  const menuItems = [
    {
      leftIcon: <FolderIcon aria-hidden={'true'} className={'inline'} focusable={'false'} />,
      text: 'Open Report',
    },

    {
      leftIcon: <DownloadIcon aria-hidden={'true'} className={'inline'} focusable={'false'} />,
      text: 'Download CSV',
    },
    {
      leftIcon: <DownloadIcon aria-hidden={'true'} className={'inline'} focusable={'false'} />,
      text: 'Download PDF',
    },
  ];
  return (
    <Menu
      className={'translate-x-[-90px]'}
      items={menuItems}
      onClose={onClose} // eslint-disable-line react/jsx-handler-names
      selectItem={handleMenuItemSelect}
    />
  );
};

export { CustomizeReportMenu };
