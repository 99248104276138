
import { Chip, Input } from '@kubecost-frontend/holster';

interface ComponentProps {
  addItem: (item: string) => void;
  handleOnChange: (e: React.FormEvent<HTMLInputElement>) => void;
  recipients: string[];
  removeItem: (item: number) => void;
  value: string;
}

const EmailRecipientsInput = ({
  addItem,
  handleOnChange,
  recipients,
  removeItem,
  value,
}: ComponentProps) => (
    <>
      <Input
        data-test={'emailRecipientInput'}
        helperText={'List of email recipients (optional). Press Enter after typing each address.'}
        label={'Add a recipient'}
        onChange={handleOnChange}
        onKeyPress={(e) => {
          if (e.key.toLowerCase() === 'enter') {
            addItem(value);
          }
        }}
        placeholder={'user@domain.tld'}
        style={{ width: '100%' }}
        value={value}
      />
      {recipients.map((recipient, i) => (
        <Chip
          color={'default'}
          data-test={'emailRecipientsList'}
          key={recipient}
          label={recipient}
          onDelete={() => removeItem(i)}
        />
      ))}
    </>
  );

export { EmailRecipientsInput };
