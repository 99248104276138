import { FC } from 'react';

import { CodeBlock } from '@kubecost-frontend/holster';

import { AdvancedReport } from '../../types/advancedReport';
import { AllocationReport } from '../../types/allocation';
import { AssetReport } from '../../types/asset';

type Report = AllocationReport | AssetReport | AdvancedReport;
type ReportGenerator = {
  handles: (r: Report) => boolean;
  section: string;
  yaml: (r: Report) => string;
};

export const ReportExport: FC<ReportExportProps> = ({ report }) => {
  const generator = ReportGenerators.find((g) => g.handles(report));

  if (!generator) {
    return <p>Unable to export YAML for this report.</p>;
  }

  return (
    <div>
      <p className={"py-4"}>
        To the following section of <strong>values.yaml</strong>:
      </p>
      {generator.section && (
        <CodeBlock language={"YAML"} showLineNumbers={false} text={generator?.section} />
      )}
      <p className={"py-4"}>Add the following report:</p>
      <CodeBlock
        language={"YAML"}
        showLineNumbers={false}
        text={generator.yaml(report) ?? 'Unable to generate report YAML'}
        copy
      />
      <br />
      <p>
        For more info visit the{' '}
        <a
          href={"https://docs.kubecost.com/using-kubecost/getting-started/saved-reports"}
          target={"__blank"}
        >
          Saved Reports Doc Page.
        </a>
      </p>
    </div>
  );
};

const FiltersGenerator = (r: Report) => {
  const reportFilters = r.filters; // [{property: 'property', value: 'value'}];

  const filters = [];

  if (reportFilters.length === 0) {
    return '[]';
  }

  for (const f of reportFilters) {
    filters.push(`      - property: "${f.property}"`);
    filters.push(`        value: "${f.value}"`);
  }

  return `\n${filters.join('\n')}`;
};

const ReportGenerators: ReportGenerator[] = [
  // Advanced Report
  {
    handles: (r) => 'cloudBreakdown' in r,
    section: `advancedReports:
  enabled: true
  reports:`,
    yaml: (report) => {
      // Not thrilled about casting the type here...
      const r = report as AdvancedReport;

      return `   - title: "${r.title}"
     window: "${r.window}"
     aggregatedBy: "${r.aggregateBy}"
     filters: ${FiltersGenerator(r)}
     cloudBreakdown: "${r.cloudBreakdown}"
     cloundJoin: "${r.cloudJoin}"`;
    },
  },
  // Allocation Report
  {
    handles: (r) => 'idle' in r,
    section: `savedReports:
    enabled: true
    reports:`,
    yaml: (report) => {
      // Not thrilled about casting the type here...
      const r = report as AllocationReport;

      return `   - title: "${r.title}"
     window: "${r.window}"
     aggregatedBy: "${r.aggregateBy}"
     idle: "${r.idle}"
     accumulate: ${r.accumulate ? 'true' : 'false'}
     filters: ${FiltersGenerator(r)}`;
    },
  },
  // Asset Report
  {
    handles: () => true,
    section: `assetReports:
    enabled: true
    reports:`,
    yaml: (report) => {
      const r = report as AssetReport;

      return `   - title: "${r.title}"
      window: "${r.window}"
      aggregateBy: "${r.aggregateBy}"
      accumulate: ${r.accumulate ? 'true' : 'false'}
      filters: ${FiltersGenerator(r)}`;
    },
  },
];

export interface ReportExportProps {
  report: Report;
  // type?: 'YAML'
}
