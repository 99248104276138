import configs from '../../services/appconfig';
import { model } from '../../services/model';
import {
  fetchAbandonedWorkloads,
  fetchClusterSizingRecommendations,
  fetchRequestSizingRecommendationsMaxHeadroom,
  getPvSavings,
  getReservedRecSavings,
  getSavingsSummary,
  getUnassignedAddressSavings,
  getUnassignedDiskSavings,
  getUnutilizedLocalDiskSavings,
} from '../../services/savings';
import {
  defaultSpotAllowSharedCore,
  defaultSpotMinNodeCount,
  defaultSpotMinOnDemandNodeCount,
  defaultSpotTargetUtilization,
  defaultSpotWindow,
  fetchSpotChecklistClusterSizing,
} from '../../services/savings/spotclustersizing';

class Api {
  kubernetesCosts = 0;

  kubernetesCostsOld = 0;

  kubernetesCostsNew = 0;

  cloudCosts = 0;

  usedStorageCosts = 0;

  totalStorageCosts = 0;

  clusterCount = 0;

  providerCount = 0;

  providers: ProviderAccount[] = [];

  cloudServices: ProviderService[] = [];

  allNamespaces: Set<string> = new Set();

  allClusters: Set<string> = new Set();

  totalSavings = 0;

  clusterDailyCosts: Record<string, number | string>[] = [];

  clusterDailyCounts: Record<string, Set<string> | string>[] = [];

  namespaceDailyCosts: Record<string, number | string>[] = [];

  namespaceDailyCounts: Record<string, Set<string> | string>[] = [];

  clusterTotals: Record<string, ClusterTotals> = {};

  namespaceTotals: Record<string, NamespaceTotals> = {};

  namespaceNetworkCosts: Record<string, NetworkTotals> = {};

  assetFetchLocked: boolean = false;

  savingsFetchLocked: boolean = false;

  networkDaemonsetNotActive: boolean = true;

  networkCheckComplete: boolean = false;

  allocFetchLocked: boolean = false;

  async initAllocations(numDays: number, costPromiseCallback: () => void): Promise<void> {
    if (this.allocFetchLocked) {
      return;
    }
    this.allocFetchLocked = true;
    const numberOfDaysToQuery = numDays * 2 + 1;
    const win = `${numberOfDaysToQuery}d`;
    // take the given relative window, and break it into a series of absolute windows
    // which can be requested one at a time
    const windows = model.relativeToAbsoluteWindows(win).reverse();

    // a sorted list of window starts helps to keep data ordered as it comes in
    const starts = windows.map((w) => w.split(',')[0]);
    const newStarts = starts.slice(8);

    // zero-out stored values
    this.kubernetesCosts = 0;
    this.kubernetesCostsOld = 0;
    this.kubernetesCostsNew = 0;
    this.allNamespaces = new Set();
    this.allClusters = new Set();

    this.clusterDailyCosts = newStarts.map((date) => ({ date }));
    this.clusterDailyCounts = newStarts.map((date) => ({ date }));

    this.namespaceDailyCosts = newStarts.map((date) => ({ date }));
    this.namespaceDailyCounts = newStarts.map((date) => ({ date }));

    this.clusterTotals = {};
    this.namespaceTotals = {};
    this.namespaceNetworkCosts = {};

    await configs.init();

    // issue two requests per day -- a cluster/namespace aggregation
    // for computing costs, and a cluster/node/namespace/pod aggregation
    // for computing pod and node counts in a cluster/namespace

    // courser-grained requests for costs
    // `costPromises` is an array of 1d-window promises
    model
      .getAllocationSummary(win, 'cluster,namespace', {
        accumulate: false,
        external: false,
        idleByNode: false,
        shareCost: configs.getSharedOverhead(),
        shareIdle: true,
        shareLabels: configs.getSharedLabels(),
        shareNamespaces: configs.getSharedNamespaces(),
        shareSplit: 'weighted',
        shareTenancyCosts: configs.getShareTenancyCosts(),
      })
      .then(({ data }) => {
        data.sets.forEach(({ allocations }, i) => {
          if (i < 7) {
            Object.keys(allocations).forEach((key) => {
              const totalCost = model.getSummaryTotalCost(allocations[key]);
              this.kubernetesCostsOld += totalCost;
            });
          } else if (i === 7) {
            Object.keys(allocations).forEach((key) => {
              const totalCost = model.getSummaryTotalCost(allocations[key]);
              this.kubernetesCostsNew += totalCost;
            });
          } else if (i > 7 && i < 14) {
            const idx = i - 8;
            Object.keys(allocations).forEach((key) => {
              const totalCost = model.getSummaryTotalCost(allocations[key]);
              const networkCost = model.getSummaryNetworkCost(allocations[key]);
              this.kubernetesCosts += totalCost;
              this.kubernetesCostsNew += totalCost;
              const [cluster, namespace] = key.split('/');

              this.namespaceTotals[`${cluster}/${namespace}`] = this.namespaceTotals[
                `${cluster}/${namespace}`
              ] || {
                namespace,
                cluster,
                id: `${cluster}/${namespace}`,
                cost: 0,
              };
              this.clusterTotals[cluster] = this.clusterTotals[cluster] || {
                name: cluster,
                id: cluster,
                cost: 0,
              };
              this.namespaceNetworkCosts[namespace] = this.namespaceNetworkCosts[namespace] || {
                namespace,
                id: namespace,
                cost: 0,
              };

              this.namespaceDailyCosts[idx][`${namespace}/cost`] =
                this.namespaceDailyCosts[idx][`${namespace}/cost`] || 0;
              this.clusterDailyCosts[idx][`${cluster}/cost`] =
                this.clusterDailyCosts[idx][`${cluster}/cost`] || 0;

              // namespace costs
              if (namespace && namespace !== 'undefined') {
                this.allNamespaces.add(namespace);
                this.namespaceDailyCosts[idx][`${namespace}/cost`] += totalCost;
                this.namespaceTotals[`${cluster}/${namespace}`].cost += totalCost;
                this.namespaceNetworkCosts[namespace].cost += networkCost;
              }

              // cluster costs
              if (cluster && cluster !== 'undefined') {
                this.allClusters.add(cluster);
                this.clusterDailyCosts[idx][`${cluster}/cost`] += totalCost;
                this.clusterTotals[cluster].cost += totalCost;
              }
            });
          } else if (i === 14) {
            const idx = i - 8;
            Object.keys(allocations).forEach((key) => {
              const totalCost = model.getSummaryTotalCost(allocations[key]);
              const networkCost = model.getSummaryNetworkCost(allocations[key]);
              this.kubernetesCosts += totalCost;

              const [cluster, namespace] = key.split('/');

              // these entries may be initialized by this callback, *or* by the counts callback
              this.namespaceTotals[`${cluster}/${namespace}`] = this.namespaceTotals[
                `${cluster}/${namespace}`
              ] || {
                namespace,
                cluster,
                id: `${cluster}/${namespace}`,
                cost: 0,
              };
              this.namespaceNetworkCosts[namespace] = this.namespaceNetworkCosts[namespace] || {
                namespace,
                id: namespace,
                cost: 0,
              };
              this.clusterTotals[cluster] = this.clusterTotals[cluster] || {
                name: cluster,
                id: cluster,
                pods: new Set(),
                nodes: new Set(),
                namespaces: new Set(),
                podCount: 0,
                nodeCount: 0,
                namespaceCount: 0,
                cost: 0,
              };

              this.namespaceDailyCosts[idx][`${namespace}/cost`] =
                this.namespaceDailyCosts[idx][`${namespace}/cost`] || 0;
              this.clusterDailyCosts[idx][`${cluster}/cost`] =
                this.clusterDailyCosts[idx][`${cluster}/cost`] || 0;

              // namespace costs
              if (namespace && namespace !== 'undefined') {
                this.allNamespaces.add(namespace);
                this.namespaceDailyCosts[idx][`${namespace}/cost`] += totalCost;
                this.namespaceTotals[`${cluster}/${namespace}`].cost += totalCost;
                this.namespaceNetworkCosts[namespace].cost += networkCost;
              }

              // cluster costs
              if (cluster && cluster !== 'undefined') {
                this.allClusters.add(cluster);
                this.clusterDailyCosts[idx][`${cluster}/cost`] += totalCost;
                this.clusterTotals[cluster].cost += totalCost;
              }
            });
          }
          costPromiseCallback();
        });
        costPromiseCallback();
        this.allocFetchLocked = false;
      });
  }

  async initAssets(numDays: number): Promise<void> {
    const numberOfDaysToQuery = numDays * 2 + 3;
    const win = `${numberOfDaysToQuery}d`;
    if (this.assetFetchLocked) {
      return;
    }
    this.assetFetchLocked = true;
    this.cloudCosts = 0;
    this.usedStorageCosts = 0;
    this.totalStorageCosts = 0;
    this.clusterCount = 0;
    this.providerCount = 0;
    this.providers = [];
    this.cloudServices = [];

    const displayResponse = await model.getAssets('7d', {
      aggregate: 'provider,account,cluster,service,type',
      accumulate: true,
      noLimit: true,
    });
    const assetSet = displayResponse.data[0];
    const clusterSet = new Set();
    const providerSet = new Set();
    const providerAccountMap: Record<string, ProviderAccount> = {};
    const cloudServiceMap: Record<string, ProviderService> = {};
    Object.keys(assetSet).forEach((key) => {
      const [provider, account, cluster, service, type] = key.split('/');

      if (service !== 'Kubernetes') {
        // total cloud costs are all non-k8s asset costs
        this.cloudCosts += assetSet[key].totalCost;

        // get provider/service line items for non-k8s costs
        if (cloudServiceMap[`${provider}/${service}`]) {
          cloudServiceMap[`${provider}/${service}`].totalCost += assetSet[key].totalCost;
        } else {
          cloudServiceMap[`${provider}/${service}`] = {
            service,
            id: `${provider}/${service}`,
            totalCost: assetSet[key].totalCost,
            provider,
          };
        }

        // get provider/account line items for non-k8s costs
        if (providerAccountMap[`${provider}/${account}`]) {
          providerAccountMap[`${provider}/${account}`].totalCost += assetSet[key].totalCost;
        } else {
          providerAccountMap[`${provider}/${account}`] = {
            id: `${provider}/${account}`,
            account,
            provider,
            totalCost: assetSet[key].totalCost,
          };
        }
      }

      // get a count of all unique clusters under management
      if (cluster !== '__undefined__') {
        clusterSet.add(cluster);
      }

      // get a count of all providers under management
      if (provider && provider !== '__undefined__') {
        providerSet.add(provider);
      }

      if (type === 'Disk') {
        const breakdown = assetSet[key].breakdown;
        this.totalStorageCosts += assetSet[key].totalCost;
        this.usedStorageCosts +=
          assetSet[key].totalCost * (breakdown.user + breakdown.system + breakdown.other);
      }
    });
    this.clusterCount = clusterSet.size;
    this.providers = Object.values(providerAccountMap).sort((pr1, pr2) =>
      pr1.totalCost > pr2.totalCost ? -1 : 1,
    );
    this.providerCount = providerSet.size;
    this.cloudServices = Object.values(cloudServiceMap).sort((s1, s2) =>
      s1.totalCost > s2.totalCost ? -1 : 1,
    );
    this.assetFetchLocked = false;
  }

  async initSavings(win: () => void, lose: (err: unknown) => void): Promise<void> {
    if (this.savingsFetchLocked) {
      return;
    }
    this.savingsFetchLocked = true;
    this.totalSavings = 0;
    try {
      await Promise.all([
        getSavingsSummary().then(({ nodeTurndown }) => {
          this.totalSavings += 0.65 * nodeTurndown;
        }),

        getPvSavings().then((savings) => {
          this.totalSavings += 0.65 * savings * 2;
        }),

        getReservedRecSavings().then((savings) => {
          this.totalSavings += 0.65 * savings;
        }),

        getUnassignedAddressSavings().then((savings) => {
          this.totalSavings += 0.65 * savings;
        }),

        getUnassignedDiskSavings().then((savings) => {
          this.totalSavings += 0.65 * savings;
        }),

        getUnutilizedLocalDiskSavings().then((savings) => {
          this.totalSavings += 0.65 * savings;
        }),

        fetchClusterSizingRecommendations('2d', 0.8, 1, true, 'x86').then((recs: any) => {
          const savings = Object.keys(recs.data).reduce((total, key) => {
            if (
              recs.data[key].recommendations &&
              recs.data[key].recommendations.single &&
              recs.data[key].recommendations.single.monthlySavings
            ) {
              return recs.data[key].recommendations.single.monthlySavings + total;
            }
            return total;
          }, 0);
          this.totalSavings += 0.65 * savings;
        }),

        fetchRequestSizingRecommendationsMaxHeadroom('2d', 0.8, 0.8, []).then(
          ({ monthlySavings }) => {
            this.totalSavings += 0.65 * Math.max(0, monthlySavings);
          },
        ),

        fetchAbandonedWorkloads(2, 500).then((workloads: any) => {
          this.totalSavings +=
            0.65 *
            workloads.reduce((total: number, workload: any) => workload.monthlySavings + total, 0);
        }),

        fetchSpotChecklistClusterSizing(
          defaultSpotMinOnDemandNodeCount,
          defaultSpotMinNodeCount,
          defaultSpotTargetUtilization,
          defaultSpotAllowSharedCore,
          defaultSpotWindow,
        ).then((sizing: any) => {
          this.totalSavings += 0.65 * sizing.monthlySavings || 0;
        }),
      ]);
      win();
      this.savingsFetchLocked = false;
    } catch (err) {
      lose(err);
      this.savingsFetchLocked = false;
    }
  }

  async networkCheck(): Promise<void> {
    this.networkCheckComplete = false;
    this.networkDaemonsetNotActive = true;
    try {
      await model.getNetworkTraffic();
      this.networkDaemonsetNotActive = false;
    } catch (err) {
      this.networkDaemonsetNotActive = true;
    } finally {
      this.networkCheckComplete = true;
    }
  }
}

interface ClusterTotals {
  cost: number;
  id: string;
  name: string;
  namespaceCount: number;
  namespaces: Set<string>;
  nodeCount: number;
  nodes: Set<string>;
  podCount: number;
  pods: Set<string>;
}

interface Namespace {
  cluster: string;
  id: string;
  namespace: string;
  totalCost: number;
}

interface NamespaceTotals {
  cluster: string;
  cost: number;
  id: string;
  namespace: string;
}

interface NetworkTotals {
  cost: number;
  id: string;
  namespace: string;
}

interface ProviderAccount {
  account: string;
  id: string;
  provider: string;
  totalCost: number;
}

interface ProviderService {
  id: string;
  provider: string;
  service: string;
  totalCost: number;
}

export {
  Api,
  ClusterTotals,
  Namespace,
  NamespaceTotals,
  NetworkTotals,
  ProviderAccount,
  ProviderService,
};
